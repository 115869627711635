import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Login": "Login",
      "Phone number": "Phone number",
      "Password": "Password",
      "Continue": "Continue",
      "Sign up": "Sign up",
      "Join": "Join",
      "have an account?": "have an account?",
      "Sign in": "Sign in",
      "Welcome": "Welcome",
      "FAQ": "FAQ",
      "Contact Us": "Contact Us",
      "Terms & Privacy Policy": "Terms & Privacy Policy",
      "Profile": "Profile",
      "location": "location",
      "Language": "Language",
      "First Name": "First Name",
      "Middle Name": "Middle Name",
      "Last Name": "Last Name",
      "Why eneo?": "Why eneo?",
      "Why Eneo?": "Why Eneo?",
      "Find property for rent": "Find property for rent",
      "Search for a city": "Search for a city",
      "Suburb": "Suburb",
      "Experience": "Experience",
      "Search": "Search",
      "Category": "Category",
      "Min. price": "Min. price",
      "Max. price": "Max. price",
      "bathrooms": "bathrooms",
      "bedrooms": "bedrooms",
      "Explore Places": "Explore Places",
      "Find what suits you from nearby places": "Find what suits you from nearby places",
      "Explore more places": "Explore more places",
      "Never miss out the most important listings": "Never miss out the most important listings",
      "Discover Places": "Discover Places",
      "Browse over hundreds of places available to rent": "Browse over hundreds of places available to rent",
      "Affordable price": "Affordable price",
      "Find places within your budget": "Find places within your budget",
      "Simple to use": "Simple to use",
      "Secured": "Secured",
      "Reliable": "Reliable",
      "Places are vetted and verified for your safety": "Places are vetted and verified for your safety",
      "About us": "About us",
      "How it works": "How it works",
      "About eneo": "About eneo",
      "Our story": "Our story",
      "How to be an agent": "How to be an agent",
      "Follow Us": "Follow Us",
      "Safe": "Safe",
      "Post a rental": "Post a rental",
      "SUPPORT": "SUPPORT",
      "Please login to continue using Eneo": "Please login to continue using Eneo",
      "Forgot password?": "Forgot password?",
      "Please sign up to start using Eneo": "Please sign up to start using Eneo",
      "First name": "First name",
      "Last name": "Last name",
      "Email": "Email",
      "Email (optional)": "Email (optional)",
      "Password": "Password",
      "I'm registering as a property owner/ agent": "I'm registering as a property owner/ agent",
      "Type": "Type",
      "I am an agent": "I am an agent",
      "I have my own properties": "I have my own properties",
      "Affordable quality": "Affordable quality",
      "Apartments": "Apartments",
      "Property Title (English)": "Property Title (English)",
      "Property Title (Kiswahili)": "Property Title (Kiswahili)",
      "Region": "Region",
      "District": "District",
      "Ward": "Ward",
      "Village": "Village",
      "add a location": "add a location",
      "Search your location and choose": "Search your location and choose",
      "no option": "no option",
      "Plot number": "Plot number",
      "Number of bathrooms": "Number of Bathrooms",
      "Property size": "Property size",
      "Category": "Category",
      "House of rent": "House of rent",
      "apartment/flat to rent": "apartment/flat to rent",
      "Development to rent": "Development to rent",
      "Self contained": "Self contained",
      "Keywords": 'Keywords',
      "Price per month": "Price per month",
      "Display on eneo": "Display on eneo",
      "Property description (English)": "Property description (English)",
      "Property description (Kiswahili)": "Property description (Kiswahili)",
      "Next": "Next",
      "Back": "Back",
      "click to browse": "click to browse",
      "Submit": "Submit",
      "Please upload atleast 5 photos": "Please upload atleast 5 photos",
      "Add file": "Add file",
      "property created successfully": "property created successfully",
      "Property": "Property",
      "Dashboard": "Dashboard",
      "terms and conditions": "terms and conditions",
      "Privacy and policy": "Privacy and policy",
      "Email or Phone (255xxxxx)": "Email or Phone (255xxxxx)",
      "Add a location (search your location and choose)": "Add a location (search your location and choose)",
      "Discover our featured listing": "Discover our featured listing",
      "Don’t miss out the hottest place to stay or do business": "Don’t miss out the hottest place to stay or do business",
      "Affordable Prices": "Affordable Prices",
      "Built For Africa": "Built For Africa",
      "Places are vetted and verified for your safety.": "Places are vetted and verified for your safety.",
      "Vibrant, secured & simple to use.": "Vibrant, secured & simple to use.",
      "Reliable & Safe": "Reliable & Safe",
      "Search for a city, surburb or experience": "Search for a city, surburb or experience",
      "ABOUT US": "ABOUT US",
      "Privacy": "Privacy",
      "Terms": "Terms",
      "Description": "Description",
      "Overview": "Overview",
      "Location": "Location",
      "Bedrooms": "Bedrooms",
      "Bathrooms": "Bathrooms",
      "SquareFt": "SquareFt",
      "Year Built": "Year Built",
      "FOLLOW US": "FOLLOW US",
      "For Rent": "For Rent",
      "Reset password": "Reset password",
      "Enter phone or email": "Enter phone or email",
      "Property type": "Property type",
      "Terms and Conditions": "Terms and Conditions",
      "Privacy Policy": "Privacy Policy",
      "Property Title": "Property Title",
      "appears below the property image on the property pages": "appears below the property image on the property pages",
      "Number of bedrooms": "Number of bedrooms",
      "Yes": "Yes",
      "No": "No",
      "New Property": "New Property",
      "For Sell": "For Sell",
      "Listings": "Listings",
      "Verified": "Verified",
      "Waiting for approval": "Waiting for approval",
      "Property Category": "Property Category",
      "Price per month": "Price per month",
      "Uploaded photos": "Uploaded photos",
      "Add new photos": "Add new photos",
      "Places": "Places",
      "Search for places": "Search for places",
      "view properties at": "view properties at",
      "Profile": "Profile",
      "Signout": "Signout",
      "Edit profile": "Edit profile",
      "Favourites": "Favourites",
      "Subscription": "Subscription",
      "Password confirmation": "Password confirmation",
      "Drag Photos or Click to Browse (Upload atleast 5 photos)": "Drag Photos or Click to Browse (Upload atleast 5 photos)",
      "House for Rent": "House for Rent",
      "Apartment/Flat to Rent": "Apartment/Flat to Rent",
      "Commercial Property to Rent": "Commercial Property to Rent",
      "Developmnts To Rent": "Developments To Rent",
      "Self contained": "Self contained",
      "Chat": "Chat",
      "Apartment": "Apartment",
      "Questions about Eneo?": "Questions about Eneo?",
      "Watch This Video": "Watch This Video",
      "Clear": "Clear"
    }
  },
  sh: {
    translation: {
      "Clear": "Ondoa",
      "Watch This Video": "Tazama hii Video",
      "Questions about Eneo?": "Maswali kuhusu Eneo?",
      "Apartment": "Nyumba",
      "Chat": "Chati",
      "Self contained": "chumba,Sebule na choo",
      "House for Rent": "Nyumba ya kukodisha",
"Apartment/Flat to Rent": "Gorofa ya kukodisha",
"Commercial Property to Rent": "Mali ya biashara ya kukodisha",
"Developments To Rent": "Kukodisha kwaajili ya maendeleo",
      "Drag Photos or Click to Browse (Upload atleast 5 photos)": "Weka picha au Bonyeza kutafuta (weka kuanzia picha 5)",
      "Password confirmation": "Uthibitisho wa neno siri",
      "Subscription": "Usajili",
      "Favourites": "Vipendwa",
      "Edit profile": "Badilisha wasifu",
      "Signout": "toka",
      "Profile": "Wasifu",
      "view properties at": "tazama mali zilizopo",
      "Search for places": "Tafuta maeneo",
      "Places": "Maeneo",
      "Add new photos": "Ongeza picha mpya",
      "Uploaded photos": "Picha zilizopakiwa",
      "Price per month": "Bei kwa mwezi",
      "Property Category": "kategoria ya mali",
      "Verified": "Imethibitishwa",
      "Waiting for approval": "Subiria uthibitisho",
      "Listings": "Orodha",
      "New Property": "Mali mpya",
      "For Sell": "Kwa kuuza",
      "Yes": "Ndio",
      "No": "Hapana",
      "Number of bedrooms": "Idadi ya vyumba vya kulala",
      "appears below the property image on the property pages": "inaonekana chini ya picha ya mali kwenye kurasa za mali",
      "Property Title (English)": "Jina la Mali (Kiingereza)",
      "Property Title (Kiswahili)": "Jina la Mali (Kiswahili)",
      "Terms and Conditions": "Sheria na Masharti",
      "Privacy Policy": "Sera ya Faragha",
      "Property type": "Aina ya mali",
      "Enter phone or email": "Weka namba ya simu au Barua pepe",
      "Reset password": "Badili neno siri",
      "For Rent": "ya kupangisha",
      "FOLLOW US": "TUFUATE",
      "Year Built": "Mwaka uliojengwa",
      "SquareFt": "Mraba",
      "Bathrooms": "Bafu",
      "Bedrooms": "Vyumba vya kulala",
      "Location": "Mahali",
      "Overview": "Muhtasari",
      "Description": "Maelezo",
      "Terms": "Sheria",
      "Privacy": "Sera",
      "ABOUT US": "KUHUSU SISI",
      "Search for a city, surburb or experience": "Tafuta mji, Kitongoji",
      "Reliable & Safe": "Madhubuti & Salama",
      "Vibrant, secured & simple to use.": "Mahiri, Salama & Rahisi kutumia",
      "Places are vetted and verified for your safety.": "Maeneo yamehakikiwa na kuthibitishwa kwa usalama wako.",
      "Built For Africa": "Imejengwa kwa ajili ya Afrika",
      "Affordable Prices": "Bei nafuu",
      "Don’t miss out the hottest place to stay or do business": "Usikose mahali pazuri pa kukaa au kufanya biashara",
      "Discover our featured listing": "Gundua mengineyo",
      "Add a location (search your location and choose)": "Ongeza eneo (Tafuta eneo lako na chagua)",
      "Email or Phone (255xxxxx)": "Barua pepe au Namba ya simu (255xxxxx)",
      "SUPPORT": "MSAADA",
      "Please login to continue using Eneo": "Tafadhali ingia ili kuendelea kutumia Eneo",
      "Forgot password?": "Umesahau neno la siri?",
      "Please sign up to start using Eneo": "Tafadhali jisajili ili kuanza kutumia Eneo",
      "First name": "Jina la kwanza",
      "Last name": "Jina la Mwisho",
      "Email": "Barua pepe",
      "Email (optional)": "Barua pepe (hiari)",
      "Password": "Neno la siri",
      "I'm registering as a property owner/ agent": "Nina jisajili kama mmiliki wa mali/ wakala",
      "Type": "Aina",
      "I am an agent": "Mimi ni wakala",
      "I have my own properties": "Nina mali zangu mwenyewe",
      "Affordable quality": "Ubora wa bei nafuu",
      "Apartments": "Gorofa",
      "Property Title": "Jina la mali",
      "Region": "Mkoa",
      "District": "Wilaya",
      "Ward": "Kata",
      "Village": "Kijiji",
      "add a location": "ongeza eneo",
      "Search your location and choose": "Tafuta eneo lako na chagua",
      "no option": "hakuna chaguo",
      "Plot number": "namba ya kiwanja",
      "Number of bathrooms": "Namba ya bafu",
      "Property size": "Ukubwa wa mali",
      "Category": "Aina",
      "House of rent": "Nyumba ya kukodisha",
      "apartment/flat to rent": "Gorofa ya kukodisha",
      "Development to rent": "Maendeleo ya kukodisha",
      "Keywords": 'maneno muhimu',
      "Price per month": "Bei kwa mwezi",
      "Display on eneo": "Onyesha kwenye eneo",
      "Property description (English)": "Maelezo ya mali (Kiingereza)",
      "Property description (Kiswahili)": "Maelezo ya mali (Kiswahili)",
      "Next": "Mbele",
      "Back": "Nyuma",
      "click to browse": "click to browse",
      "Submit": "Wasilisha",
      "Please upload atleast 5 photos": "Tafadhali pakia angalau picha 5",
      "Add file": "Ongeza faili",
      "property created successfully": "mali imeundwa kwa mafanikio",
      "Property": "Mali",
      "Dashboard": "Dashibodi",
      "terms and conditions": "sheria na Masharti",
      "Privacy and policy": "sera ya faragha",
      "Post a rental": "Weka cha kukodishwa",
      "SUPPORT": "MSAADA",
      "Safe": "Salama",
      "Follow Us": "Tufatilie",
      "How to be an agent": "Jinsi ya kuwa wakala",
      "Our story": "Hadithi yetu",
      "About eneo": "Kuhusu eneo",
      "How it works": "Inavyofanya kazi",
      "About us": "Kuhusu sisi",
      "Places are vetted and verified for your safety": "Maeneo yamehakikiwa na kuthibitishwa kwa usalama wako.",
      "Reliable": "madhubuti",
      "Secured": "Salama",
      "Simple to use": "Rahisi kutumia",
      "Find places within your budget": "Tafuta maeneo katika bajeti yako",
      "Affordable price": "Bei nafuu",
      "Browse over hundreds of places available to rent": "Vinjari mamia ya maeneo yanayopatikana ya kukodisha",
      "Discover Places": "Gundua maeneo",
      "Never miss out the most important listings": "Kamwe usikose orodha muhimu zaidi",
      "Explore more places": "Gundua maeneo zaidi",
      "Find what suits you from nearby places": "Pata kinachokufaa kutoka maeneo ya karibu",
      "Explore Places": "Gundua maeneo",
      "Bedrooms": "Vyumba",
      "Bathrooms": "Bafu",
      "Max. price": "Bei ya juu",
      "Min. price": "Bei ya chini",
      "Category": "Aina",
      "Search": "Tafuta",
      "Experience": "Uzoefu",
      "Suburb": "Kitongoji",
      "Search for a city": "Tafuta mji",
      "Find property for rent": "Tafuta mali ya kukodisha",
      "Why Eneo?": "Kwanini Eneo?",
      "Why eneo?": "Kwanini eneo?",
      "Middle Name": "Jina la kati",
      "Last Name": "Jina la mwisho",
      "First Name": "Jina la kwanza",
      "Confirm": "Thibitisha",
      "resend": "Tuma tena",
      "Close": "Funga",
      "Logout": "Toka",
      "Language": "Lugha",
      "location": "Mahali",
      "Profile": "Wasifu",
      "Login": "Ingia",
      "Phone number": "Weka Namba ya Simu",
      "Password": "Nenosiri",
      "Sign in": "Ingia",
      "Sign up": "Jiunge"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
