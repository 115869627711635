import '../App.css';
import { ButtonBase, Container, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
   heading: {
       width: '100%',
   },
  }));

  
  

function Home(props) {
    const classes = useStyles()

  return (
      <Container>
     <div className={classes.heading}>
    
      </div>
      </Container>
     
   
   
  )
}

export default Home;
