import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, ButtonBase} from '@material-ui/core';
import Apartment from '../assets/Appartment.png';


const useStyles = makeStyles((theme) => ({
   container: {
       marginBottom: 100,
       backgroundColor: '#fff',
       borderRadius: 30,
       height: 400,
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'space-evenly',
       flexDirection: 'row',
       [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: 700
      },

   },
   griditem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemthree: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemtwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1849AA',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
},
headertwo: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: '700'
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
smallheadertwo: {
    fontSize: 12,
    color: '#fff',
},
   header: {
       color: '#222222',
       fontSize: '20px',
       fontWeight: '700'
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#AEB6BB',
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
paddingLeft: 20,
paddingRight: 20,
    height: '36px',
    borderRadius: '54px',
    color: '#fff',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  buttonthreetwo: {
paddingLeft: 20,
paddingRight: 20,
    height: '36px',
    borderRadius: '54px',
    border: '1px solid #1849AA',
    backgroundColor: '#fff',
    color: '#1849AA',
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  },
  illustrationcontainer: {
      width: 200,
      height: 200,
      borderRadius: 100,
      backgroundColor: '#1849AA',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',

  },
  detailscontainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 100,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
  },
  buttoncont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
},
photo: {
    width: 300
  },
  wordscont: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
  },
  headerfour: {
    color: '#2E7D32',
    fontSize: '40px',
    fontWeight: '700',
    paddingTop: 50,
},
smallheadertwo: {
    fontSize: 16,
    color: '#222222',
    paddingTop: 10,
    paddingBottom: 20
},
}));


export default function CustomSeparator() {
  const classes = useStyles();


  return (
      <>
    <Container className={classes.container}>
    <img src={Apartment} alt='building' className={classes.photo}/>
    <div className={classes.wordscont}>
    <span className={classes.headerfour}>Free Subscription</span>
    <span className={classes.smallheadertwo}>Currently testing, we will update prices soon. Enjoy.</span>
    </div>
         <div className={classes.detailscontainer}>
         <div className={classes.illustrationcontainer}>
         <span className={classes.bigheadertwo}>Free</span>
         <span className={classes.headertwo}>BETA TEST</span>
            </div>
         </div>
    </Container>
    </>
  );
}
