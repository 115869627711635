import React from "react";

const Instagram = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0176 22.5C18.2308 22.5 23.2676 17.4632 23.2676 11.25C23.2676 5.03681 18.2308 0 12.0176 0C5.80439 0 0.767578 5.03681 0.767578 11.25C0.767578 17.4632 5.80439 22.5 12.0176 22.5Z" fill="white"/>
    <path d="M15.3699 5.98633H8.66388C7.5935 5.98633 6.72266 6.85717 6.72266 7.92755V14.6336C6.72266 15.704 7.5935 16.5751 8.66388 16.5751H15.3699C16.4403 16.5751 17.3114 15.7042 17.3114 14.6336V7.92755C17.3114 6.85717 16.4406 5.98633 15.3699 5.98633ZM12.0169 14.7665C10.095 14.7665 8.53127 13.2027 8.53127 11.2806C8.53127 9.35865 10.095 7.79495 12.0169 7.79495C13.9391 7.79495 15.5028 9.35865 15.5028 11.2806C15.5028 13.2025 13.9388 14.7665 12.0169 14.7665ZM15.6148 8.51563C15.1599 8.51563 14.7899 8.14564 14.7899 7.69092C14.7899 7.23619 15.1599 6.8662 15.6148 6.8662C16.0696 6.8662 16.4396 7.23619 16.4396 7.69092C16.4396 8.14564 16.0696 8.51563 15.6148 8.51563Z" fill="#0E3279"/>
    <path d="M12.0168 9.26758C10.9073 9.26758 10.0039 10.1705 10.0039 11.28C10.0039 12.39 10.9073 13.2932 12.0168 13.2932C13.1268 13.2932 14.0295 12.39 14.0295 11.28C14.0295 10.1708 13.1266 9.26758 12.0168 9.26758Z" fill="#0E3279"/>
    <path d="M12.0176 0C5.80463 0 0.767578 5.03705 0.767578 11.25C0.767578 17.463 5.80463 22.5 12.0176 22.5C18.2306 22.5 23.2676 17.463 23.2676 11.25C23.2676 5.03705 18.2306 0 12.0176 0ZM18.7848 14.6334C18.7848 16.5162 17.2534 18.0476 15.3706 18.0476H8.66458C6.78201 18.0476 5.25039 16.5162 5.25039 14.6334V7.92733C5.25039 6.04477 6.78201 4.51314 8.66458 4.51314H15.3706C17.2534 4.51314 18.7848 6.04477 18.7848 7.92733V14.6334Z" fill="#0E3279"/>
    </svg>
  );
};

export default Instagram;
