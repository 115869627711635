import * as types from '../types';

const initialState = {
  loading: false,
  error: null,
  userinfo: null,
  isAuthenticated: false,
  steps: 0,
  propertyitems: {},
  toedit: false,
  language: 'english',
  pagination: 2,
  prices: null
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADINGPROPERTIES:
      return {
        ...state,
        loadingproperties: action.payload,
        error: null
      }
    case types.LOADINGPAGE:
      return {
        ...state,
        loadingpage: action.payload
      }
    case types.CLEARSTEPS:
      return {
        ...state,
        steps: 0
      }
    case types.SUCCESSDELETEPHOTO: 
    return {
      ...state,
      loadingdeletephoto: false,
      successdeletephoto: action.payload,
      error: null
    }
    case types.LOADINGDELETEPHOTO: 
    return {
      ...state,
      loadingdeletephoto: action.payload
    }
    case types.CLEARSUCCESS:
      return {
        ...state,
        modifysuccess: null,
        feedback: null,
        successconfirm: null
      }
    case types.CLEARERROR:
      return {
        ...state,
        loading: false,
        error: null,
        successedit: false,
        villagesfiltered: null,
        successdeletephoto: false,
      }
    case types.CLEARDATA: 
       return {
         ...state,
        propertyitems: action.payload,
       }
    case types.SUCCESS_REVIEW:
      return {
        ...state,
        feedback: action.payload,
        loading: false,
        error: null
      }
    case types.SUCCESS_MODIFY:
      return {
        ...state,
        modify: action.payload,
        loading: false,
        error: null,
        modifysuccess: true
      }
    case types.SUCCESSSIGNUP:
      return{
        ...state,
        userdata: action.payload,
        avatar: action.payload?.user?.avatar || null,
        error: null,
        loading: false,
        signupsuccess: true,
        isAuthenticated: true,
      }
    case types.SUCCESSSIGNIN:
      return {
        ...state,
        userdata: action.payload,
        avatar: action.payload?.user?.avatar || null,
        error: null,
        loading: false,
        signinsuccess: true,
        isAuthenticated: true,
      }
      case types.SUCCESS_CONFIRM:
        return{
          ...state,
          successconfirm: action.payload,
          modify: action.payload,
          error: null,
          confirmloading: false,
          loading: false
        }
      case types.SUCCESSRESET:
        return {
          ...state,
          successreset: true,
          loading: false
        }
        case types.SUCCESSRESETPASSWORD:
          return {
            ...state,
            successresetpassword: true,
            loading: false
          }
    case types.LOADING:
      return {
        ...state,
        loading: action.payload,
        error: null
      }
    case types.CLEARSIGNUP:
      return {
        ...state,
        loading: false,
        signupsuccess: false,
        successreset: false,
        successresetpassword: false,
        signinsuccess: false,
        successcreateproperty: false,
        successeditproperty: false,
        steps: 0,
        propertyitems: null,
        loadinguserproperties: false,
        error: null
      }
    case types.LOGOUT:
      return {
        isAuthenticated: false,
        userdata: null,
        userinfo: null,
        error: null,
        loading: false
      }
    case types.CONFIRMLOADING:
      return {
        ...state,
        confirmloading: true,
        error: null
        }
    case types.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadinguserproperties: false
      }
      case types.SUCCESSEDITPROPERTY:
        return {
          ...state,
          loading: false,
          error: null,
          successeditproperty: true,
          steps: 0,
          propertyitems: null,
        }
    case types.SUCCESSCREATEPROPERTY:
      return {
        ...state,
        loading: false,
        error: null,
        successcreateproperty: true,
        steps: 0,
        propertyitems: null,
      }
    case types.SUCCESSTAGS:
      return {
        ...state,
        tags: action.payload
      }
    case types.SUCCESSPRICES:
      return {
        ...state,
        prices: action.payload
      }
      case types.SUCCESSSTATUS:
        return {
          ...state,
          status: action.payload
        }
      case types.SUCCESSLISTTYPES:
        return {
            ...state,
            listtypes: action.payload
          }
      case types.SUCCESSTYPES:
        return {
            ...state,
            propertytypes: action.payload
            }
      case types.SUCCESSREGIONS:
        return {
          ...state,
          regions: action.payload,
          loading: false
        }
        case types.SUCCESSDISTRICTS:
          return {
            ...state,
            districts: action.payload,
            loadingdistricts: false
          }
          case types.SUCCESSWARDS:
            return {
              ...state,
              wards: action.payload,
              loadingwards: false
            }
          case types.SUCCESSVILLAGES:
              return {
                ...state,
                villages: action.payload,
                villagesfiltered: action.payload,
                loadingvillages: false
              }
          case types.SUCCESSVILLAGEDATA:
            return {
              ...state,
              village: action.payload,
              loadingvillages: false
            }
          case types.LOADINGDISTRICTS:
               return {
                ...state,
                loadingdistricts: action.payload,
                loading: false
              }
          case types.LOADINGWARDS:
              return {
                ...state,
                loadingwards: action.payload,
                loading: false
              }
          case types.LOADINGVILLAGES:
              return {
                ...state,
                loadingvillages: action.payload,
                loading: false
              }
    case types.SUCCESSPLACES:
      return {
        ...state,
        places: action.payload
      }
      case types.PROPERTYITEMS:
        return {
          ...state,
          propertyitems: action.payload,
          steps: state.steps + 1
        }
      case types.BACK:
        return {
          ...state,
          steps: state.steps === 0 ? 0 : state.steps - 1
        }
      case types.SUCCESSGETPROPERTY:
        return {
          ...state,
          properties: action.payload,
          loadingproperties: false
        }
      case types.SUCCESSGETREGIONPROPERTY:
        return {
          ...state,
          regionproperties: action.payload,
          loadingproperties: false,
          pagination: 2
        }
      case types.SUCCESSPAGINATION:
        return {
          ...state,
          regionproperties: state.regionproperties.concat(action.payload),
          loadingproperties: false,
          pagination: state.pagination + 1,
        }
      case types.SUCCESSGETUSERPROPERTY:
        return {
          ...state,
          userproperties: action.payload,
          userpropertiesfilter: action.payload,
          loadinguserproperties: false,
        }
      case types.LOADINGUSERPROPERTIES:
        return {
          ...state,
          loadinguserproperties: action.payload
        }
      case types.SUCCESSGETSINGLEPROPERTY:
        return {
          ...state,
          singleproperty: action.payload
        }
      case types.PROPERTYEDITDATA:
        return {
          ...state,
          propertyitems: action.payload.data,
          propertyeditdata: action.payload.id,
          toedit: true
        }
      case types.CLEARTOEDIT:
        return {
          ...state,
          toedit: false
        }
      case types.CLEARPROP:
        return {
          ...state,
          propertyitems: {}
        }
      case types.SUCCESSGETSLIDER:
      return {
        ...state,
        sliderproperties: action.payload
      }
      case types.LOADINGSLIDER:
        return {
          ...state,
          loadingslider: action.payload
        }
      case types.SUCCESSTERMS:
        return {
          ...state,
          terms: action.payload
        }
      case types.SUCCESSABOUT:
        return {
          ...state,
          about: action.payload
        }
      case types.SUCCESSHOW:
        return {
          ...state,
          how: action.payload
        }
      case types.SUCCESSWHY:
        return {
          ...state,
          why: action.payload
        }
      case types.LANGUAGE:
        return {
          ...state,
          language: action.payload
        }
        case types.SUCCESSAGENTS:
          return {
            ...state,
            agents: action.payload
          }
      case types.SUCCESSTEAM:
        return {
          ...state,
          team: action.payload
        }
      case types.SUCCESSFAQ:
        return {
          ...state,
          faq: action.payload
        }
      case types.SUCCESSPRIVACY:
        return {
          ...state,
          privacy: action.payload
        }
      case types.SUCCESSUNLIKE:
        return {
          ...state,
          unlike: action.payload
        }
      case types.SUCCESSLIKE:
        return {
          ...state,
          like: action.payload
        }
      case types.SUCCESSEDITPHOTO:
        return {
          ...state,
          successupload: true,
          avatar: action.payload
        }
      case types.LOADINGUPLOAD:
        return {
          ...state,
          loadingupload: action.payload
        }
      case types.SUCCESSDASHBOARD:
      return {
        ...state,
        stats: action.payload
      }
      case types.LOADINGGOOGLE:
        return {
          ...state,
          loadingsocial: action.payload
        }
      case types.FILTERPROP:
        return {
          ...state,
          userproperties: action.payload
        }
      case types.SUCCESSEDITPROFILE:
        return {
          ...state,
          successedit: true,
          editprofile: action.payload
        } 
      case types.LOADINGEDIT:
        return {
          ...state,
          loadingedit: action.payload,
        }
      case types.SUCCESSPROFILE:
        return {
          ...state,
          profile: action.payload
        }
      case types.SUCCESSVILLAGES:
        return {
          ...state,
          villages: action.payload,
          villagesfiltered: action.payload,
          loadingvillages: false
        }
      case types.LOADINGVILLAGES:
        return {
          ...state,
          loadingvillages: action.payload
        }
      case types.FILTEREDVILLAGES:
        return {
          ...state,
          villagesfiltered: action.payload
        }
      case types.SUCCESSCATEGORIES:
        return {
          ...state,
          categories: action.payload
        }
    default:
      return state
  }
}



