import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import{Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
width: '100%',
display: 'flex',
flexDirection: 'row',
alignItems: 'center',
justifyContent: 'space-evenly',
flexWrap: 'wrap'
    },
   photo: {
       width: '100%',
       height: '100%',
       display: 'block',
       objectFit: 'cover',
       top: 0,
       left: 0,
       zIndex: 1,
       position: 'absolute'
   },
   photocontainer: {
       width: '100%',
       height: 200,
       position: 'relative',
       overflow: 'hidden',
       borderRadius: 20,
       marginBottom: 20,
       [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 200
      },
   },
   photocontainertwo: {
    textDecoration: 'none',
     color: '#fff',
      width: '18%',
    [theme.breakpoints.down('md')]: {
     width: '48%',
   },
},
   wordscontainer: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    top: 0,
    left: 0,
    zIndex: 9,
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 42.73%, #000000 100%)'
   },
word: {
    top: '80%',
    left: '5%',
    position: 'absolute',
    color: '#fff',
    fontWeight: '800',
}
  }));




function Productcontainer({image, name, id}) {
    const classes = useStyles()

  return (
    <>
    <Link to={`/properties?street=${id}`} className={classes.photocontainertwo}>
         <div className={classes.photocontainer}>
            {image ?  <img src={image} alt='streetphoto' className={classes.photo}/> : null}
             <div className={classes.wordscontainer}>
                 <span className={classes.word}>{name}</span>
             </div>
         </div>
         </Link>
         </>
  )
}

export default Productcontainer;
