import React from 'react';




const House = () => {
    return (
        <svg width="144" height="121" viewBox="0 0 144 121" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M63.2168 116.694H64.0232C63.8468 115.943 63.5732 115.221 63.2168 114.539V116.694Z" fill="#D4E1F4"/>
<path d="M68.2532 37.0863C73.4192 37.0863 77.606 32.9098 77.606 27.7565C77.606 22.6033 73.4192 18.4268 68.2532 18.4268C63.0872 18.4268 58.9004 22.6033 58.9004 27.7565C58.9076 32.9062 63.0908 37.0792 68.2532 37.0863ZM68.2532 22.7325C71.0324 22.7325 73.2896 24.9806 73.2896 27.7565C73.2896 30.5289 71.036 32.7806 68.2532 32.7806C65.474 32.7806 63.2168 30.5325 63.2168 27.7565C63.2204 24.9806 65.474 22.7361 68.2532 22.7325Z" fill="#1849AA"/>
<path d="M53.2302 116.694H4.46458C4.29178 117.398 4.20898 118.124 4.20898 118.849C4.20898 120.038 5.17738 121.004 6.36898 121.004H51.333C52.5246 121.004 53.493 120.038 53.493 118.849C53.4894 118.124 53.403 117.398 53.2302 116.694Z" fill="#49B2FF"/>
<path d="M138.241 93.6713C136.502 93.6677 134.857 94.4506 133.762 95.7973C132.668 97.144 132.247 98.9144 132.614 100.609L130.98 101.64V93.7647C135.195 92.7628 138.169 89.01 138.172 84.6899V75.1267C138.172 69.9734 133.986 65.7969 128.82 65.7969C123.654 65.7969 119.467 69.9734 119.467 75.1267V84.6935C119.474 89.0136 122.448 92.7664 126.66 93.7683V105.921L124.536 104.405C123.564 103.716 122.217 103.938 121.526 104.904C120.835 105.87 121.058 107.217 122.026 107.907L126.66 111.207V118.842C126.66 120.03 127.628 120.996 128.82 120.996C130.011 120.996 130.98 120.034 130.98 118.842V106.736L135.037 104.175C137.085 105.547 139.782 105.465 141.744 103.967C143.702 102.473 144.487 99.8948 143.695 97.5641C142.903 95.2335 140.71 93.6677 138.241 93.6713ZM123.787 84.6935V75.1267C123.787 72.3507 126.04 70.1027 128.823 70.1027C131.606 70.1027 133.86 72.3507 133.86 75.1267V84.6935C133.856 86.6327 132.736 88.3959 130.983 89.2255V87.4299C130.983 86.2413 130.018 85.2752 128.823 85.2752C127.632 85.2752 126.663 86.2377 126.663 87.4299V89.2255C124.91 88.3959 123.79 86.6327 123.787 84.6935ZM138.241 100.846C137.445 100.846 136.801 100.204 136.801 99.41C136.801 98.6163 137.445 97.9735 138.241 97.9735C139.036 97.9735 139.681 98.6163 139.681 99.41C139.681 100.204 139.036 100.846 138.241 100.846Z" fill="#49B2FF"/>
<path d="M140.04 116.694H117.172V40.3466L133.113 51.9244C134.078 52.6247 135.424 52.4128 136.126 51.4504C136.828 50.488 136.616 49.1449 135.651 48.4446L117.169 35.0209V7.83954C117.169 6.65088 116.2 5.68486 115.009 5.68486H102.42C101.228 5.68486 100.26 6.65088 100.26 7.83954V22.7356L69.5228 0.41306C68.7668 -0.136385 67.7408 -0.136385 66.9812 0.41306L0.852798 48.441C-0.0832019 49.1485 -0.277602 50.4736 0.413598 51.4217C1.1048 52.3661 2.426 52.5924 3.3908 51.9208L19.3316 40.343V98.8463C20.0048 98.6524 20.7032 98.5518 21.4016 98.5554C22.1648 98.5554 22.9208 98.6703 23.648 98.893V37.2079L68.252 4.81221L112.856 37.2079V116.694H84.7976V75.7912C84.7976 74.6025 83.8292 73.6365 82.6376 73.6365H61.0556C59.864 73.6365 58.8956 74.6025 58.8956 75.7912V116.698H3.6608C2.4692 116.698 1.5008 117.664 1.5008 118.853C1.5008 120.041 2.4692 121.007 3.6608 121.007H140.04C141.231 121.007 142.2 120.041 142.2 118.853C142.196 117.66 141.231 116.694 140.04 116.694ZM80.4812 116.694H63.2156V77.9423H80.4812V116.694ZM112.856 31.8858L104.58 25.8707V9.99064H112.856V31.8858Z" fill="#1849AA"/>
<path d="M44.1352 109.519H43.9516C45.5824 106.348 44.7652 102.473 41.9932 100.225C39.2212 97.977 35.2468 97.977 32.4748 100.225C29.6992 102.47 28.882 106.348 30.5164 109.519H28.1296C29.332 107.185 29.2276 104.394 27.856 102.157C26.4844 99.9163 24.04 98.5552 21.4084 98.5588C20.71 98.5588 20.0116 98.6558 19.3384 98.8497C17.164 99.4638 15.382 101.019 14.482 103.087C13.582 105.156 13.6576 107.515 14.6908 109.519H13.564C9.2332 109.523 5.4676 112.492 4.4668 116.694H9.0172C9.8488 114.942 11.62 113.825 13.564 113.825H44.1388C46.0828 113.825 47.854 114.942 48.6892 116.694H53.236C52.228 112.489 48.466 109.523 44.1352 109.519ZM21.4048 109.321C19.6192 109.321 18.1684 107.878 18.1684 106.093C18.1684 104.312 19.6192 102.865 21.4048 102.865C23.194 102.865 24.6412 104.312 24.6412 106.093C24.6376 107.874 23.1904 109.318 21.4048 109.321ZM37.2304 109.321C35.4448 109.321 33.994 107.878 33.994 106.093C33.994 104.312 35.4448 102.865 37.2304 102.865C39.0196 102.865 40.4668 104.312 40.4668 106.093C40.4668 107.874 39.016 109.318 37.2304 109.321Z" fill="#49B2FF"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="144" height="121" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}


export default House;