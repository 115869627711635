import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core';
import Layout from '../layout';
import Breadcrumbs from './Breadcrumbs';
import Productheading from './Productheader';
import Footer from './Footer';
import Photocontainer from './Photocontainer';
import Details from './detailscontainer';
import {useParams} from 'react-router-dom';
import {getSingleProperty} from '../store/actions/appactions';
import {useSelector, useDispatch} from 'react-redux';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%'
   },
  }));




function Home() {
    const classes = useStyles()
    let { propertyid } = useParams();
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);

    useEffect(() => {
      dispatch(getSingleProperty(propertyid))
    }, [])

  return (
      <Layout>
        <div className={classes.root}>
       <Photocontainer/> 
        <Productheading/>
        <Details/>
        <Footer/>
        </div>

      </Layout>


  )
}

export default Home;
