import React from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core';
import Layout from '../layout';
import Footer from './Footer';
import Profiledetails from './profileeditform';
import Subscriptionstatus from './subscriptionstatus';
import Favourites from './favourites';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%',
     marginTop: 100,
     [theme.breakpoints.down('md')]: {
      marginTop: 0
    },
   },
  }));

  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function Home(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
  return (
      <Layout>
        <div className={classes.root}>
      <Profiledetails/>
        <Footer/>
        </div>  
   
      </Layout>
   
   
  )
}

export default Home;
