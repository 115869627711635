import React from 'react';




const House = () => {
    return (
        <svg width="119" height="121" viewBox="0 0 119 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.5036 59.161C29.4328 48.6408 28.4009 35.1824 33.7854 23.7081C39.17 12.2338 50.1802 4.42509 62.7892 3.13949C61.5632 3.01458 60.3324 2.95117 59.0996 2.9502C45.7642 2.9406 33.5097 10.2881 27.2344 22.0555C20.9592 33.8228 21.6866 48.0922 29.1244 59.161L59.0996 103.81L62.7892 98.3141L36.5036 59.161Z" fill="#D4E1F4"/>
        <path d="M59.0991 121C87.7493 121 118.198 115.304 118.198 104.746C118.198 100.45 113.312 96.7783 103.675 93.8362C95.6176 91.3765 84.6929 89.6441 72.6623 88.9023L91.5254 60.8055C101.896 45.3015 99.8827 24.633 86.7154 11.4205C71.4881 -3.80683 46.7101 -3.80683 31.4827 11.4205C18.3164 24.633 16.3035 45.3015 26.6738 60.8055L45.5359 88.9023C33.5226 89.6431 22.6114 91.3707 14.5576 93.8256C4.89834 96.7696 0 100.444 0 104.746C0 115.304 30.4499 121 59.0991 121ZM33.5697 13.5074C46.711 0.367039 67.6803 -0.651447 82.0333 11.1534C96.3853 22.9582 99.4321 43.7305 89.0743 59.1596L59.0991 103.81L29.1239 59.1606C19.5376 44.8278 21.3988 25.7216 33.5697 13.5074ZM15.4176 96.6486C23.6423 94.1427 34.9763 92.4103 47.447 91.7483L57.874 107.28C58.1479 107.689 58.6081 107.933 59.0991 107.933C59.591 107.933 60.0503 107.689 60.3242 107.28L70.7521 91.7483C83.2382 92.4113 94.5866 94.1475 102.813 96.6582C110.599 99.0362 115.247 102.059 115.247 104.746C115.247 108.415 107.102 111.812 99.4782 113.766C88.7111 116.528 74.3706 118.048 59.0991 118.048C43.8276 118.048 29.4871 116.528 18.72 113.767C11.0977 111.812 2.95169 108.415 2.95169 104.746C2.95169 102.055 7.61174 99.0286 15.4176 96.6486Z" fill="#1849AA"/>
        <path d="M59.099 10.0654C43.0636 10.0654 30.0645 23.0655 30.0645 39.1009C30.0645 55.1363 43.0636 68.1355 59.099 68.1355C75.1344 68.1355 88.1345 55.1363 88.1345 39.1009C88.1163 23.0723 75.1277 10.0837 59.099 10.0654ZM59.099 65.1838C44.6941 65.1838 33.0161 53.5058 33.0161 39.1009C33.0161 24.6951 44.6941 13.0171 59.099 13.0171C73.5048 13.0171 85.1828 24.6951 85.1828 39.1009C85.1655 53.4991 73.4971 65.1665 59.099 65.1828V65.1838Z" fill="#49B2FF"/>
        </svg>
        

    )
}


export default House;