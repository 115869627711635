import '../App.css';
import {ButtonBase, Divider, makeStyles } from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import Likes from '@material-ui/icons/FavoriteBorder';
import Sofa from '@material-ui/icons/Weekend';
import Shower from '@material-ui/icons/Bathtub';
import Car from '@material-ui/icons/DriveEta';
import Measurement from '@material-ui/icons/BorderStyle';
import Phone from '@material-ui/icons/Phone';
import {Link, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Like, Unlike} from '../store/actions/appactions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
   productcontainer: {
       width: '100%',
       height: 500,
       borderRadius: 25,
       display: 'flex',
       flexDirection: 'column',
       overflow: 'hidden',
       margin: 10,
       [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 0,
      },
   },
   photo: {
       width: '100%',
       height: '100%',
       display: 'block',
       objectFit: 'cover',
       top: 0,
       left: 0,
       zIndex: 1,
       position: 'absolute'
   },
   photowords: {
       width: '100%',
       height: '100%',
       top: 0,
       left: 0,
       zIndex: 9,
       position: 'absolute',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'space-between',
       flexDirection: 'column',
       background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 67.39%, rgba(0, 0, 0, 0.81) 100%)',
   },
   photocontainer: {
       flex: 1,
       width: '100%',
       position: 'relative',
       overflow: 'hidden'
   },
   wordscontainer: {
       flex: 1,
       width: '100%',
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'space-evenly',
       backgroundColor: '#fff'
   },
   featured: {
       height: 30,
       backgroundColor: '#1849AA',
       paddingLeft: 10,
       paddingRight: 10,
       borderRadius: 15,
       color: '#fff',
   },
   forrent: {
    height: 30,
    backgroundColor: '#222222',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15,
    color: '#fff',
   },
   like: {
    height: 30,
    width: 30,
    backgroundColor: 'red',
    borderRadius: 15,
    color: '#fff',
   },
   unlike: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff',
   },
   liketwo: {
    height: 30,
    width: 30,
    backgroundColor: '#1849AA',
    borderRadius: 15,
    color: '#fff'
   },
   photowordsinside: {
       width: '100%',
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       justifyContent: 'space-between',
       paddingLeft: 10,
       paddingRight: 10,
       paddingTop: 20,
       position: 'absolute',
       zIndex: 99
   },
   photowordsinsidetwo: {
    position: 'absolute',
    zIndex: 99,
    top: '80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20
},
wordsone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
},
spanone: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
},
spantwo: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
},
spanthree: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
},
spanfour: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
},
wordstwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
},
wordsthree: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20
}
  }));




function Productcontainer({image, name, category, propertyid, address, price, size, bathrooms, bedrooms,id, type_id, liked}) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const phoneprice = price ? new Intl.NumberFormat().format(price) : "---";
    const [likes, setLikes] = useState([]);
    let history = useHistory();
    const {t} = useTranslation();

    const rate = (id) => {
        if(app?.userdata?.token){
            if(likes.includes(id)){
                const dat = likes.filter(ids => ids !== id);
                setLikes(dat);
                dispatch(Unlike(id, app.userdata.token))
              }else{
                  setLikes([...likes, id]);
                  dispatch(Like(id, app?.userdata?.token))
              }
        }else{
            history.push('/auth')
        }
    }
  return (
    <>
      <div className={classes.productcontainer}>
          <div className={classes.photocontainer}>
           {image ? <img src={image} className={classes.photo} onClick={() => {
              history.push(`/propertydetails/${id}`)
          }}/> : null}
           
               <div className={classes.photowordsinside}>
                   <ButtonBase></ButtonBase>
                   <ButtonBase className={classes.forrent}>{type_id === 1 ? `${t("For Rent")}` : type_id === 2 ? `${t("For Sell")}` : type_id === 3 ? "Plot" : null}</ButtonBase>
               </div>
               <div className={classes.photowordsinsidetwo}>
                   <span style={{color: '#fff'}}>{category}</span>
                   {liked ? null : <ButtonBase className={likes.includes(propertyid) ? classes.like : classes.unlike} onClick={() => {rate(propertyid)}}><Likes style={{fontSize: 16}}/></ButtonBase>}
               </div>
          </div>
          <div className={classes.wordscontainer} onClick={() => {
              history.push(`/propertydetails/${id}`)
          }}>
             <div className={classes.wordsone}>
                 <span className={classes.spanone}>{name}</span>
                 <span className={classes.spantwo}>{address}</span>
             </div>
             <div className={classes.wordstwo}>
             <span className={classes.spanthree}>TSh {phoneprice || '---'}/<span className={classes.spanfour}>{app?.language === 'english' ? "30 days" : "siku 30"}</span></span>
               {type_id === 1 ?   <span className={classes.spanthree}>{app?.language === 'english' ? '1 Installment' : app?.language === 'kiswahili' ? 'Awamu 1' : '1 Installment'}/<span className={classes.spanfour}>{app?.language === 'english' ? "30 days" : "siku 30"}</span></span> : null}
             </div>
             <div className={classes.wordsthree}>
                 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 2, justifyContent: 'space-evenly'}}><Sofa style={{color: '#1849AA'}}/>{bedrooms}<Divider orientation="vertical" flexItem /><Shower style={{color: '#1849AA'}}/>{bathrooms}<Divider orientation="vertical" flexItem /><Car style={{color: '#1849AA'}}/><Divider orientation="vertical" flexItem /><Measurement style={{color: '#1849AA'}}/> <span>{size}SqFt</span></div>
                 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-end'}}><ButtonBase className={classes.liketwo}><Phone style={{fontSize: 16}}/></ButtonBase> </div>
             </div>
          </div>
      </div>
   </>
  )
}

export default Productcontainer;
