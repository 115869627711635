import '../App.css';
import { ButtonBase, makeStyles } from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import OwlCarousel from 'react-owl-carousel';
import Productcontainer from './singleproduct';
import Words from './headerwords';
import {useSelector, useDispatch} from 'react-redux';
import {getProperties} from '../store/actions/appactions';
import Skeleton from '@material-ui/lab/Skeleton';
import {Box} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
   item: {
       width: '100%',
       height: 500,
       marginBottom: 100
   },
   button: {
    width: '230px',
    height: '56px',   
    background: '#1849AA',
    borderRadius: '94px',
    color: '#fff'
   }
  }));

  
  

function Home(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
   const options = {
        loop: true,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 3,
            },
        },
    }
    useEffect(() => {
        dispatch(getProperties());
    }, []);

    const loadingcontent = ['one', 'two', 'three'];

  return (
      <div style={{width: '100%', paddingTop: 50, paddingBottom: 100}}>
          <Words header='Affordable plots' headermini='New plots new home'/>
          {app?.loadingproperties ?  
          <OwlCarousel className='owl-theme' {...options} loop margin={20}>
               { app.loadingproperties ? 
               loadingcontent.map(load => {
                   return (
                     <Box pt={0.5} key={load}>
                     <Skeleton variant="rect" width='100%' height={300} />
                   <Skeleton />
                   <Skeleton width="60%" />
                   <Skeleton width="60%" />
                   <Skeleton width="100%" />
                 </Box>
                   )
               }) : null}</OwlCarousel>
                : app?.error?.type === 'getproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>An error occured, please try again later</span>
      </div> : app.properties && app?.properties.filter(property => property.type_id === 3).length > 0 && !app?.error?.type !== 'getproperty' && !app?.loadingproperties ?
       <OwlCarousel className='owl-theme' {...options} loop margin={20}>
             {app.properties ? app.properties.filter(property => property.type_id === 3).map((property) => {
        return (
            <Productcontainer image={property?.thumbnails[0] || 'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG91c2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60'} address={property.street_address} name={property.title} size={property.property_size} price={property.rent_prices} bathrooms={property.number_of_bathrooms} bedrooms={property.number_of_bedrooms} id={property.id} type_id={property.type_id} propertyid={property.id}/>
        )
    }) : null}</OwlCarousel>
: app?.properties && app?.properties.filter(property => property.type_id === 3).length === 0 ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
<span style={{color: 'black'}}>No Plots Found</span>
</div> : null }
    {/* <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
        <ButtonBase className={classes.button}>Explore more places</ButtonBase>
    </div> */}
    {/* <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
        <ButtonBase className={classes.button}>Explore more plots</ButtonBase>
    </div> */}
      </div>
   
   
  )
}

export default Home;
