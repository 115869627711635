import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
     flexDirection: 'column',
     paddingBottom: 50
   },
   bigtext: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',  
    textAlign: 'center', 
    color: '#222222',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
    },
   },
   smalltext: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '24px', 
    textAlign: 'center',
    color: '#222222',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    
   }
  }));

  
  

function Home({header, headermini}) {
    const classes = useStyles()
    const {t} = useTranslation();
  return (
     
    <div className={classes.root}>
      <span className={classes.bigtext}>{t(`${header}`)}</span>
      <span className={classes.smalltext}>{t(`${headermini}`)}</span>
   </div>  
   
  )
}

export default Home;
