import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, ButtonBase, CircularProgress, DialogActions} from '@material-ui/core';
import Likes from '@material-ui/icons/FavoriteBorder';
import Share from '@material-ui/icons/Share';
import { useDispatch, useSelector } from 'react-redux';
import {Like, Unlike} from '../store/actions/appactions';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import {
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
  container: {
      paddingTop: 100,
      paddingBottom: 20,
      [theme.breakpoints.down('md')]: {
        paddingTop: 20
      },
  },
  heading: {
      fontSize: 25,
      fontWeight: '700',
      [theme.breakpoints.down('md')]: {
        maxWidth: '60%'
      },
      
  },
  subheading: {
      fontSize: 16,
      fontWeight: '400',
      [theme.breakpoints.down('md')]: {
        paddingBottom: 50
      },
  },
  griditem: {
      display: 'flex',
      flexDirection : 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      },
  },
  featured: {
    height: 30,
    backgroundColor: '#1849AA',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15,
    color: '#fff',
    marginRight: 5,
},
forrent: {
 height: 30,
 backgroundColor: '#3EBF45',
 paddingLeft: 10,
 paddingRight: 10,
 borderRadius: 15,
 color: '#fff',
 marginLeft: 5,
 minWidth: 80
},
like: {
    height: 30,
    width: 30,
    backgroundColor: 'red',
    borderRadius: 15,
    color: '#fff',
    marginRight: 5
   },
   unlike: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff',
    marginRight: 5
   },
   liketwo: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff'
   },
}));

export default function CustomSeparator() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [open, setOpen] = useState(false);
  const [likes, setLikes] = useState([]);
  const history = useHistory();
  const {t} = useTranslation();
  const rate = (id) => {
    if(app?.userdata?.token){
      if(likes.includes(id)){
        const dat = likes.filter(ids => ids !== id);
        setLikes(dat);
        dispatch(Unlike(id, app.userdata.token))
      }else{
          setLikes([...likes, id]);
          dispatch(Like(id, app.userdata.token))
      }
    }else{
      history.push('/auth')
    }
}

const handleOpen = () => {
  setOpen(true);
}

const handleClose = () => {
  setOpen(false);
}

const shareUrl = `Hi! I found this property on Eneo that you might like, check: https://eneo.co.tz/propertydetails/${app?.singleproperty?.id}`;
const title = app?.singleproperty?.title;
  return (
    <>
     <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Share property via</DialogTitle>
    <div style={{width: '80%', margin: '0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
       <FacebookShareButton
            url={shareUrl}
            quote={title}
            description={app?.singleproperty?.overview}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
           <TwitterShareButton url={shareUrl}
            quote={title}
            description={app?.singleproperty?.overview}
            ><TwitterIcon size={32} round/></TwitterShareButton>
           <WhatsappShareButton url={shareUrl}
            quote={title}
            description={app?.singleproperty?.overview}
            >
             <WhatsappIcon size={32} round/>
           </WhatsappShareButton>
           
    </div>
             
  <DialogActions/>

    </Dialog>
    <Container className={classes.container}>
      {app?.loadingproperties ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color="#556cd6"/>
      </div> : app?.error?.type === 'getsingleproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>An error occured, please try again later</span>
      </div> : app.singleproperty && !app?.error?.type !== 'getsingleproperty' && !app?.loadingproperties ? <Grid container>
          <Grid item xs={12} md={6} lg={6} className={classes.griditem}>
              <span className={classes.heading}>{app?.language === 'english' ? app?.singleproperty.title : app?.language === 'kiswahili' ? app?.singleproperty?.title_sw || app?.singleproperty?.title : null}<br/><span className={classes.subheading}>{app?.singleproperty?.street_address}</span></span>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {/* <ButtonBase className={classes.featured}>Featured</ButtonBase> */}
            <ButtonBase className={classes.forrent}>{app.singleproperty.type_id === 1 ? `${t('For Rent')}` : app.singleproperty.type_id === 2 ? "For Sell" : app.singleproperty.type_id === 3 ? "Plot" : null}</ButtonBase>
              </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.griditem}>
              <div></div>
              <div>
              <ButtonBase onClick={() => {handleOpen()}} className={classes.unlike}><Share style={{fontSize: 16}}/></ButtonBase>
              <ButtonBase onClick={() => {rate(app.singleproperty.id)}} className={likes.includes(app.singleproperty.id) ? classes.like : classes.unlike}><Likes style={{fontSize: 16}}/></ButtonBase>
              </div>
          </Grid>
      </Grid> : null}
    </Container>
    </>
  );
}
