import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Sofa from '@material-ui/icons/Weekend';
import Shower from '@material-ui/icons/Bathtub';
import Car from '@material-ui/icons/DriveEta';
import Measurement from '@material-ui/icons/BorderStyle';
import Calender from '@material-ui/icons/CalendarToday';
import Phone from '@material-ui/icons/Phone';
import Chat from '@material-ui/icons/WhatsApp'
import { Container, Grid, ButtonBase, CircularProgress, Button} from '@material-ui/core';
import { useSelector } from 'react-redux';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  roottwo: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'none'
      },
  },
  container: {
      paddingTop: 50,
      paddingBottom: 50,
  },
  griditem: {
      height: 200,
      backgroundColor: '#fff',
      border: '1px solid rgba(34, 34, 34, 0.05)',
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        height: 260,
        paddingTop: 20
      },
  },
  griditemthree: {
    backgroundColor: '#fff',
    border: '1px solid rgba(34, 34, 34, 0.05)',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 100,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      marginTop: 50
    },
},
  griditemtwo: {
    height: 200,
    backgroundColor: '#fff',
    border: '1px solid rgba(34, 34, 34, 0.05)',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
      borderRadius: 0
    },
},
  overview: {
      color: '#222222',
      fontWeight: '700',
  },
  headingoverview: {
    fontWeight: '400',
    color: '#222222',
    textAlign: 'justify',
    lineHeight: 2,
  },
  desc: {
      width: '95%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        paddingBottom: 50
      },
  },
  heading: {
    width: '95%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 50,
      paddingBottom: 20,
    },
  },
  descitem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '30%',
        marginBottom: 20,
        marginLeft: '3%'
      },

  },
  descheading: {
      fontWeight: 'normal',
      color: 'rgba(34, 34, 34, 0.5)',
      paddingBottom: 20
  },
  descsubheading: {
    fontWeight: '500',
    color: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12
  },
  descsubheadingtwo: {
    fontWeight: '500',
    color: '#222222',
    fontSize: 16,
    paddingTop: 3
  },
  headingtwo: {
      fontSize: 25,
      color: '#222222',
      fontWeight: '700'
  },
  buttons: {
      width: '95%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
  },
  buttonstwo: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },

},
  buttonfive: {
    width: '130px',
    height: '46px',
    fontSize: 15,
    borderRadius: '54px',
    backgroundColor: '#3EBF45',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    marginRight: 10,
    "&:hover": {
      backgroundColor: '#3EBF45',
    }
  },
  buttonfivecc: {
    paddingLeft: 10,
    paddingRight: 10,
    height: '46px',
    fontSize: 15,
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    marginRight: 10,
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  buttonfivetwo: {
    width: '130px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    fontSize: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    color: '#fff',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomSeparator() {
  const classes = useStyles();
  const app = useSelector(state => state.app);
  const {t} = useTranslation();

  const geo = app?.singleproperty?.geo_location || null;
  const geoarray = geo ? geo.split(',') : null;
  return (
    <>
    <Container className={classes.container}>
       {app?.loadingproperties ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color="#556cd6"/>
      </div> : app?.error?.type === 'getsingleproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>{t("An error occured, please try again later")}</span>
      </div> : app.singleproperty && !app?.error?.type !== 'getsingleproperty' && !app?.loadingproperties ? <>
       <Grid container spacing={2}>
           <Grid item xs={12} md={7} lg={7} className={classes.griditem}>
           <div className={classes.heading}><span>{t("Overview")}</span></div>
           <div className={classes.desc}>
               <div className={classes.descitem}>
                   <span className={classes.descheading}>{t("Property type")}</span>
                   <span className={classes.descsubheadingtwo}>{t("Apartment")}</span>
               </div>
               <div className={classes.descitem}>
                   <span className={classes.descheading}>{t("Bedrooms")}</span>
                   <span className={classes.descsubheading}><Sofa style={{paddingLeft: 5, fontSize: 25}}/>&nbsp;&nbsp; {app.singleproperty.number_of_bedrooms}</span>
               </div>
               <div className={classes.descitem}>
                   <span className={classes.descheading}>{t("Bathrooms")}</span>
                   <span className={classes.descsubheading}><Shower style={{paddingLeft: 5, fontSize: 25}}/>&nbsp;&nbsp; {app.singleproperty.number_of_bathrooms}</span>
               </div>
               <div className={classes.descitem}>
                   <span className={classes.descheading}>{t("SquareFt")}</span>
                   <span className={classes.descsubheading}><Measurement style={{paddingLeft: 5, fontSize: 25}}/>&nbsp;&nbsp; {app.singleproperty.property_size}</span>
               </div>
               {/* <div className={classes.descitem}>
                   <span className={classes.descheading}>{t("Year Built")}</span>
                   <span className={classes.descsubheading}><Calender style={{paddingLeft: 5, fontSize: 25}}/>&nbsp;&nbsp; {app.singleproperty.year_built}</span>
               </div> */}
           </div>
           </Grid>
           <Grid item xs={0} md={1} lg={1}></Grid>
           <Grid item xs={12} md={4} lg={4} className={classes.griditemtwo}>
          <span className={classes.headingtwo}>TZS {new Intl.NumberFormat().format(app.singleproperty?.price_per_month)}</span>
          <div className={classes.buttonstwo}>
            <a href={app?.userdata?.token ? `https://wa.me/${app?.singleproperty?.phone}` : `/auth`} style={{textDecoration: 'none'}}>
              <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonfive}>
             {t("Chat")} <Chat style={{fontSize: 18, marginLeft: 10}}/>
            </ButtonBase> 
            </a>
            <a href={app?.userdata?.token ? `tel://+${app?.singleproperty?.phone}` : `/auth`} style={{textDecoration: 'none'}}>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonfivecc}>
             {app?.userdata?.token ? `+${app?.singleproperty?.phone}` : `${t("Call")}`} <Phone style={{fontSize: 18, marginLeft: 10}}/>
            </ButtonBase> 
            </a>
          </div>
           </Grid>
       </Grid>
       <Grid container spacing={2}>
           <Grid item xs={12} md={12} lg={7} className={classes.griditemthree}>
           <div style={{width: '90%', paddingTop: 30, }}>
            <span className={classes.heading}>{t("Description")}</span>
           </div>
           <div style={{width: '90%', paddingTop: 20,  paddingBottom: 50}}>
            <span className={classes.headingoverview}>
            {app?.language === 'english' ? app?.singleproperty?.overview : app?.language === 'kiswahili' ? app?.singleproperty?.overview_sw || app?.singleproperty?.overview : null}
           </span>
           </div>
           </Grid>
        </Grid>
        {/* <Grid container spacing={2}>
           <Grid item xs={7} className={classes.griditemthree}>
           <div style={{width: '90%', paddingTop: 30, }}>
            <span className={classes.heading}>Details</span>
           </div>
           <div style={{width: '90%', marginTop: 30,  paddingTop: 20,  paddingBottom: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Property ID</span>
            <span>ECD125686</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75);', fontWeight: '700'}}>Garage</span>
            <span>1</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Price</span>
            <span>TZS 200,000,000</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Garage size</span>
            <span>200 SqFt</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Property size</span>
            <span>800SqFt</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Year Built</span>
            <span>2020</span>
            </div>
           </div>
           </Grid>
        </Grid>
        <Grid container spacing={2}> */}
           {/* <Grid item xs={7} className={classes.griditemthree}>
           <div style={{width: '90%', paddingTop: 30, }}>
            <span className={classes.heading}>Extra Details</span>
           </div>
           <div style={{width: '90%', marginTop: 30,  paddingTop: 20,  paddingBottom: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Property ID</span>
            <span>ECD125686</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75);', fontWeight: '700'}}>Garage</span>
            <span>1</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Price</span>
            <span>TZS 200,000,000</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Garage size</span>
            <span>200 SqFt</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Property size</span>
            <span>800SqFt</span>
            </div>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span style={{color: 'rgba(34, 34, 34, 0.75)', fontWeight: '700'}}>Year Built</span>
            <span>2020</span>
            </div>
           </div>
           </Grid>
        </Grid> */}
        <Grid container spacing={2}>
           <Grid item xs={12} md={12} lg={7} className={classes.griditemthree}>
           <div style={{width: '90%', paddingTop: 30, }}>
            <span className={classes.heading}>{t("Location")}</span>
           </div>
           <div style={{width: '90%', marginTop: 30,  paddingTop: 20,  paddingBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <div style={{width: '48%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20}}>
            <span>{app.singleproperty.street_address}</span>
            </div>
           </div>
           <div style={{width: '100%', height: 300}}>
           <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCjEFFXjPr1PloDRpmV1Ti4m8iGSeYnNFk&q=${geoarray[0]},${geoarray[1]}`}
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
           </div>
           </Grid>
        </Grid></>: null}
    </Container>
    <div style={{position: 'fixed', bottom: 0, zIndex: '99999999', width: '100%', left: 0}}>
        <BottomNavigation
      className={classes.roottwo}
    >
       <div className={classes.buttons}>
            <a href={app?.userdata?.token ? `https://wa.me/${app?.singleproperty?.phone}` : `/auth`} style={{textDecoration: 'none'}}>
              <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonfive}>
             {t("Chat")} <Chat style={{fontSize: 18, marginLeft: 10}}/>
            </ButtonBase> 
            </a>
            <a href={app?.userdata?.token ? `tel://+${app?.singleproperty?.phone}` : `/auth`} style={{textDecoration: 'none'}}>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonfivetwo}>
             {t("Call")} <Phone style={{fontSize: 18, marginLeft: 10}}/>
            </ButtonBase> 
            </a>
          </div>
    </BottomNavigation>   
      </div>
    </>
  );
}
