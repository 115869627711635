import '../App.css';
import { makeStyles, Grid, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import Dashboard from './dashboard';
import Building from '@material-ui/icons/HomeWork';
import Slider from '@material-ui/core/Slider';
import Dots from '@material-ui/icons/MoreHoriz';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from 'react-rating';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Divider from '@material-ui/core/Divider';
import Deposits from './deposits';
import clsx from 'clsx';
import { getStats } from '../store/actions/appactions';
import { useSelector, useDispatch } from 'react-redux';
import { useTabContext } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';




const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%',
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
     flexDirection: 'column',
     paddingBottom: 50
   },
   bigtext: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',  
    textAlign: 'center', 
    color: '#222222',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
    },
   },
   smalltext: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '24px', 
    textAlign: 'center',
    color: '#222222',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
   },
   properties: {
       height: 100,
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       justifyContent: 'space-evenly',
       borderRadius: 20,
       backgroundColor: '#1849AA'
   },
   propertiestwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: '#fff'
},
propertiesfive: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 20,
  backgroundColor: '#fff',
  marginTop: 20
},
propertiestwotwo: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 20,
  backgroundColor: '#fff',
  marginTop: 20
},
heading: {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20,
  marginTop: 20

},
headingtwo: {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20,
  marginTop: 20
},
headingthree: {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  marginTop: 20
},
iconcontainer: {
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center',
   justifyContent: 'center'
},
iconbox: {
  width: 50,
  height: 50,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#222222'
},
iconboxtwo: {
  width: 50,
  height: 50,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#369B3B'
},
iconwords: {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 10
},
propertiesthree: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 20,
  backgroundColor: '#fff',
  minHeight: 100,
  marginTop: 20,
  marginBottom: 5
},
percent: {
  position: 'absolute',
  width: 40,
  height: 40,
  zIndex: 9,
  marginLeft: 20,
  borderRadius: 20,
  backgroundColor: 'rgba(54, 155, 59, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
},
percenttwo: {
  position: 'absolute',
  width: 40,
  height: 40,
  zIndex: 9,
  marginLeft: 20,
  borderRadius: 20,
  backgroundColor: 'rgba(34, 34, 34, 0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
},
commentcontainer: {
  width: '100%',
   minHeight: 150,
   display: 'flex',
   flexDirection: 'column',
},
commenthead: {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 20,
  paddingRight: 20,
},
commentphotocontainer: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
},
commentphoto: {
  width: 30,
  height: 30,
  borderRadius: 15,
  backgroundColor: '#eeeeee'
},
comment: {
  paddingLeft: 20,
  
},
paper: {
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
},
fixedHeight: {
  height: 240,
},
  }));

  const data = [
    {
      name: 'Page C',
      uv: 0,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page A',
      uv: 4000,
      pv: 1400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    // {
    //   name: 'Page D',
    //   uv: 2780,
    //   pv: 3908,
    //   amt: 2000,
    // },
    // {
    //   name: 'Page E',
    //   uv: 1890,
    //   pv: 4800,
    //   amt: 2181,
    // },
    // {
    //   name: 'Page F',
    //   uv: 2390,
    //   pv: 3800,
    //   amt: 2500,
    // },
    // {
    //   name: 'Page G',
    //   uv: 3490,
    //   pv: 4300,
    //   amt: 2100,
    // },
  ];
  

  const datatwo = [
    {
      name: 'April',
      uv: 0,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'May',
      uv: 4000,
      pv: 1400,
      amt: 2400,
    },
    {
      name: 'June',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'July',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'August',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Sept',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Oct',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Nov',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  

function Home({header, headermini}) {
    const classes = useStyles()
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
      dispatch(getStats(app.userdata.token));
    }, [])
  return (
      <Dashboard>
    <Container style={{width: '100%', height: '100vh', marginTop: 50}}>
      <Grid container spacing={2}>
         <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title={t("Total properties")} stats={app?.stats?.total_properties}/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title={t("Total likes")} stats={app?.stats?.total_likes}/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title={t("Total views")} stats={app?.stats?.total_views}/>
              </Paper>
            </Grid>
            {/* <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid> */}
      </Grid>
      </Container>
    {/* <Grid container spacing={2}>
         <Grid item xs={12} lg={6} md={6}>
           <Grid container>
             <Grid item xs={12} className={classes.properties}>
             <Building style={{color: '#fff', fontSize: 60}}/>
          <div style={{width: 200, display: 'flex', flexDirection: 'column'}}>
              <span style={{color: '#fff', fontWeight: '500', fontSize: 14}}>Total Properties</span>
              <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" style={{color: '#fff'}} size='medium'/>
      <span style={{color: 'rgba(255, 255, 255, 0.65)', fontWeight: 'normal', fontSize: 10}}>431 more to break last month record</span>
      </div>
      <span style={{color: '#fff', fontWeight: '800', fontSize: 44}}>4,367</span>
             </Grid>
             <Grid item xs={12} className={classes.propertiestwotwo}>
           <div className={classes.heading}>
             <span style={{color: 'black', fontWeight: '500', fontSize: 14}}>Overview</span>
             <span style={{color: 'black', fontWeight: '500', fontSize: 14}}><Dots style={{color: '#2E2E2E'}}/></span>
           </div>
           <div className={classes.headingthree}>
               <div className={classes.iconcontainer}>
                 <div className={classes.iconbox}><Building style={{color: '#fff'}}/></div>
                 <div className={classes.iconwords}>
                   <span style={{color: 'rgba(0, 0, 0, 0.5)', fontSize: 10, fontWeight: '400'}}>Total rent</span>
                   <span style={{color: '#222222', fontSize: 14, fontWeight: 'bold'}}>1,252 Unit</span>
                 </div>
               </div>
               <div className={classes.iconcontainer}>
                 <div className={classes.iconboxtwo}>
                 <Building style={{color: '#fff'}}/>
                 </div>
                 <div className={classes.iconwords}>
                   <span style={{color: 'rgba(0, 0, 0, 0.5)', fontSize: 10, fontWeight: '400'}}>Total sale</span>
                   <span style={{color: '#222222', fontSize: 14, fontWeight: 'bold'}}>2,652 Unit</span>
                 </div>
               </div>
           </div>
           <div style={{width: '100%', minHeight: 260, marginTop: 50, paddingBottom: 20}}>
           <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={datatwo}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
      </ResponsiveContainer>
           </div>
         </Grid>
           </Grid>
         </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <Grid container>
          <Grid item xs={12} className={classes.propertiestwo}>
           <div className={classes.heading}>
             <span style={{color: 'black', fontWeight: '500', fontSize: 14}}>Total Revenue</span>
             <span style={{color: 'black', fontWeight: '500', fontSize: 14}}><Dots style={{color: '#2E2E2E'}}/></span>
           </div>
           <div className={classes.headingtwo}>
             <span style={{color: 'black', fontWeight: '700', fontSize: 24}}>TZS 15M</span>
             <span style={{color: '#A1A1A1', fontWeight: '400', fontSize: 10}}>last month TZS 13M</span>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <AreaChart
          width={50}
          height={30}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke="#37D159" fill="#37D159" />
        </AreaChart>
        <span style={{marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#37D159'}}>7% <ArrowDropUp/></span>
            </div>
           </div>
           <div style={{width: '100%', height: 200}}>

           </div>
         </Grid>
         <Grid item xs={12} lg={6} md={6}>
         <Grid container>
         <Grid item xs={12} className={classes.propertiesthree}>
         <div style={{width: 200, display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
         <span style={{color: '#222222', fontWeight: '800', fontSize: 22, paddingBottom: 10}}>4,367</span>
              <span style={{color: '#222222', fontWeight: '500', fontSize: 12}}>Properties for Sale</span>
      <span style={{color: 'rgba(34, 34, 34, 0.5)', fontWeight: 'normal', fontSize: 10}}>Target 3k/month</span>
      </div>
     <div style={{paddingRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
       <CircularProgress variant="determinate" value={100} style={{width: 60, color: '#369B3B'}}/>
       <div className={classes.percent}>
         <span style={{color: '#369B3B', fontSize: 12}}>71%</span>
       </div>
       </div>
         </Grid>
         <Grid item xs={12} className={classes.propertiesthree}>
         <div style={{width: 200, display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
         <span style={{color: '#222222', fontWeight: '800', fontSize: 22, paddingBottom: 10}}>2,206</span>
              <span style={{color: '#222222', fontWeight: '500', fontSize: 12}}>Properties for Rent</span>
      <span style={{color: 'rgba(34, 34, 34, 0.5)', fontWeight: 'normal', fontSize: 10}}>Target 3k/month</span>
      </div>
     <div style={{paddingRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
       <CircularProgress variant="determinate" value={100} style={{width: 60, color: '#222222'}}/>
       <div className={classes.percenttwo}>
         <span style={{color: '#222222', fontSize: 12}}>71%</span>
       </div>
       </div>
         </Grid>
         </Grid>
         </Grid>

          <Grid item xs={12} lg={6} md={6}>
          <Container fixed disableGutters classNam={classes.comment}>
           <Grid item xs={12} className={classes.propertiesfive}>
           <div className={classes.heading}>
             <span style={{color: 'black', fontWeight: '500', fontSize: 14}}>Customer review</span>
             <span style={{color: 'black', fontWeight: '500', fontSize: 14}}><Dots style={{color: '#2E2E2E'}}/></span>
           </div>
           <div style={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: 30}}>
           <div className={classes.commentcontainer}>
           <div className={classes.commenthead}>
           <div className={classes.commentphotocontainer}>
               <div className={classes.commentphoto}></div>
                <span style={{fontSize: 10, color: '#222222', paddingLeft: 10}}>William chacha<br/><Rating fullSymbol={<Star style={{color: '#1849AA', fontSize: 12}}/>} emptySymbol={<StarBorder style={{color: '#1849AA', fontSize: 12}}/>}/></span>
           </div>
           <span style={{fontSize: 8, color: 'rgba(0, 0, 0, 0.6)'}}>15min ago</span>
           </div>
           <span style={{color: '#000000', fontSize: 10, fontWeight: '200', paddingLeft: 20, paddingRight: 20, margin: '0 auto', marginTop: 10}}>
           Neque convallis a cras semper auctor neque vitae tempus quam. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Interdum velit euismod in pellentesque massa. 
           </span>
           <Divider style={{width: '100%', color: 'grey', marginTop: 20}}/>
           </div>
           <div className={classes.commentcontainer}>
           <div className={classes.commenthead}>
           <div className={classes.commentphotocontainer}>
               <div className={classes.commentphoto}></div>
                <span style={{fontSize: 10, color: '#222222', paddingLeft: 10}}>William chacha<br/><Rating fullSymbol={<Star style={{color: '#1849AA', fontSize: 12}}/>} emptySymbol={<StarBorder style={{color: '#1849AA', fontSize: 12}}/>}/></span>
           </div>
           <span style={{fontSize: 8, color: 'rgba(0, 0, 0, 0.6)'}}>15min ago</span>
           </div>
           <span style={{color: '#000000', fontSize: 10, fontWeight: '200', paddingLeft: 20, paddingRight: 20, margin: '0 auto', marginTop: 10}}>
           Neque convallis a cras semper auctor neque vitae tempus quam. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Interdum velit euismod in pellentesque massa. 
           </span>
           <Divider style={{width: '100%', color: 'grey', marginTop: 20}}/>
           </div>
           <div className={classes.commentcontainer}>
           <div className={classes.commenthead}>
           <div className={classes.commentphotocontainer}>
               <div className={classes.commentphoto}></div>
                <span style={{fontSize: 10, color: '#222222', paddingLeft: 10}}>William chacha<br/><Rating fullSymbol={<Star style={{color: '#1849AA', fontSize: 12}}/>} emptySymbol={<StarBorder style={{color: '#1849AA', fontSize: 12}}/>}/></span>
           </div>
           <span style={{fontSize: 8, color: 'rgba(0, 0, 0, 0.6)'}}>15min ago</span>
           </div>
           <span style={{color: '#000000', fontSize: 10, fontWeight: '200', paddingLeft: 20, paddingRight: 20, margin: '0 auto', marginTop: 10}}>
           Neque convallis a cras semper auctor neque vitae tempus quam. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Interdum velit euismod in pellentesque massa. 
           </span>
           <Divider style={{width: '100%', color: 'grey', marginTop: 20}}/>
           </div>
           <div className={classes.commentcontainer}>
           <div className={classes.commenthead}>
           <div className={classes.commentphotocontainer}>
               <div className={classes.commentphoto}></div>
                <span style={{fontSize: 10, color: '#222222', paddingLeft: 10}}>William chacha<br/><Rating fullSymbol={<Star style={{color: '#1849AA', fontSize: 12}}/>} emptySymbol={<StarBorder style={{color: '#1849AA', fontSize: 12}}/>}/></span>
           </div>
           <span style={{fontSize: 8, color: 'rgba(0, 0, 0, 0.6)'}}>15min ago</span>
           </div>
           <span style={{color: '#000000', fontSize: 10, fontWeight: '200', paddingLeft: 20, paddingRight: 20, margin: '0 auto', marginTop: 10}}>
           Neque convallis a cras semper auctor neque vitae tempus quam. Tempus urna et pharetra pharetra massa massa ultricies mi quis. Interdum velit euismod in pellentesque massa. 
           </span>
           <Divider style={{width: '100%', color: 'grey', marginTop: 20}}/>
           </div>
           </div>
         </Grid>
           </Container>
          </Grid>
          </Grid>
        </Grid>
     </Grid>
    </Container> */}
      </Dashboard>
    
  )
}

export default Home;
