import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Grid, Button, ButtonBase} from '@material-ui/core';
import Globe from '@material-ui/icons/Public';
import Person from '@material-ui/icons/PersonOutline';
import Logo from '../components/logos/logo';
import Logotwo from '../components/logos/logotwo';
import {Link} from 'react-router-dom';
import Usericon from '@material-ui/icons/PermIdentity';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Search from '@material-ui/icons/Search';
import {useDispatch, useSelector} from 'react-redux';
import Menuicon from '@material-ui/icons/Menu';
import Swiperble from './dashbordsidebar';
import { useTranslation } from 'react-i18next';
import {language} from '../store/actions/appactions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        [theme.breakpoints.down('sm')]: {
          width: `100%`,
          marginLeft: 0,
          height: '100px',
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
      headercontainer: {
        height: '100px',
        backgroundColor: '#1849AA',
        [theme.breakpoints.down('md')]: {
          height: '100px',
        },
      },
      grid: {
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      menugrid: {
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
      gridtwo: {
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
          display: 'none'
        },
      },
      button: {
        width: '130px',
        height: '46px',
        borderRadius: '54px',
        border: '1px solid rgba(233, 240, 255, 0.5)',
        backgroundColor: 'rgba(233, 240, 255, 0.05)',
        "&:hover": {
          backgroundColor: 'rgba(233, 240, 255, 0.05)',
        }
      },
      buttontwo: {
        width: '130px',
        height: '46px',
        borderRadius: '54px',
        backgroundColor: '#fff',
        color: '#1849AA',
        "&:hover": {
          backgroundColor: '#fff',
        },
      },
      buttonfive: {
        width: '130px',
        height: '46px',
        borderRadius: '54px',
        backgroundColor: '#3EBF45',
        color: '#fff',
        "&:hover": {
          backgroundColor: '#3EBF45',
        }
      },
     buttonthree: {
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 15,
        height: '46px',
        borderRadius: '54px',
        border: '1px solid rgba(233, 240, 255, 0.5)',
        backgroundColor: '#1849AA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": { 
          backgroundColor: '#1849AA',
        }
      },
      buttonthreethree: {
        width: '116px',
        height: '46px',
        borderRadius: '54px',
        border: '1px solid rgba(233, 240, 255, 0.5)',
        backgroundColor: '#1849AA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": { 
          backgroundColor: '#1849AA',
        }
      },
      buttonfour: {
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 15,
        height: '46px',
        borderRadius: '54px',
        backgroundColor: '#2965DB',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": {
          backgroundColor: '#2965DB',
        }
      },
      popper: {
        backgroundColor: '#fff',
        marginTop: 10,
        marginRight: 50,
        borderRadius: 10,
        minWidth: 200,
        zIndex: '999999999'
      },
      searchbar: {
        width: '50%',
        height: 70,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 50,
        position: 'absolute',
        zIndex: '9999999999',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 64
      },
      inputcontainer: {
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'flex-start',
       justifyContent: 'center',
       height: 79
      },
      input: {
      width: '100%',
      backgroundColor: 'transparent',
      border: '0 !important',
      borderColor: 'transparent !important',
      color: '#222222',
      paddingLeft: 20,
      '&:focus, &:active': {
        border: 0,
        borderColor: 'transparent !important',
        outline: 'none !important'
      }
      },
      inputheading: {
        color: '#1849AA',
        fontSize: 14,
        paddingLeft: 20,
      },
      buttonsearch: {
        width: 50,
        height: 50,
        backgroundColor: '#1849AA',
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
      },
      minilogo: {
        [theme.breakpoints.up('md')]: {
          display: 'none'
        },
      },
      searchbardiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          display: 'none'
        },
      },
      logo: {
        [theme.breakpoints.down('md')]: {
          display: 'none'
        },
      },
      logotwo: {
        [theme.breakpoints.up('md')]: {
          display: 'none'
        },
      }
 
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function PermanentDrawerRight(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [openside, setOpenside] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElthree, setAnchorElthree] = React.useState(null);
  const [anchorElone, setAnchorElone] = React.useState(null);
  const [anchorEltwo, setAnchorEltwo] = React.useState(null);
  const {t, i18n} = useTranslation()

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosetwo = (event) => {
    setAnchorElthree(false)
    setAnchorElone(false);
};

const handleClose = (event) => {
  setAnchorEltwo(false)
  setAnchorEl(false);
};

const changelanguage = (lang) => {
  i18n.changeLanguage(lang === 'kiswahili' ? 'sh' : 'en');
  dispatch(language(lang))
}

const handleClicktwo = (event) => {
  setAnchorElone(null)
  setAnchorElthree(anchorElthree ? null : event.currentTarget);
};

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const opentwo = Boolean(anchorElthree);
  const idtwo = opentwo ? 'simple-popper' : undefined;

  const onOpen = () => {
    setOpenside(true);
}

const closesidebar = () => {
    setOpenside(false);
}

  return (
   <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.headercontainer}>
          <Grid container>
            <Grid item xs={3} className={classes.grid}>
          <Link to='/' className={classes.minilogo}>
            <div className={classes.logo}>
              <Logo />
            </div>
            <div className={classes.logotwo}>
            <Logotwo />  
            </div>
            </Link> 
            </Grid>
            <Grid item xs={9} className={classes.menugrid}>
                                <Menuicon color='white' onClick={() => {onOpen()}}/>
                            </Grid>
            <Grid item xs={3} className={classes.gridtwo}>
            {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttontwo}>
             Panga
            </ButtonBase>
            <div style={{backgroundColor: '#fff', width: 10, height: 10, clipPath: 'polygon(100% 0, 0 0, 51% 100%)', position: 'absolute',top: 46,}}></div>
            </div>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.button}>
             Pangisha
            </ButtonBase> */}
            </Grid>
            <Grid item  xs={2} className={classes.gridtwo}>
            {/* <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonfive}>
             Why eneo?
            </ButtonBase>  */}
            </Grid>
            <Grid item xs={4} className={classes.gridtwo}>
              <>
              <ClickAwayListener onClickAway={handleClosetwo}>
            <ButtonBase onClick={handleClicktwo} elevation={0} variant="contained" color="secondary" className={classes.buttonfour}>
            {app.language === 'english' ? 'English' : 'Kiswahili'} <Globe style={{marginLeft: 10, color: '#8BAFF6'}}/>
            </ButtonBase>
            </ClickAwayListener>
            <Popper id={idtwo} open={opentwo} anchorEl={anchorElthree} className={classes.popper}>
                                            <List component="nav" aria-label="main mailbox folders">
                                                <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'english' ? '#1849AA' : '#fff', color: app.language === 'english' ? '#fff' : 'black'}} onClick={() => changelanguage('english')}>
                                                    <ListItemText primary="English"/>
                                                </ListItem>
                                                <Divider/>
                                                <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'kiswahili' ? '#1849AA' : '#fff', color: app.language === 'kiswahili' ? '#fff' : 'black'}} onClick={() => changelanguage('kiswahili')}>
                                                    <ListItemText primary="Kiswahili"/>
                                                </ListItem>
                                                {/* <Divider/>
                                                <ListItemLink href="/dashboard">
                                                    <ListItemText primary="Dashboard"/>
                                                </ListItemLink> */}
                                            </List>
                                        </Popper>
            </>
            {app?.userdata?.token && app.isAuthenticated ?  
            <>
            <ClickAwayListener onClickAway={handleClose}>
               <ButtonBase aria-describedby={id} onClick={handleClick} elevation={0} variant="contained" color="secondary" className={classes.buttonthreethree}>
             {app?.userdata?.user?.first_name || null} <Usericon/>
            </ButtonBase>
            </ClickAwayListener>
             
            <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
            <List component="nav" aria-label="main mailbox folders">
            <ListItemLink href="/profile">
          <ListItemText primary={t("Profile")} />
        </ListItemLink>
        <Divider />
        <ListItemLink href="/logout">
          <ListItemText primary={t("Signout")} />
        </ListItemLink>
        {/* <Divider />
        <ListItemLink href="/dashboard">
          <ListItemText primary="Dashboard" />
        </ListItemLink> */}
      </List>
           </Popper>
            </>
               :  <Link to='/auth' style={{textDecoration: 'none', color: '#fff'}}>
             <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             {t("Sign in")}/{t("Sign up")} <Person style={{marginLeft: 5}}/>
            </ButtonBase>
            </Link>     }
              
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Swiperble opensidebar={openside} closesidebar={closesidebar}/>
      </>
  );
}
