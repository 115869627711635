import '../App.css';
import {makeStyles, ButtonBase , Container} from '@material-ui/core';
import React, {useEffect} from 'react';
import Photocontainer from './regioncontainer';
import Words from './headerwords';
import {getNearbyPlaces} from '../store/actions/appactions';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 50,
        paddingBottom: 50,
width: '100%',
display: 'flex',
flexDirection: 'row',
alignItems: 'center',
justifyContent: 'space-evenly',
flexWrap: 'wrap',
backgroundColor: '#fff',
    },
   photo: {
       width: '100%',
       height: '100%',
       display: 'block',
       objectFit: 'cover',
       top: 0,
       left: 0,
       zIndex: 1,
       position: 'absolute'
   },
   photocontainer: {
       width: 200,
       height: 200,
       position: 'relative',
       overflow: 'hidden',
       borderRadius: 20,
   },
   wordscontainer: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    top: 0,
    left: 0,
    zIndex: 9,
    position: 'absolute'
   },
   button: {
    width: '230px',
    height: '56px',
    background: '#1849AA',
    borderRadius: '94px',
    fontSize: 15,
    color: '#fff'
   }
  }));




function Productcontainer({image}) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const history = useHistory();
    const {t} = useTranslation();

    useEffect(() => {
      dispatch(getNearbyPlaces());
  }, []);
  return (
      <>
      <div style={{width: '100%', backgroundColor: '#fff', paddingTop: 50}}>
           <Words header='Explore Places' headermini='Find what suits you from nearby places'/>
         <Container>
    <div className={classes.root}>
        {app.places ? app.places.slice(0, 10).map((place) => {
          return (
            <Photocontainer id={place.id} image={place?.cover_image || null} key={place.id} name={place.name}/>
          )
        }) : null}
     </div>
      </Container>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 50}}>
        <ButtonBase onClick={() => history.push('/places')} className={classes.button}>{t("Explore more places")}</ButtonBase>
    </div>
      </div>
     </>
  )
}

export default Productcontainer;
