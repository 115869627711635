import '../App.css';
import { makeStyles, Grid, Container, ButtonBase, CircularProgress} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Dashboard from './dashboard';
import PropTypes from 'prop-types';
import House from '@material-ui/icons/HomeWork';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Productcontainer from './singleproductuser';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { getUserProperties, propertyedit, clearSignup, clearProp, filterprop, getRegions} from '../store/actions/appactions';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  buttoncontainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FAFAFA',
       padding: theme.spacing(3),
       [theme.breakpoints.down('md')]: {
         paddingTop: 20,
        padding: 0,
        flexDirection: 'column-reverse',
        alignItems: 'flex-start'
      },
  },
  buttoncontainertwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
},
  buttondiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
  },
  buttontwo: {
    width: '130px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    fontSize: 15,
    color: '#fff',
    "&:hover": {
      backgroundColor: '#1849AA',
    },
  },
  buttontwotwo: {
    width: '130px',
    height: '46px',
    borderRadius: '54px',
    fontSize: 15,
    border: '1px solid #1849AA',
    color: '#1849AA',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  buttonfour: {
    width: '140px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#FF6746',
    color: '#fff',
    marginRight: 20,
    "&:hover": {
      backgroundColor: '#1849AA',
    },
  },
  buttonfive: {
    width: '150px',
    height: '46px',
    borderRadius: '54px',
    fontSize: 15,
    backgroundColor: '#369B3B',
    color: '#fff',
    "&:hover": {
      backgroundColor: '#1849AA',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 20
    }
  },
  buttonthree: {
    width: '130px',
    height: '46px',
    borderRadius: '54px',
    fontSize: 15,
    border: '1px solid #1849AA',
    color: '#1849AA',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: 20,
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  buttonthreethree: {
    width: '130px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    fontSize: 15,
    color: '#fff',
    marginLeft: 20,
    "&:hover": {
      backgroundColor: '#1849AA',
    },
  },
  buttonsix: {
      paddingLeft: 10,
      paddingRight: 10,
    height: '36px',
    borderRadius: '54px',
    border: '1px solid #1849AA',
    color: '#1849AA',
    fontWeight: '800',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: 20,
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  root: {
      marginTop: 0,
      backgroundColor: '#FAFAFA',
      paddingTop: 60
  }
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function Home({header, headermini}) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0);
    let history = useHistory();
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [typedata, setTypedata] = useState(1);
    const {t} = useTranslation();

    useEffect(() => {
       dispatch(getUserProperties(app.userdata.token));
       dispatch(clearProp());
       dispatch(getRegions());
    }, [])

    useEffect(() => {
      if(app.successeditproperty){
        setShow(true);
        setTimeout(() => {
          setShow(false);
          dispatch(clearSignup());
        }, 5000)
      }
  }, [app.successeditproperty])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onPress = () => {
    history.push('/dashboard/property/create');
  }

  useEffect(() => {
    if(app.successcreateproperty){
      setShow(true);
      setTimeout(() => {
        setShow(false);
        dispatch(clearSignup());
      }, 5000)
    }
}, [app.successcreateproperty])


const onSubmit = (data) => {
  var dat = {
    title: data.title,
    title_sw: data?.title_sw || data?.title,
    street_address: data.street_address,
    geo_location: data.geo_location,
    plot_number: data.plot_number,
    ward: data.ward_id,
    number_of_bedrooms: data.number_of_bedrooms,
    region: data.region_id,
    number_of_bathrooms: data.number_of_bathrooms,
    is_self_contained: data.is_self_contained,
    year_built: data.year_built,
    property_size: data.property_size,
    type: data.type_id,
    price_per_month: data?.price_per_month,
    sell_price: data.type_id !== 1 ? data?.sell_price : 0,
    overview: data.overview,
    overview_sw: data?.overview_sw || data?.overview,
    visible: data.is_visible,
    region: data.region_id,
    district: data.district_id,
    ward: data.ward_id,
    street: data.village_id,
    property_category: data?.property_category_id,
    tags: data.tags,
    thumbnails: data?.thumbnails
  }
  dispatch(clearSignup());
  dispatch(propertyedit(dat, data));
}

useEffect(() => {
   if(app.toedit){
    history.push('/dashboard/property/edit');
   }
}, [app.toedit])

const handleClose = () => {
  dispatch(clearSignup());
  setShow(false);
}

const filterproperties = (type) => {
  setTypedata(type);
  const filterdata = app.userpropertiesfilter.filter(property => property.type_id === type);
  dispatch(filterprop(filterdata))
}

  return (
      <Dashboard>
         <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={handleClose} severity="success">
        <span style={{color: '#fff', fontSize: 20}}>{app?.successcreateproperty ? `${t("property created successfully")}` : app?.successeditproperty ? `${t("property edited successfully")}` : null}</span>
        </Alert>
      </Snackbar>
        <div style={{width: '100%', backgroundColor: '#FAFAFA'}}>
       <Container>
       <div className={classes.buttoncontainer}>
        <div className={classes.buttondiv}>
        <ButtonBase onClick={() => {filterproperties(2)}} elevation={0} variant="contained" color="secondary" className={typedata === 2 ? classes.buttontwo : classes.buttontwotwo}>
             {t("For Sell")}
            </ButtonBase>
            <ButtonBase onClick={() => {filterproperties(1)}} elevation={0} variant="contained" color="secondary" className={typedata === 1 ? classes.buttonthreethree : classes.buttonthree}>
             {t("For Rent")}
            </ButtonBase>
        </div>
        <div className={classes.buttondiv}>
            <ButtonBase onClick={onPress} elevation={0} variant="contained" color="secondary" className={classes.buttonfive}>
             {t("New Property")}&nbsp;&nbsp;<House/>
            </ButtonBase>
        </div>
        </div>
        <div className={classes.root}>
      <AppBar position="static" elevation={0} style={{backgroundColor: '#FAFAFA'}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={t("Listings")} {...a11yProps(0)}  style={{color: '#1849AA'}}/>
          {/* <Tab label="Sold" {...a11yProps(1)} style={{color: '#757575'}}/> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
         {app?.loadinguserproperties ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color="#556cd6"/>
      </div> : app?.error?.type === 'getuserproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>{t("An error occured, please try again later")}</span>
      </div> : app.userproperties && !app?.error?.type !== 'getuserproperty' && !app?.loadinguserproperties ?
        <Grid container spacing={2} style={{marginTop: 40}}>
          {app.userproperties ? app.userproperties.map((property) => {
        return (
          <Grid item xs={12} md={6} lg={4}>
            <Productcontainer fromdashboard={true} is_verified={property.is_verified} image={property?.thumbnails[0] || null} address={property.street_address} name={app?.language === 'english' ? property.title : app?.language === 'kiswahili' ? property?.title_sw || property?.title : null} size={property.property_size} price={property?.price_per_month} bathrooms={property.number_of_bathrooms} bedrooms={property.number_of_bedrooms} id={property.id} type_id={property.type_id} onPress={() => {onSubmit(property)}}/>
            </Grid>
        )
    }) : null}
        </Grid> : null}
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </div>
       </Container>       
       </div>
      </Dashboard>
    
  )
}

export default Home;
