import '../App.css';
import { makeStyles, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Layout from '../layout';
import Homeslider from './searchbar';
import Productslider from './productslider';
import Plotslider from './plotslider';
import Listingslider from './listingslider';
import Regions from './regions';
import Instruction from './instructionscontainer';
import Footer from './Footer';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useDispatch, useSelector} from 'react-redux';
import {clearSignup} from '../store/actions/appactions';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%',
   },
  }));


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


function Home(props) {
    const classes = useStyles()
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);

    useEffect(() => {
        if(app.signupsuccess){
          setShow(true);
        }
    }, [app.signupsuccess])

    useEffect(() => {
      if(app.signinsuccess){
        setShow(true);
      }
  }, [app.signinsuccess])

    const handleClose = () => {
      setShow(false);
      dispatch(clearSignup());
    }
  return (
    <>
     <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert onClose={handleClose} severity="success">
          Welcome {app.signinsuccess ? 'back' : null}, you have successfully {app.signupsuccess ? 'joined Eneo' : app.signinsuccess ? 'logged in' : null}
        </Alert>
      </Snackbar>
      <Layout>
        <div className={classes.root}>
       <Homeslider/>
      <Container>
          <Productslider/></Container>
      <Regions/>
      {/* <Container><Plotslider/></Container> */}
      <Instruction/>

      <Container><Listingslider/></Container>
      <Footer/>
   </div>
      </Layout>
      </>


  )
}

export default Home;
