import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, ButtonBase} from '@material-ui/core';
import Shield from '@material-ui/icons/VerifiedUser';
import Person from '@material-ui/icons/ArrowForward';


const useStyles = makeStyles((theme) => ({
   container: {
       paddingTop: 50,
       marginBottom: 100,
       marginTop: 100,
       backgroundColor: '#fff',
       borderRadius: 30

   },
   griditem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemthree: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemtwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1849AA',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
},
headertwo: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: '700'
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
smallheadertwo: {
    fontSize: 12,
    color: '#fff',
},
   header: {
       color: '#222222',
       fontSize: '20px',
       fontWeight: '700'
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#AEB6BB',
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
    width: '136px',
    height: '46px',
    borderRadius: '54px',
    color: '#fff',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  }
}));


export default function CustomSeparator() {
  const classes = useStyles();


  return (
      <>
    <Container className={classes.container}>
      <Grid container>
      <Grid item xs={3} className={classes.griditemthree}>
      
      <span className={classes.header}><Shield style={{color: '#1849AA'}} fontSize='large'/><br/>30-day money-back<br/>
guarantee</span>
          </Grid>
          <Grid item xs={3} className={classes.griditem}>
            <span className={classes.header}>Single day</span>
            <span className={classes.bigheader}>500</span>
            <span className={classes.smallheader}>per day</span>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttontwo}>
             Choose plan
            </ButtonBase>
          </Grid>
          <Grid item xs={3} className={classes.griditemtwo}>
            <span className={classes.headertwo}>Month</span>
            <span className={classes.bigheadertwo}>10K</span>
            <span className={classes.smallheadertwo}>per month</span>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttontwo}>
             Choose plan
            </ButtonBase>
          </Grid>
          <Grid item xs={3} className={classes.griditem}>
            <span className={classes.header}>Life time</span>
            <span className={classes.bigheader}>20K</span>
            <span className={classes.smallheader}>life subscription</span>
            <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttontwo}>
             Choose plan
            </ButtonBase>
          </Grid>
      </Grid>
    </Container>
    <Container>
        <Grid container>
          <Grid item xs={12} className={classes.button}>
          <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             Continue <Person fontSize='small'/>
            </ButtonBase>
          </Grid>   
        </Grid>
    </Container>
    </>
  );
}
