import '../App.css';
import { makeStyles, Grid, Container, CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import Productcontainer from './singleproduct';
import { useSelector, useDispatch} from 'react-redux';
import { getRegionProperties, getPagination} from '../store/actions/appactions';
import { useLocation } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
   heading: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       flexDirection: 'column',
       height: 400,
       width: '100%',
       position: 'relative',
       backgroundColor: 'black',
   },
   photo: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    top: 0,
    left: 0,
    zIndex: 1,
    position: 'absolute'
},
photowords: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 9,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 67.39%, rgba(0, 0, 0, 0.81) 100%)',
},
  }));


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  

function Home(props) {
    const classes = useStyles()
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    let query = useQuery();
    const min_price = query.get("min_price");
    const keysw = query.get("keyword");
    const page = query.get("page");
    const max_price = query.get("max_price");
    const bathrooms = query.get("bathrooms");
    const bedrooms = query.get("bedrooms");
    const street = query.get("street");
    const property_category = query.get("property_category");
    const [newpage, setNewpage] = useState(1);


    useEffect(() => {
      if(keysw || page || min_price || max_price || bedrooms || bathrooms ||  property_category){
        const path = `?page=${page}${keysw ? `&keyword=${keysw}` : ''}${min_price ? `&min_price=${min_price}` : ''}${max_price ? `&max_price=${max_price}` : ''}${bedrooms ? `&bedrooms=${bedrooms}` : ''}${bathrooms ? `&bathrooms=${bathrooms}` : ''}${property_category ? `&property_category=${property_category}` : ''}`
        dispatch(getRegionProperties(path));
      }else if(street){
        const path = `?street=${street}`
        dispatch(getRegionProperties(path));
      }
      
   }, [keysw, page, max_price, min_price, bedrooms, bathrooms, street])

   const fetchData = () => {
    if(keysw || page || min_price || max_price || bedrooms || bathrooms ||  property_category){
      const path = `?page=${app?.pagination}${keysw ? `&keyword=${keysw}` : ''}${min_price ? `&min_price=${min_price}` : ''}${max_price ? `&max_price=${max_price}` : ''}${bedrooms ? `&bedrooms=${bedrooms}` : ''}${bathrooms ? `&bathrooms=${bathrooms}` : ''}${property_category ? `&property_category=${property_category}` : ''}`
      dispatch(getPagination(path));
    }else if(street || page){
      const path = `?page=${app?.pagination}&street=${street}`
      dispatch(getPagination(path));
    }
   }

  return (
    <InfiniteScroll
  dataLength={app?.regionproperties?.length ? app?.regionproperties?.length / 3 : 0} //This is important field to render the next data
  next={fetchData}
  height={500}
  hasMore={true}
  loader={app?.loadingpage ? <div style={{width: '100%', height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
  <CircularProgress color="#556cd6"/>
</div> : null}
  endMessage={
    <p style={{ textAlign: 'center' }}>
      <b>Yay! You have seen it all</b>
    </p>
  }
  // // below props only if you need pull down functionality
  // refreshFunction={this.refresh}
  // pullDownToRefresh
  // pullDownToRefreshThreshold={50}
  // pullDownToRefreshContent={
  //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
  // }
  // releaseToRefreshContent={
  //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
  // }
>
      <Container>
           {app?.loadingproperties ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color="#556cd6"/>
      </div> : app?.error?.type === 'getregionproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>An error occured, please try again later</span>
      </div> : Array.isArray(app.regionproperties) && app?.regionproperties?.length > 0 && !app?.error?.type !== 'getregionproperty' && !app?.loadingproperties ?
       <Grid container spacing={2} style={{paddingBottom: 50}}>
          {app.regionproperties ? app.regionproperties.map((property) => {
        return (
<Grid item xs={12} md={6} lg={4}>
<Productcontainer image={property?.thumbnails?.length > 0 ? property?.thumbnails[0] : null} address={property.street_address} name={app?.language === 'english' ? property.title : app?.language === 'kiswahili' ? property?.title_sw || property?.title : null} size={property.property_size} price={property.price_per_month} bathrooms={property.number_of_bathrooms} bedrooms={property.number_of_bedrooms} id={property.id} type_id={property.type_id}/>
</Grid>
        )}) : null} 
          
      </Grid> : Array.isArray(app.regionproperties) && app.regionproperties.length === 0 && app?.error?.type !== 'getregionproperty' && !app?.loadingproperties ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black', textAlign: 'center'}}>No properties found — Please contact Eneo at +255-758-988-500 to find a property for you!</span>
      </div> : null}
      </Container>
      </InfiniteScroll>
  )
}

export default Home;
