import '../App.css';
import {makeStyles, Grid, Container } from '@material-ui/core';
import React from 'react';
import Instruction from './instructions';
import House from './logos/house';
import Building from './logos/building';
import Location from './logos/location';
import Person from './logos/person';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 50,
        paddingBottom: 50,
        width: '100%',
        backgroundColor: '#FAFAFA'
    },
    words: {
        width: '100%',
        textAlign: 'center',
        color: 'black',
        fontSize: 20,
        fontWeight: '800',
        paddingBottom: 50
    }
  }));

  
  

function Productcontainer({image}) {
    const classes = useStyles()
    const {t} = useTranslation();

  return (
      <>
     <div className={classes.root} id='rentals'>
         <div className={classes.words}><span>{t("Never miss out the most important listings")}</span></div>
         <Container>
              <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <Instruction title='Discover Places' description="Browse over hundreds of places available to rent">
                <House style={{height: 20}}/>
                </Instruction>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <Instruction title='Affordable Prices' description="Find places within your budget">
                    <Building/>
                </Instruction>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <Instruction title='Built For Africa' description="Vibrant, secured & simple to use." >
                    <Location/>
                </Instruction>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <Instruction title='Reliable & Safe' description="Places are vetted and verified for your safety.">
                    <Person/>
                </Instruction>
            </Grid>
        </Grid>
         </Container>
       
     </div>
     </>
  )
}

export default Productcontainer;
