import '../../App.css';
import { makeStyles, Grid, Container, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import {Enteritem} from '../../store/actions/appactions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Steps from './steps';
import _, { isInteger } from 'lodash';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
 container: {
     paddingTop: 50,
 },
 input: {
     marginBottom: 50
 }
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  

  

function Home({header, headermini}) {
    const classes = useStyles()
    const app = useSelector(state => state.app);
    const [sellprice, setSellprice] = useState(app?.propertyitems?.sell_price || '');
    const [tags, setTags] = useState(app?.propertyitems?.tags || []);
    const [price, setPrice] = useState(app?.propertyitems?.price_per_month || '');
    const [overview, setOverview] = useState(app?.propertyitems?.overview || '');
    const [overviewsw, setOverviewsw] = useState(app?.propertyitems?.overview_sw || '');
    const [visible, setVisible] = useState(app?.propertyitems?.visible === 1 ? true : false);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const {t} = useTranslation();
    

    const handleNext = () => {
      setError(null);

      const priceint = parseInt(price)
      if(!isInteger(priceint)){
        setError({type: 'price', body: 'please choose a valid price'})
      } else if(!overview){
        setError({type: 'overview', body: 'please enter valid overview'})
      } else if(!overviewsw){
        setError({type: 'overviewsw', body: 'please enter valid overview'})
      } else if(!_.isBoolean(visible)){
        setError({type: 'visible', body: 'please choose a valid option'})
      }  else {
          const data = {
            tags: tags,
            price_per_month: parseInt(price),
            overview,
            overview_sw: overviewsw,
            visible
          }
          dispatch(Enteritem(app.propertyitems, data))
      }
    }


  return (
    <div style={{width: '100%'}}>
    <Container className={classes.container} >
        <form>
         <Grid container spacing={2}>
             
            {/* {[2, 3].includes(parseInt(app?.propertyitems?.type)) ? <Grid item xs={12} md={6} lg={4}>
              <TextField
              error={error?.type === 'sellprice'}
               helperText={error?.type === 'sellprice' ? error?.body : null}
               value={sellprice}
                onChange={(e) => setSellprice(e.target.value)}
                 id="sellprice"
                  label="Sell price"
                   variant="outlined"
                    fullWidth
                     className={classes.input}
                      /></Grid>
             : null} */}
             <Grid item xs={12} md={6} lg={4}>
             <Autocomplete
        multiple
        id="tags-outlined"
        options={app?.tags}
        value={tags}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => {setTags(value)}}
        defaultValue={[app?.tags[0]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t("Keywords")}
            placeholder={t("Keywords")}
            helperText={t('keywords or phrases are assigned to properties, so that they are easier to find within search function.Choose more than two keywords')}
          />
        )}
      />
             </Grid>
             
            {/* {[1].includes(parseInt(app?.propertyitems?.type)) ?   */}
            <Grid item xs={12} md={6} lg={4}>
            <CurrencyTextField
               helperText='Enter price as an integer eg 10000' error={error?.type === 'price'} helperText={error?.type === 'price' ? error?.body : null} value={price} onChange={(e, value) => setPrice(value)} id="price" label={t("Price per month")} variant="outlined" fullWidth className={classes.input}
		currencySymbol="Tsh"
		//minimumValue="0"
		outputFormat="string"
		decimalCharacter="."
		digitGroupSeparator=","
    />
              </Grid>
             {/* : null} */}
              <Grid item xs={12} md={6} lg={4}>
<TextField
 labelId="visible"
 id="visible"
 select
 fullWidth
 variant="outlined"
 value={visible}
 label={t("Display on eneo")}
 onChange={(e) => setVisible(e.target.value)}
 error={error?.type === 'visible'} helperText={error?.type === 'visible' ? error?.body : null}
>
 <MenuItem value={true}>{t("Yes")}</MenuItem>
 <MenuItem value={false}>{t("No")}</MenuItem>
</TextField>
             </Grid>  
             <Grid item xs={12} md={12} lg={6}>
             <TextField error={error?.type === 'overview'} helperText={error?.type === 'overview' ? error?.body : null} value={overview} onChange={(e) => setOverview(e.target.value)} id="overview" label={t("Property description")} variant="outlined" fullWidth className={classes.input} multiline
       rows={6} placeholder='example my apartment is comfortable and well located.....' />
             </Grid>
             <Grid item xs={12} md={12} lg={6}>
                <TextField placeholder='mfano nyumba yangu iko vizuri na iko vizuri...' error={error?.type === 'overviewsw'} helperText={error?.type === 'overviewsw' ? error?.body : null} value={overviewsw} onChange={(e) => setOverviewsw(e.target.value)} id="overview" label={t("Property description (Kiswahili)")} variant="outlined" fullWidth className={classes.input} multiline
          rows={6} />
                </Grid>
         </Grid>
        </form>
    </Container>   
    <Container>
      <Steps handleNext={handleNext}/>
   </Container>          
    </div>
    
  )
}

export default Home;
