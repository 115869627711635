import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, ButtonBase, CircularProgress} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {uploadphoto} from '../store/actions/appactions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Photodelete from './photodelete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
   container: {
       marginBottom: 100,
       backgroundColor: '#fff',
       borderRadius: 30,
       height: 400,
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       flexDirection: 'row',
       [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },

   },
   griditem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemthree: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemtwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1849AA',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
},
headertwo: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: '700'
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
smallheadertwo: {
    fontSize: 12,
    color: '#fff',
},
   header: {
       color: '#222222',
       fontSize: '20px',
       fontWeight: '700'
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#AEB6BB',
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
paddingLeft: 20,
paddingRight: 20,
    height: '36px',
    borderRadius: '54px',
    color: '#fff',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  buttonthreetwo: {
paddingLeft: 20,
paddingRight: 20,
    height: '36px',
    borderRadius: '54px',
    border: '1px solid #1849AA',
    backgroundColor: '#fff',
    color: '#1849AA',
    marginLeft: 20,
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  },
  illustrationcontainer: {
      width: 200,
      height: 200,
      borderRadius: 100,
      backgroundColor: '#eeeeee',
      position: 'relative'
  },
  detailscontainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 100,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        textAlign: 'center'
      },
      
  },
  buttoncont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 30,
},
edit: {
  position: 'absolute',
  top: 0,
  left: 150,
  width: 50,
  height: 50,
  borderRadius: 25,
  backgroundColor: '#1849AA',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '5px solid #fff'
},
input: {
  display: 'none',
},
}));


export default function CustomSeparator(props) {
  const classes = useStyles();
  const app = useSelector(state => state.app);
  const [photo, setPhoto] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

let history = useHistory();
  const uploadPhoto = (file) => {
    setPhoto(file);
    setOpen(true);
    
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    setOpen(false);
    dispatch(uploadphoto(photo, app.userdata.token));
  }

  
  return (
      <>
      <Photodelete open={open} handleClose={handleClose} handleSave={handleSave} photo={photo}/>
    <Container className={classes.container}>
        <div className={classes.illustrationcontainer}> 
        <div style={{width: 200, height: 200, borderRadius: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', overflow: 'hidden'}}>
            {app?.loadingupload ? <CircularProgress color="black"/> : app?.avatar ? <img style={{width: '100%', height: '100%', display: 'block', objectFit: 'cover'}} src={app?.avatar} alt="profile"/> : null}
            </div>
          <div className={classes.edit}>
           
          <input onChange={(e) => uploadPhoto(e.target.files[0])} accept="image/*" className={classes.input} id="icon-button-file" type="file" />
      <label htmlFor="icon-button-file">
        <IconButton style={{color: '#fff'}} aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
          </div>
            {/* <img src={} alt='illustration'/> */}
        </div>
         <div className={classes.detailscontainer}>
             <span className={classes.header}>{app?.profile?.first_name || null} {app?.profile?.last_name || null}</span>
             <span className={classes.smallheader}>{app?.profile?.email || null}</span>
         <div className={classes.buttoncont}>
         <ButtonBase onClick={() => history.push('/profile/edit')} elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             {t("Edit profile")}
            </ButtonBase>
            {/* <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonthreetwo}>
             Change email
            </ButtonBase> */}
         </div>
         </div>
    </Container>
    </>
  );
}
