import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import {useDispatch, useSelector} from 'react-redux';
import {Signup, Signinsocial} from '../../store/actions/appactions';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Phone from 'phone';
import validator from 'validator';
import Googlelogin from './google';
import Facebook from './facebook';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Alerts(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  buttonfour: {
    width: '120px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#2965DB',
    }
  },
  link: {
    color: '#1849AA',
    fontWeight: '800'
  },
  griditem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  divider: {
    backgroundColor: 'grey',
    width: '45%',
    marginTop: 50
  },
  or: {
    color: 'grey',
    fontWeight: '800',
    marginTop: 50
  }
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showerror, setShowerror] = useState(false);
  const [show, setShow] = useState(false);
  const [renter, setRenter] = useState(false);
  const [role, setRole] = useState('');
  const {t} = useTranslation();
  let history = useHistory();


  function validation(value){
    const validate =  validator.isStrongPassword(value, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1
      })
  
      return validate;
    }

  const onSubmit = (e) => {
    e.preventDefault();
    setError(null);
    const phonenumber = Phone(phone, {country: 'TZ'});
    const trimmedstring = phonenumber?.phoneNumber?.substring(1) || null;
      const completephone = trimmedstring;
    const data = email ? {
      first_name: firstname,
      last_name: lastname,
      password,
      phone: completephone,
      email,
      registration_type: renter && role ? role : null
    } : {
      first_name: firstname,
      last_name: lastname,
      password,
      phone: completephone,
      registration_type: renter && role ? role : null
    }
    if ((!email || validator.isEmail(email)) && password && phonenumber?.phoneNumber && validator.matches(firstname, "^(?=.{3,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$") && validator.matches(lastname, "^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$") && ((renter && role) || (!renter && !role))) {
     dispatch(Signup(data)); 
    } else if(!validator.matches(firstname, "^(?=.{3,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$")){
      setError({
        type: 'firstname',
        data: 'Enter valid data'
      })
    }else if(!validator.matches(lastname, "^(?=.{3,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$")){
      setError({
        type: 'lastname',
        data: 'Enter valid data'
      })
    }else if(!phonenumber?.phoneNumber){
      setError({
        type: 'phone',
        data: 'Enter valid phone number'
      })
    }else if(email && !validator.isEmail(email)){
      setError({
        type: 'email',
        data: 'Enter valid email address'
      })
    }else if(!password){
     setError({
       type: 'password',
       data: 'enter a valid password'
     })
    }else if(renter && !role){
      setError({
        type: 'role',
        data: 'choose a valid option'
      })
    }
  }

  useEffect(() => {
    if(app.signupsuccess){
      history.push("/");
    }
}, [app.signupsuccess])

useEffect(() => {
  if(app.signinsuccess){
    history.push("/");
  }
}, [app.signinsuccess])

useEffect(() => {
if(app.loadingsocial){
  setShow(true);
}else{
  setShow(false)
}
}, [app.loadingsocial])

useEffect(() => {
if(app?.error?.type === 'signinsocial'){
  setShowerror(true);
}else{
  setShowerror(false)
}
}, [app.error])

const handleSocialLogin = (user) => {
  const googledata = {
    first_name: user?._profile?.firstName,
    last_name: user?._profile?.lastName,
    email: user?._profile?.email,
    avatar: user?._profile?.profilePicURL,
    provider: 1,
    token: user?._token?.accessToken,
  }
  dispatch(Signinsocial(googledata))
};

const handleSocialLoginFailure = (err) => {
  console.error(err);
};

const handleClose = () => {
  setShow(false);
}

const handleCloseerror = () => {
  setShowerror(false);
}

  return (
    <>
     <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alerts onClose={handleClose} severity="success">
          {t("logging you in")} <CircularProgress color="white" size={10}/>
        </Alerts>
      </Snackbar>

      <Snackbar open={showerror} autoHideDuration={6000} onClose={handleCloseerror} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alerts onClose={handleCloseerror} severity="error">
          {t("Error occured, please try again")}
        </Alerts>
      </Snackbar>
      <form noValidate autoComplete="off" onSubmit={(e) => onSubmit(e)}>
      <Grid container spacing={2}>
     <Grid item xs={12}>
             <span style={{color: '#1849AA', fontWeight: '700', fontSize: 20}}>{t("Please sign up to start using Eneo")}</span>
         </Grid>
         <Grid item xs={12} md={6} lg={6}>
         <TextField error={(app?.error?.type === 'signup' && app?.error?.data?.first_name) || error?.type === 'firstname'} helperText={app?.error?.type === 'signup' && app?.error?.data?.first_name ? app?.error?.data?.first_name[0] : error?.type === 'firstname' ? error?.data : null} fullWidth value={firstname} onChange={(e) => setFirstname(e.target.value)} id="firstname" label={t("First name")} variant="outlined" />
         </Grid>
         <Grid item xs={12} md={6} lg={6}>
         <TextField  error={(app?.error?.type === 'signup' && app?.error?.data?.last_name) || error?.type === 'lastname'} helperText={app?.error?.type === 'signup' && app?.error?.data?.last_name ? app?.error?.data?.last_name[0] : error?.type === 'lastname' ? error?.data : null} fullWidth value={lastname} onChange={(e) => setLastname(e.target.value)} id="lastname" label={t("Last name")} variant="outlined" />
         </Grid>
         <Grid item xs={12} md={6} lg={6}>
         <TextField error={(app?.error?.type === 'signup' && app?.error?.data?.phone) || error?.type === 'phone'} helperText={app?.error?.type === 'signup' && app?.error?.data?.phone ? app?.error?.data?.phone[0] : error?.type === 'phone' ? error?.data : null} fullWidth value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" label={t("Phone number")} variant="outlined" />
         </Grid>
         <Grid item xs={12} md={6} lg={6}>
         <TextField error={(app?.error?.type === 'signup' && app?.error?.data?.email) || error?.type === 'email'} helperText={app?.error?.type === 'signup' && app?.error?.data?.email ? app?.error?.data?.email[0] : error?.type === 'email' ? error?.data : null} fullWidth type='email' value={email} onChange={(e) => setEmail(e.target.value)} id="email" label={t("Email (optional)")} variant="outlined" />
         </Grid>
         <Grid item xs={12}>
         <TextField fullWidth error={(app?.error?.type === 'signup' && app?.error?.data?.password) || error?.type === 'password'} helperText={app?.error?.type === 'signup' && app?.error?.data?.password ? app?.error?.data?.password[0] : error?.type === 'password' ? error?.data : null} type='password' id="password" value={password}  onChange={(e) => setPassword(e.target.value)} label={t("Password")} variant="outlined" />
         </Grid>
         <Grid item xs={12} md={8}>
         <FormControlLabel control={<Checkbox color='blue' checked={renter} onChange={() => {setRenter(!renter); setRole('')}} />} label={t("I'm registering as a property owner/ agent")} />
         </Grid>

         {renter ? <Grid item xs={12} md={4}>
         <TextField error={error?.type === 'role'} helperText={error?.type === 'role' ? error?.body : null} value={role} onChange={(e) => setRole(e.target.value)} id="role" label={t("Type")} variant="outlined" fullWidth className={classes.input} select>
                <MenuItem value='1'>{t("I have my own properties")}</MenuItem>
                <MenuItem value='2'>{t("I am an agent")}</MenuItem>
                </TextField>
         </Grid> : null}
         <Grid item xs={12} className={classes.griditem}>
         <ButtonBase type='submit' elevation={0} variant="contained" color="secondary" className={classes.buttonfour}>
             {app.loading ? <CircularProgress color="#fff" size={25}/> : `${t('Sign up')}`}
            </ButtonBase>
            {/* <span className={classes.link}>{t("Forgot password?")}</span> */}
         </Grid>
         <Grid item xs={12} className={classes.griditem}>
           <Divider className={classes.divider}/><span className={classes.or}>{t("OR")}</span><Divider className={classes.divider}/>
         </Grid>
         <Grid item xs={12} className={classes.griditem}>
         <Grid container>
           <Grid item xs={12} md={6} lg={6}>
              <Googlelogin 
       provider="google"
       appId="212938495154-7n2fgu3gb2q6eilpmt06s7ge88ti9n44.apps.googleusercontent.com"
       onLoginSuccess={handleSocialLogin}
       onLoginFailure={handleSocialLoginFailure}
       />
           </Grid>
           <Grid item xs={12} md={6} lg={6}>
             <Facebook 
       provider="facebook"
       appId="212938495154-7n2fgu3gb2q6eilpmt06s7ge88ti9n44.apps.googleusercontent.com"
       onLoginSuccess={handleSocialLogin}
       onLoginFailure={handleSocialLoginFailure}
       /> 
           </Grid>
         </Grid>
         </Grid>
     </Grid>
    </form>
    </>
  );
}
