import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./common/constants/PrivateRoute";
import PublicRoute from "./common/constants/PublicRoute";
import Auth from './components/Auth';
import Dashboard from './components/dashboardcontent';
import Home from './components/Home';
import Profile from './components/profile';
import Profileedit from './components/profileedit';
import Logout from './components/logout';
import Forgot from './components/Forgotpassword';
import Verify from './components/Verifycontainer';
import Productdetails from './components/productdetails';
import Productsview from './components/productsview';
import Subscriptionview from './components/subscription';
import Property from './components/property';
import Propertycreate from './components/stepper';
import Propertyedit from './components/editproperty/stepperedit';
import Terms from './components/terms';
import About from './components/about';
import How from './components/works';
import Faq from './components/faq';
import Privacy from './components/privacy';
import Places from './components/places';
import Agent from './components/agent';
import Why from './components/whyeneo';
// import { animateScroll } from 'react-scroll';
// import {useLocation} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { useTranslation } from 'react-i18next';
import {useSelector} from 'react-redux';

const AppRouter = () => {
//   const { pathname } = useLocation();
const {t, i18n} = useTranslation();
const app = useSelector(state => state.app);

useEffect(() => {
  i18n.changeLanguage(app.language === 'kiswahili' ? 'sh' : 'en');
}, [app.language])

//   useEffect(() => {
//     animateScroll.scrollToTop({
//       duration: 0
//     });
// }, [pathname]);

  return (
    <Router>
      <ScrollToTop/>
        <Switch>
        <PrivateRoute exact path="/dashboard">
          <Dashboard/>
        </PrivateRoute>
        <PrivateRoute exact path="/profile">
          <Profile/>
        </PrivateRoute>
        <PrivateRoute exact path="/profile/edit">
          <Profileedit/>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard/property">
          <Property/>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard/property/edit">
          <Propertyedit/>
        </PrivateRoute>
        <PrivateRoute exact path="/dashboard/property/create">
          <Propertycreate/>
        </PrivateRoute>
        <PrivateRoute exact path="/logout" >
          <Logout />
        </PrivateRoute>
        <PublicRoute exact path="/">
          <Home />
        </PublicRoute>
        <PublicRoute exact path="/terms-and-conditions">
          <Terms />
        </PublicRoute>
        <PublicRoute exact path="/privacy-and-policy">
          <Privacy />
        </PublicRoute>
        <PublicRoute exact path="/aboutus">
          <About />
        </PublicRoute>
        <PublicRoute exact path="/places">
          <Places />
        </PublicRoute>
        <PublicRoute exact path="/faq">
          <Faq />
        </PublicRoute>
        <PublicRoute exact path="/how-it-works">
          <How />
        </PublicRoute>
        <PublicRoute exact path="/why-eneo">
          <Why />
        </PublicRoute>
        <PublicRoute exact path="/how-to-be-an-agent">
          <Agent />
        </PublicRoute>
        <PublicRoute exact path="/auth" >
          <Auth />
        </PublicRoute>
        <PublicRoute exact path="/forgot-password" >
          <Forgot />
        </PublicRoute>
        <PublicRoute exact path="/propertydetails/:propertyid?" >
          <Productdetails />
        </PublicRoute>
        <PublicRoute exact path="/subscription" >
          <Subscriptionview />
        </PublicRoute>
        <PublicRoute exact path="/properties" >
          <Productsview />
        </PublicRoute>
        <PublicRoute exact path="/auth/reset-password/:token">
        <Verify />
        </PublicRoute>
      </Switch>
    </Router>
  );
};

export default  AppRouter;
