import '../App.css';
import { makeStyles, Grid, Container, CircularProgress, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import Dropzone from 'react-dropzone-uploader'
import {Createproperty, clearSignup} from '../store/actions/appactions';
import Steps from './steps';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
 container: {
     paddingTop: 50,
     marginBottom: 50
 },
 input: {
     marginBottom: 50
 }
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function Home() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [filesupload, setFilesupload] = useState([]);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const getUploadParams = ({file, meta}) => {
      return { url: 'https://httpbin.org/post' }
    }
  
    const handleChangeStatus = (data) => {
      const filename = data.meta
      if(filename.status === 'headers_received'){
        const currentfiles = filesupload.map(file => {return (file)});
      setFilesupload([...currentfiles, data])
      }else if(filename.status === 'removed'){
        const currentfiles = filesupload.filter(file => file.meta.name !== filename.name);
      setFilesupload(currentfiles)
      }
    }

    const handleSubmit = ()  => {
      setError(false);
      setLoading(true)
        setTimeout(() => {
          if(_.size(filesupload) > 10){
            setLoading(false)
            setError({data: "Please upload a maximum of 10 photos"});
            setShow(true);
          }else if(_.size(filesupload) < 5){
            setLoading(false)
            setError({data: "Please upload atleast 5 photos"});
            setShow(true)
          } else if(_.size(filesupload) >= 5 && _.size(filesupload) <= 10){
            const media = filesupload.map(f => f.file);
            let propitems = app.propertyitems;
            const tages = app?.propertyitems?.tags?.map(tg => {
             return (
               tg.id
             )
           });
           propitems.tags = tages;
           propitems.ward = app?.propertyitems?.ward?.id;
           propitems.street = app?.propertyitems?.street?.id;
           propitems.region = app?.propertyitems?.region?.id;
           propitems.district = app?.propertyitems?.district?.id;
           dispatch(Createproperty(propitems, media,  app.userdata.token))
           setLoading(false);
          }
        }, 3000)
    }

    const handleClose = () => {
      setShow(false);
    }

    useEffect(() => {
      if(app?.error?.type === 'createproperty'){
        setShow(true);
      }
  }, [app?.error])

  useEffect(() => {
    setShow(false);
    setError(null);
  }, [])


  return (
        <div style={{width: '100%'}}>
            <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert onClose={handleClose} severity="error">
          {error?.data ? error?.data : app?.error?.type === 'createproperty' ? app?.error?.data : null }
        </Alert>
      </Snackbar>
       <Container className={classes.container} >
           <form onSubmit={(e, files) => {e.preventDefault(); handleSubmit(files)}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                <Dropzone
                inputContent={t('Drag Photos or Click to Browse (Upload atleast 5 photos)')}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      SubmitButtonComponent={null}
      styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
      maxFiles={10}
    />
                </Grid>
            </Grid>
           </form>
       </Container>   
       <Container>
       <Steps disabled={app.loading} loading={loading || app.loading} activeStep={app.steps} handleSubmit={handleSubmit}/>
      </Container>        
       </div>
    
  )
}

export default Home;
