import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Dashboard from './dashboard';
import Propertyone from './propertycreate';
import Propertytwo from './propertytwo';
import Propertythree from './propertthree';
import Propertyfour from './propertyfour';
import {getTags, getStatus, getTypes, getListtypes, getRegions, getCategories, getDistricts, getWards, clearsteps} from '../store/actions/appactions';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#fff'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select campaign settings', 'Create an ad group', 'Create an ad', "upload photos"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Propertyone/>;
    case 1:
      return <Propertytwo/>;
    case 2:
      return <Propertythree/>;
    case 3:
        return <Propertyfour/>;
    default:
      return 'Unknown step';
  }
}


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function HorizontalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  let history = useHistory();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getDistricts());
    dispatch(getWards());
     dispatch(getTags());
     dispatch(getStatus());
     dispatch(getListtypes());
     dispatch(getTypes());
     dispatch(getCategories());
     dispatch(clearsteps())
  }, []);

  useEffect(() => {
    if(app.successcreateproperty){
        history.push('/dashboard/property');
    }
}, [app.successcreateproperty])
  
  return (
    <Dashboard>
    <div className={classes.root}>
      <Stepper activeStep={app.steps}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label}>
              <StepLabel {...labelProps}></StepLabel>
            </Step>
          );
        })}
      </Stepper>  
          <div>
 {getStepContent(app.steps)}
          </div>
          </div>  
    </Dashboard>
  );
}
