import '../App.css';
import { makeStyles } from '@material-ui/core';
import Layout from '../layout';
import Breadcrumbs from './Breadcrumbs';
import Footer from './Footer';
import Subheader from './subscriptionheader';
import Subcontainer from './Subscriptioncontainer';
import Subpayment from './Paymentcontainer';
import Congratulations from './Congratulations';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%'
   },
  }));

  
  

function Home(props) {
    const classes = useStyles()
  return (
      <Layout>
        <div className={classes.root}>
        {/* <Breadcrumbs/>
        <Subheader/> */}
        {/* <Subcontainer/> */}
        {/* <Subpayment/> */}
        <Congratulations/>
        <Footer/>
        </div>  
   
      </Layout>
   
   
  )
}

export default Home;
