import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
    root: {
width: '100%',
display: 'flex',
flexDirection: 'row',
alignItems: 'center',
height: 200,
backgroundColor: '#fff',
border: '1px solid rgba(9, 26, 57, 0.1)',
borderRadius: 10,
    },
    photocontainer: {
        height: 200,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wordscontainer: {
        height: 200,
        flex: 2,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 20
    },
    header: {
        fontWeight: '800',
        fontSize: 16,
        marginBottom: 5,
    },
    miniheader: {
        fontSize: 16,
        fontWeight: '400',
        textAlign: 'left',
        marginTop: 5,
        lineClamp: '3',
        wordBreak: 'break-all'
    }
  }));

  
  

function Productcontainer(props) {
    const classes = useStyles()
    const {t} = useTranslation();

  return (
         <div className={classes.root}>
            <div className={classes.photocontainer}>
              {props.children}
            </div>
            <div className={classes.wordscontainer}>
               <span className={classes.header}>{t(`${props.title}`)}</span>
               <span className={classes.miniheader} number>{t(`${props.description}`)}</span>
            </div>
         </div>
  )
}

export default Productcontainer;
