import '../../App.css';
import { makeStyles, Grid, Container, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Enteritem} from '../../store/actions/appactions';
import Steps from './steps';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
 container: {
     paddingTop: 50,
 },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  

function Home({header, headermini}) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0); 
    const app = useSelector(state => state.app);
    const [bathrooms, setBathrooms] = useState(app?.propertyitems?.number_of_bathrooms);
    const [selfcontained, setSelfcontained] = useState(app?.propertyitems?.is_self_contained === 1 ? true : false);
    const [size, setSize] = useState(app?.propertyitems?.property_size);
    // const [type, setType] = useState(app?.propertyitems?.type);
    const [bedrooms, setBedrooms] = useState(app?.propertyitems?.number_of_bedrooms || '');
    const [category, setCategory] = useState(app?.propertyitems?.property_category || '');
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const {t} = useTranslation();
   

    const handleNext = () => {
      setError(null);
      if(!bathrooms){
        setError({type: 'bathrooms', body: 'please enter valid number of bathrooms'})
      } else if(!bedrooms){
        setError({type: 'bedrooms', body: 'please enter valid number of bedrooms'})
      } else if(!_.isBoolean(selfcontained)){
        setError({type: 'selfcontained', body: 'please choose a valid option'})
      } else if(!size){
        setError({type: 'size', body: 'please enter valid size'})
      }
      //  else if(![1,2,3].includes(parseInt(type))){
      //   setError({type: 'type', body: 'please enter valid type'})
      // }
       else if(!category){
        setError({type: 'category', body: 'please enter valid category'})
      } else {
        const data = {
          number_of_bathrooms: bathrooms,
          number_of_bedrooms: bedrooms,
          is_self_contained: selfcontained,
          property_category:  category,
          property_size: size,
      }

      dispatch(Enteritem(app.propertyitems, data))
      }
    }
    
  const statusarray = app.status || {};


  return (
    <div style={{width: '100%'}}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container className={classes.container} >
    <form>
     <Grid container spacing={2}>
         <Grid item xs={12} md={6} lg={4}>
         <TextField error={error?.type === 'bathrooms'} helperText={error?.type === 'bathrooms' ? error?.body : null} value={bathrooms} onChange={(e) => setBathrooms(e.target.value)} id="bathrooms" label={t("Number of bathrooms")} variant="outlined" fullWidth className={classes.input} />
         </Grid>
         <Grid item xs={12} md={6} lg={4}>
             <TextField error={error?.type === 'bedrooms'} helperText={error?.type === 'bedrooms' ? error?.body : null} value={bedrooms} onChange={(e) => setBedrooms(e.target.value)} id="bedrooms" label={t("Number of bedrooms")} variant="outlined" fullWidth className={classes.input} />
             </Grid>
         <Grid item xs={12} md={6} lg={4}>
         <FormControl variant='outlined' fullWidth>
<InputLabel id="selfcontained">{t("Self contained")}</InputLabel>
<Select
labelId="selfcontained"
id="selfcontained"
value={selfcontained}
label={t("Self contained")}
onChange={(e) => setSelfcontained(e.target.value)}
error={error?.type === 'selfcontained'} helperText={error?.type === 'selfcontained' ? error?.body : null}
>
<MenuItem value={true}>{t("Yes")}</MenuItem>
<MenuItem value={false}>{t("No")}</MenuItem>
</Select>
</FormControl>
         </Grid>
         <Grid item xs={12} md={6} lg={4}>
         <TextField error={error?.type === 'size'} helperText={error?.type === 'size' ? error?.body : null} value={size} onChange={(e) => setSize(e.target.value)} id="year" label={`${t("Property size")} (square meter (optional))`} variant="outlined" fullWidth className={classes.input} />
         </Grid>
         {/* <Grid item xs={12} md={6} lg={4}>
         <FormControl variant='outlined' fullWidth>
<InputLabel id="selfcontained">Property Type</InputLabel>
<Select
labelId="type"
id="type"
value={type}
label="Type"
onChange={(e) => setType(e.target.value)}
error={error?.type === 'type'} helperText={error?.type === 'type' ? error?.body : null}
>
{app.propertytypes ? Object.values(app.propertytypes).map((value, index) => {
return (
 <MenuItem value={index + 1}>{value}</MenuItem>
)
}) : null}
</Select>
</FormControl>
         </Grid>     */}
         <Grid item xs={12} md={6} lg={4}>
                <FormControl variant='outlined' fullWidth>
                <InputLabel id="categories">{t("Property Category")}</InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  value={category}
                  label={t("Category")}
                  onChange={(e) => setCategory(e.target.value)}
                  error={error?.type === 'category'} helperText={error?.type === 'category' ? error?.body : null}
                >
                    {app.categories ? app.categories.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>{t(`${category.name}`)}</MenuItem>
                    )
                  }) : null}
                </Select>
              </FormControl>
                </Grid>              
     </Grid>
    </form>
</Container>    
<Container style={{marginTop: 20}}>
  <Steps handleNext={handleNext}/>
</Container>       
    </MuiPickersUtilsProvider>
</div>
    
  )
}

export default Home;
