import '../App.css';
import {makeStyles, Grid, Container, Divider, ButtonBase } from '@material-ui/core';
import React from 'react';
import Logo from './logos/logo';
import Facebook from './logos/facebook';
import Instagram from './logos/instagram';
import Twitter from '../assets/twitter.png';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menuicon from '@material-ui/icons/Menu';
import {useTranslation} from 'react-i18next';
import {language} from '../store/actions/appactions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 50,
        paddingBottom: 50,
        width: '100%',
        backgroundColor: '#0E3279'
    },
    footeritem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            marginTop: 20
          },
    },
    footeritemtwo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start',
            marginTop: 20
          },
    },
    follow: {
        color: 'rgba(255, 255, 255, 0.5)',
        [theme.breakpoints.down('md')]: {
            fontSize: 12
          },
    },
    footeritemthree: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    footeritemfour: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    heading: {
        fontWeight: '800',
        color: '#fff'
    },
    subheading: {
        fontWeight: '400',
        color: '#fff',
        paddingTop: 20,
        TextDecoration: 'none'
    },
    divider: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        marginTop: 20,
        marginBottom: 20
    },
    buttonfour: {
        width: '100px',
        height: '46px',
        borderRadius: '54px',
        backgroundColor: '#2965DB',
        fontSize: 15,
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": {
          backgroundColor: '#2965DB',
        }
      }
  }));

  
  

function Footer() {
    const classes = useStyles()
    const {t, i18n} = useTranslation();
    const app = useSelector(state => state.app);
    const [anchorEltwo, setAnchorEltwo] = React.useState(null);
    const dispatch = useDispatch();
    const handleClicktwo = (event) => {
        setAnchorEltwo(anchorEltwo ? null : event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEltwo(false)
    };

    const opentwo = Boolean(anchorEltwo);
    const idtwo = opentwo ? 'simple-popper' : undefined;

    const changelanguage = async (lang) => {
       await i18n.changeLanguage(lang === 'kiswahili' ? 'sh' : 'en');
        dispatch(language(lang))
    }

  return (
      <>
     <div className={classes.root}>
         <Container>
              <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={2} className={classes.footeritem}>
            <span className={classes.heading}>{t("ABOUT US")}</span>
           <Link to='/how-it-works' className={classes.subheading} style={{textDecoration: 'none'}}><span >{t("How it works")}</span></Link> 
           <Link to='/how-to-be-an-agent' className={classes.subheading} style={{textDecoration: 'none'}}><span>{t("How to be an agent")}</span></Link>  
           <Link to='/aboutus' className={classes.subheading} style={{textDecoration: 'none'}}><span>{t("About eneo")}</span></Link> 
           {/* <Link to='/how-it-works' className={classes.subheading} style={{textDecoration: 'none'}}><span>Founders</span></Link>  */}
           {/* <Link to='/ourstory' className={classes.subheading} style={{textDecoration: 'none'}}><span>{t("Our story")}</span></Link> */}
            </Grid>
            <Grid item xs={12} md={6} lg={2} className={classes.footeritem}>
            <span className={classes.heading}>{t("SUPPORT")}</span>
            <Link to='/faq' className={classes.subheading} style={{textDecoration: 'none'}}><span>{t("FAQ")}</span></Link> 
            </Grid>
            {/* <Grid item xs={12} md={6} lg={2} className={classes.footeritem}>
            <span className={classes.heading}>APPS</span>
            <span className={classes.subheading}>Eneo Android App</span>
            <span className={classes.subheading}>Eneo Ios App</span>
            </Grid> */}
            <Grid item xs={12} md={6} lg={2} className={classes.footeritem}>
            {/* <span className={classes.heading}>Company</span>
            <span className={classes.subheading}>Plan and Pricing</span>
            <span className={classes.subheading}>Contact Us</span> */}
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.footeritemtwo}>
            <Logo/>
            <span className={classes.subheading}>info@eneo.co.tz</span>
            {/* <span className={classes.subheading}>+255 000 000 000</span> */}
            </Grid>
            <Grid item xs={12}>
            <Divider className={classes.divider}/>
            </Grid>
            <Grid item xs={12} md={6} className={classes.footeritemthree}>
                <span style={{color: '#fff'}}>© 2021 Eneo   • <Link to='privacy-and-policy' style={{textDecoration: 'none', color: '#fff'}}>{t("Privacy")}</Link> • <Link to='terms-and-conditions' style={{textDecoration: 'none', color: '#fff'}}>{t("Terms")}</Link></span>
            </Grid>
            <ClickAwayListener onClickAway={handleClose}>
            <Grid item xs={12} md={6} className={classes.footeritemfour}>
                <span className={classes.follow}>{t("FOLLOW US")}</span><div style={{paddingLeft: 20, paddingRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><a href='https://www.facebook.com/eneoapp' target="_blank"><Facebook/></a></div> <div style={{paddingLeft: 20, paddingRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><a href='https://www.instagram.com/eneoapp/' target="_blank"><Instagram /></a></div><a href='https://mobile.twitter.com/Eneoapp' target="_blank"><img style={{paddingLeft: 20, paddingRight: 20}} src={Twitter} alt="icon"/></a>
                
                <>
                <Popper id={idtwo} open={opentwo} anchorEl={anchorEltwo} className={classes.popper}>
                                            <List component="nav" aria-label="main mailbox folders">
                                                <ListItem button style={{ cursor: 'pointer', backgroundColor: app.language === 'english' ? '#1849AA' : '#fff', color: app.language === 'english' ? '#fff' : 'black'}} onClick={() => {
                 
                                                    changelanguage('english')}}>
                                                        <ButtonBase onClick={() => {
                 
                                                    changelanguage('english')}} style={{width: '100%', height: '100%', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
 <ListItemText primary="English"/>
                                                        </ButtonBase>
                                                   
                                                </ListItem>
                                                <Divider/>
                                                <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'kiswahili' ? '#1849AA' : '#fff', color: app.language === 'kiswahili' ? '#fff' : 'black'}} onClick={() => changelanguage('kiswahili')}>
                                                    <ListItemText primary="Kiswahili"/>
                                                </ListItem>
                                                {/* <Divider/>
                                                <ListItemLink href="/dashboard">
                                                    <ListItemText primary="Dashboard"/>
                                                </ListItemLink> */}
                                            </List>
                                        </Popper>
                                        
                                            <ButtonBase aria-describedby={idtwo} onClick={handleClicktwo} elevation={0} variant="contained" color="secondary" className={classes.buttonfour}>
             {app.language === 'english' ? 'English' : 'Kiswahili'}
            </ButtonBase> 
                                        
               </>
            </Grid></ClickAwayListener>
        </Grid>
         </Container>
       
     </div>
     </>
  )
}

export default Footer;
