import React from 'react';




const House = () => {
    return (
        <svg width="93" height="121" viewBox="0 0 93 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.4285 15.2835V11.338C22.4333 7.65469 24.836 4.40469 28.3549 3.32008C27.5585 3.0751 26.7295 2.95117 25.8956 2.95117C21.266 2.95694 17.5146 6.70841 17.5098 11.338V15.2835C17.5098 17.9436 18.7711 20.4453 20.9106 22.0265C23.05 23.6078 25.812 24.0805 28.3549 23.3004C24.836 22.2158 22.4333 18.9658 22.4285 15.2835Z" fill="#D4E1F4"/>
        <path d="M20.5632 116.659L19.104 67.2874L19.0713 65.1643C19.0655 64.3487 18.3998 63.6935 17.5851 63.6993C16.7705 63.706 16.1143 64.3717 16.1201 65.1864C16.1249 66.5996 15.412 67.9195 14.2266 68.69L15.6445 116.659C15.7416 117.453 16.4179 118.05 17.2181 118.048H22.1368C21.3356 118.051 20.6603 117.453 20.5632 116.659Z" fill="#D4E1F4"/>
        <path d="M8.19129 65.3376C8.19129 65.0888 8.13941 40.4549 8.13941 40.1158C8.14037 35.271 12.0667 31.3447 16.9114 31.3447H11.9927C7.14798 31.3447 3.22166 35.271 3.2207 40.1158C3.2207 40.4549 3.27258 65.0888 3.27258 65.3376C3.2745 67.527 5.04793 69.3024 7.23829 69.3053H7.29977C8.16823 69.2908 9.00595 68.9815 9.67458 68.4272C8.73696 67.6759 8.19129 66.5394 8.19129 65.3376Z" fill="#D4E1F4"/>
        <path d="M37.2317 15.2835V11.338C37.2317 5.07626 32.1554 0 25.8937 0C19.6319 0 14.5566 5.07626 14.5566 11.338V15.2835C14.5566 21.5443 19.6319 26.6206 25.8937 26.6206C32.1554 26.6206 37.2317 21.5443 37.2317 15.2835ZM17.5079 15.2835V11.338C17.5127 6.70847 21.2641 2.95699 25.8937 2.95123C30.5232 2.95699 34.2747 6.70847 34.2805 11.338V15.2835C34.2805 19.915 30.5252 23.6694 25.8937 23.6694C21.2622 23.6694 17.5079 19.915 17.5079 15.2835Z" fill="#1849AA"/>
        <path d="M57.3261 63.4938L79.4766 80.397V119.524H82.4279V82.6498L88.4033 87.2092C88.8222 87.534 89.3832 87.6089 89.8732 87.4062C90.3622 87.2035 90.7061 86.7539 90.7724 86.2284C90.8387 85.7019 90.6177 85.1812 90.194 84.8633L58.2224 60.4647C57.6941 60.0612 56.9611 60.0612 56.4317 60.4647L24.4601 84.8633C24.0364 85.1812 23.8164 85.7019 23.8827 86.2284C23.949 86.7539 24.292 87.2035 24.7819 87.4062C25.2719 87.6089 25.832 87.534 26.2508 87.2092L32.2272 82.6498V119.524H35.1785V80.397L57.3261 63.4938Z" fill="#49B2FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41 89.15C41 88.5149 41.5149 88 42.15 88H71.85C72.4851 88 73 88.5149 73 89.15C73 89.7851 72.4851 90.3 71.85 90.3H42.15C41.5149 90.3 41 89.7851 41 89.15ZM41 95.15C41 94.5149 41.5149 94 42.15 94H71.85C72.4851 94 73 94.5149 73 95.15C73 95.7851 72.4851 96.3 71.85 96.3H42.15C41.5149 96.3 41 95.7851 41 95.15ZM41 101.15C41 100.515 41.5149 100 42.15 100H71.85C72.4851 100 73 100.515 73 101.15C73 101.785 72.4851 102.3 71.85 102.3H42.15C41.5149 102.3 41 101.785 41 101.15ZM41 107.15C41 106.515 41.5149 106 42.15 106H71.85C72.4851 106 73 106.515 73 107.15C73 107.785 72.4851 108.3 71.85 108.3H42.15C41.5149 108.3 41 107.785 41 107.15ZM41 113.15C41 112.515 41.5149 112 42.15 112H71.85C72.4851 112 73 112.515 73 113.15C73 113.785 72.4851 114.3 71.85 114.3H42.15C41.5149 114.3 41 113.785 41 113.15Z" fill="#49B2FF"/>
        <path d="M91.28 118.049H17.216C16.4157 118.051 15.7394 117.455 15.6424 116.66L14.1831 67.2888L14.1504 65.1657C14.1447 64.3501 13.4789 63.6949 12.6643 63.7007C11.8496 63.7074 11.1935 64.3731 11.1992 65.1878C11.1992 67.4329 9.44212 69.2794 7.29787 69.3034H7.23639C5.04699 69.3005 3.27356 67.5261 3.27068 65.3377C3.27068 65.0888 3.2188 40.455 3.2188 40.1149C3.21976 35.2711 7.14609 31.3448 11.9908 31.3448H19.2824L24.9437 41.7317C25.1993 42.2015 25.6883 42.4955 26.2234 42.5012C26.7575 42.507 27.2532 42.2236 27.5193 41.7596L33.4938 31.3467C36.617 31.3544 40.9132 31.3717 41.4012 31.3928C46.2056 31.9001 49.6968 36.1934 49.2155 41.0006C49.2107 41.0496 49.2078 41.0986 49.2078 41.1476V66.3454C49.2078 67.1601 49.8687 67.821 50.6834 67.821C51.499 67.821 52.159 67.1601 52.159 66.3454V41.2206C52.7594 34.808 48.078 29.1102 41.6702 28.4531C41.3955 28.4253 38.3578 28.4051 32.6427 28.3936H32.6398C32.1114 28.3945 31.6234 28.677 31.3611 29.1352L26.2743 38.0023L21.4565 29.1631C21.198 28.6885 20.7014 28.3936 20.1615 28.3936H11.9908C5.51676 28.3936 0.268539 33.6418 0.267578 40.1149C0.267578 40.4578 0.316573 64.328 0.319455 65.3415C0.325219 69.1583 3.41863 72.2508 7.23543 72.2556H7.31421C8.76964 72.2373 10.1799 71.7512 11.3366 70.8683L12.694 116.805C12.695 116.839 12.6969 116.873 12.7008 116.906C12.9208 119.233 14.8786 121.008 17.216 121.001H91.28C92.0947 121.001 92.7556 120.34 92.7556 119.525C92.7556 118.71 92.0947 118.049 91.28 118.049Z" fill="#1849AA"/>
        </svg>
        
    )
}


export default House;