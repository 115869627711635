import React from 'react';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/appactions";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    localStorage.removeItem('token');
    window.localStorage.clear();
    window.FB && window.FB.logout();
    dispatch(logout());
    window.location.reload();
  }, []);
  return null;
};

export default Logout;
