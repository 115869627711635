import React, { useEffect } from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core';
import Layout from '../layout';
import Footer from './Footer';
import Profiledetails from './profiledetails';
import Subscriptionstatus from './subscriptionstatus';
import Favourites from './favourites';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../store/actions/appactions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%',
     marginTop: 100,
     [theme.breakpoints.down('md')]: {
      marginTop: 50
    },
   },
  }));

  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function Home(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(0);
const dispatch = useDispatch();
const {t} = useTranslation();
const app = useSelector(state => state.app);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
      dispatch(getProfile(app?.userdata?.token));
    }, [])
  
  return (
      <Layout>
        <div className={classes.root}>
        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        style={{marginTop: 50}}
      >
        <Tab label={t("Profile")} />
        <Tab label={t("Subscription")} />
        <Tab label={t("Favourites")} />
      </Tabs>
      <TabPanel value={value} index={0}>
      <Profiledetails/>
        </TabPanel>
        <TabPanel value={value} index={1}>
      <Subscriptionstatus/>
        </TabPanel>
        <TabPanel value={value} index={2}>
      <Favourites/>
        </TabPanel>
        <Footer/>
        </div>  
   
      </Layout>
   
   
  )
}

export default Home;
