import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import {useDispatch, useSelector} from 'react-redux';
import {clearSignup, Resetpassword, clearError} from '../store/actions/appactions';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import validator from 'validator';
import phone from 'phone';
import { useTranslation } from 'react-i18next';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      hegiht: '100vh'
    },
  },
  buttonfour: {
    width: '120px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#2965DB',
    }
  },
  link: {
    color: '#1849AA',
    fontWeight: '800'
  },
  griditem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  divider: {
    backgroundColor: 'grey',
    width: '45%',
    marginTop: 50
  },
  or: {
    color: 'grey',
    fontWeight: '800',
    marginTop: 50
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);
  const {t} = useTranslation();


  const onSubmit = (e) => {
    e.preventDefault();
    setError(null);
    const phonenumber = phone(username, {country: 'TZ'});
    if (validator.isEmail(username) || phonenumber?.phoneNumber) {
      if(phonenumber?.phoneNumber){
      const trimmedstring = phonenumber?.phoneNumber.substring(1);
      const completephone = trimmedstring;
      const data = {
        phone: completephone,
      }
      console.log(trimmedstring);
      dispatch(Resetpassword(data));
      }else if(validator.isEmail(username)){
        const data = {
          phone: username,
        }
        dispatch(Resetpassword(data));
      }
    }else{
      setError('Enter valid phone or email')
    }
  }

  const clearsignup = () => {
    dispatch(clearSignup());
  }

  useEffect(() => {
    clearError();
    setError(null);
   }, [])

  return (
    <>
      <div className={classes.root}>
         <form noValidate autoComplete="off" onSubmit={(e) => onSubmit(e)}>
  
    <Grid container spacing={2}>
      {app?.successreset ? <Grid item xs={12}>
      <Alert onClose={() => {clearsignup()}} severity="success">Link to reset password sent successfully, please check your email to reset your password</Alert>
      </Grid> : null}
   <Grid item xs={12}>
           <span style={{color: '#1849AA', fontWeight: '700', fontSize: 20}}>{t("Reset password")}</span>
       </Grid>
       <Grid item xs={12}>
       <TextField error={app?.error?.type === 'forgotpassword' || error} helperText={app?.error?.type === 'forgotpassword' ? 'please enter valid email or phone number' : error ? error : null} fullWidth value={username} onChange={(e) => setUsername(e.target.value)} id="username" label={t("Enter phone or email")} variant="outlined" />
       </Grid>
       <Grid item xs={12} className={classes.griditem}>
       <ButtonBase type='submit' elevation={0} variant="contained" color="secondary" className={classes.buttonfour}>
           {app.loading ? <CircularProgress color="#fff" size={25}/> : `${t("Submit")}`}
          </ButtonBase>
          <span className={classes.link}></span>
       </Grid>
   </Grid>
  </form>  
 
      </div> 
   </>
  );
}
