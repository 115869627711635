import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid} from '@material-ui/core';
import Apartment from '../assets/Appartment.png';
import Productcontainer from './singleproduct';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
   container: {
       marginBottom: 100,
       backgroundColor: '#fff',
       borderRadius: 30,
       paddingBottom: 50,
       paddingTop: 50,
   },
   griditem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemthree: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
   },
   griditemtwo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1849AA',
    paddingTop: 100,
    paddingBottom: 50,
    bottom: 120,
    position: 'relative',
    borderRadius: 20
},
headertwo: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: '700'
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
smallheadertwo: {
    fontSize: 12,
    color: '#fff',
},
   header: {
       color: '#222222',
       fontSize: '20px',
       fontWeight: '700'
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#AEB6BB',
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
paddingLeft: 20,
paddingRight: 20,
    height: '36px',
    borderRadius: '54px',
    color: '#fff',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  buttonthreetwo: {
paddingLeft: 20,
paddingRight: 20,
    height: '36px',
    borderRadius: '54px',
    border: '1px solid #1849AA',
    backgroundColor: '#fff',
    color: '#1849AA',
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  },
  illustrationcontainer: {
      width: 200,
      height: 200,
      borderRadius: 100,
      backgroundColor: '#1849AA',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
  },
  detailscontainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 100
  },
  buttoncont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
},
photo: {
    width: 300
  },
  wordscont: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
  },
  headerfour: {
    color: '#2E7D32',
    fontSize: '20px',
    fontWeight: '700',
    paddingTop: 50,
},
smallheadertwo: {
    fontSize: 12,
    color: '#222222',
    paddingTop: 10,
    paddingBottom: 20
},
}));


export default function CustomSeparator() {
  const classes = useStyles();
  const app = useSelector(state => state.app);


  return (
      <>
    <Container className={classes.container}>
   <Grid container spacing={2}>
          {app?.profile?.favorites ? app?.profile?.favorites.map(property => {
            return (
              <Grid key={property.id} item xs={12} md={4} lg={4}>
              <Productcontainer image={property?.thumbnails?.length > 0 ? property?.thumbnails[0] : null} address={property.street_address} name={app?.language === 'english' ? property.title : app?.language === 'kiswahili' ? property?.title_sw || property?.title : null} size={property.property_size} price={property.price_per_month} bathrooms={property.number_of_bathrooms} bedrooms={property.number_of_bedrooms} id={property.id} type_id={property.type_id} propertyid={property.id} liked={true}/>
              </Grid>
            )
          }) : null}
       
   </Grid>
    </Container>
    </>
  );
}
