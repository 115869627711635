import '../App.css';
import { Button, ButtonBase, Divider, makeStyles } from '@material-ui/core';
import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import Like from '@material-ui/icons/Edit';
import Sofa from '@material-ui/icons/Weekend';
import Shower from '@material-ui/icons/Bathtub';
import Car from '@material-ui/icons/DriveEta';
import Measurement from '@material-ui/icons/BorderStyle';
import Phone from '@material-ui/icons/Phone';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
   productcontainer: {
       width: '100%',
       height: 500,
       borderRadius: 25,
       display: 'flex',
       flexDirection: 'column',
       overflow: 'hidden',
       [theme.breakpoints.down('md')]: {
        width: '100%',
      },
   },
   photo: {
       width: '100%',
       height: '100%',
       display: 'block',
       objectFit: 'cover',
       top: 0,
       left: 0,
       zIndex: 1,
       position: 'absolute'
   },
   photowords: {
       width: '100%',
       height: '100%',
       top: 0,
       left: 0,
       zIndex: 9,
       position: 'absolute',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'space-between',
       flexDirection: 'column',
       background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 67.39%, rgba(0, 0, 0, 0.81) 100%)',
   },
   photocontainer: {
       flex: 1,
       width: '100%',
       position: 'relative',
       overflow: 'hidden'
   },
   wordscontainer: {
       flex: 1,
       width: '100%',
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'space-evenly',
       backgroundColor: '#fff'
   },
//    featured: {
//        height: 30,
//        backgroundColor: '#1849AA',
//        paddingLeft: 10,
//        paddingRight: 10,
//        borderRadius: 15,
//        color: '#fff'
//    },
   forrent: {
    height: 30,
    backgroundColor: '#222222',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15,
    color: '#fff'
   },
   like: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff'
   },
   statuslike: {
    height: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#369B3B',
    borderRadius: 15,
    color: '#fff'
   },
   statuspending: {
    height: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#F29339',
    borderRadius: 15,
    color: '#fff'
   },
   liketwo: {
    height: 30,
    width: 30,
    backgroundColor: '#1849AA',
    borderRadius: 15,
    color: '#fff'
   },
   photowordsinside: {
       width: '100%',
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       justifyContent: 'space-between',
       paddingLeft: 10,
       paddingRight: 10,
       paddingTop: 20
   },
   photowordsinsidetwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20
},
wordsone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
},
spanone: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
},
spantwo: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
},
spanthree: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
},
spanfour: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
},
wordstwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
},
wordsthree: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20
}
  }));

  
  

function Productcontainer({image, name, fromdashboard, address, price, size, bathrooms, bedrooms,id, type_id, onPress, is_verified}) {
    const classes = useStyles()
    const {t} = useTranslation();
    const app = useSelector(state => state.app);
    const phoneprice = price ? new Intl.NumberFormat().format(price) : '';
  return (
    <>
    <Link onClick={onPress} style={{textDecoration: 'none', color: '#222222'}}>
      <div className={classes.productcontainer}>
          <div className={classes.photocontainer}>
           {image ? <img src={image} className={classes.photo}/> : null}
           <div className={classes.photowords}>
               <div className={classes.photowordsinside}>
                   <ButtonBase className={classes.featured}></ButtonBase>
                   <ButtonBase className={classes.forrent}>{type_id === 1 ? `${t("For Rent")}` : type_id === 2 ? `${t("For Sell")}` : type_id === 3 ? "Plot" : null}</ButtonBase>
               </div>
               <div className={classes.photowordsinsidetwo}>
                   {/* <span style={{color: '#fff'}}>{category}</span> */}
            {fromdashboard ? <ButtonBase className={is_verified ? classes.statuslike : classes.statuspending}><span style={{color: '#fff'}}>{is_verified ? `${t('Verified')}` : `${t('Waiting for approval')}`}</span></ButtonBase> : null}
                   <ButtonBase className={classes.like}><Like style={{fontSize: 16}}/></ButtonBase>
               </div>
           </div>
          </div>
          <div className={classes.wordscontainer}>
             <div className={classes.wordsone}>
                 <span className={classes.spanone}>{name}</span>
                 <span className={classes.spantwo}>{address}</span>
             </div>
             <div className={classes.wordstwo}>
             <span className={classes.spanthree}>TZS {phoneprice || '---'} / <span className={classes.spanfour}>{app?.language === 'english' ? "30 days" : "siku 30"}</span></span>
               {type_id === 1 ?   <span className={classes.spanthree}>{app?.language === 'english' ? "1 Installments" : "Awamu 1"} / <span className={classes.spanfour}>{app?.language === 'english' ? "30 days" : "siku 30"}</span></span> : null}
             </div>
             <div className={classes.wordsthree}>
                 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 2, justifyContent: 'space-evenly'}}><Sofa style={{color: '#1849AA'}}/>{bedrooms}<Divider orientation="vertical" flexItem /><Shower style={{color: '#1849AA'}}/>{bathrooms}<Divider orientation="vertical" flexItem /><Car style={{color: '#1849AA'}}/><Divider orientation="vertical" flexItem /><Measurement style={{color: '#1849AA'}}/> <span>{size}SqFt</span></div>
                 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-end'}}><ButtonBase className={classes.liketwo}><Phone style={{fontSize: 16}}/></ButtonBase> </div>
             </div>
          </div>
      </div>
      </Link>
   </>
  )
}

export default Productcontainer;