import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, ButtonBase, CircularProgress} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {clearError, Editprofile} from '../store/actions/appactions';
import {phone} from 'phone';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginTop: 50,
      },
      inputtwo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 20
      },
   container: {
       paddingTop: 50,
       marginBottom: 100,
       marginTop: 100,
       backgroundColor: '#fff',
       borderRadius: 30,
       paddingLeft: 50,
       paddingBottom: 50,
       [theme.breakpoints.down('md')]: {
        paddingLeft: 15,
        marginTop: 0,
        borderRadius: 0,
        paddingTop: 20
      },

   },
   griditem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
   },
   choosecontainer: {
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       paddingTop: 20,
   },
headertwo: {
    color: '#222222',
    fontSize: '14px',
    fontWeight: '200',
    marginLeft: 10
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
   header: {
       color: '#222222',
       fontSize: '20px',
       fontWeight: '700'
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#AEB6BB',
       paddingTop: 10
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
    width: '136px',
    height: '46px',
    borderRadius: '54px',
    color: '#fff',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  },
  inputcontainer: {
      display: 'flex',
      flexDirection: 'column',
  }
}));

function Alerts(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function CustomSeparator() {
  const classes = useStyles();
  const app = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [firstname, setFirstname] = useState(app?.userdata?.user?.first_name || '');
  const [lastname, setLastname] = useState(app?.userdata?.user?.last_name || '');
  const [email, setEmail] = useState(app?.userdata?.user?.email || '');
  const [phonenumber, setPhonenumber] = useState(app?.userdata?.user?.phone || '');
  const [password, setPassword] = useState('');
  const [passwordconfirm, setPasswordconfirm] = useState('');
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const {t} = useTranslation();

  const onSubmit = () => {
    setError(null);
    const phonenumberformat = phone(phonenumber, {country: 'TZ'});
    if(!firstname){
      setError({type: 'firstname', data: 'enter first name'})
    } else if(!lastname){
      setError({type: 'lastname', data: 'enter last name'})
    } else if(!email){
      setError({type: 'email', data: 'enter email'})
    } else if(!phonenumber || !phonenumberformat?.phoneNumber){
      setError({type: 'phone', data: 'enter valid phone number'})
    } else if(!password || !passwordconfirm){
      setError({type: 'password', data: 'enter password'})
    } else if(password !== passwordconfirm){
      setError({type: 'password', data: 'password should match'})
    } else {
      const trimmedstring = phonenumberformat?.phoneNumber.substring(1);
      dispatch(Editprofile(firstname, lastname, email, trimmedstring, password, passwordconfirm, app?.userdata?.token));
    }
  }

  useEffect(() => {
      if(app.successedit){
        setShow(true)
      }else{
        setShow(false);
      }
  }, [app.successedit])

  useEffect(() => {
    if(app?.error?.type === 'editprofile'){
      setShowerror(true)
    }else{
      setShowerror(false);
    }
}, [app.error])

  const handleClose = () => {
    dispatch(clearError());
    setShow(false);
  }

  const handleCloseerror = () => {
    dispatch(clearError());
    setShowerror(false);
  }


  return (
      <>
       <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alerts onClose={handleClose} severity="success">
          Profile edited successfully
        </Alerts>
      </Snackbar>
      <Snackbar open={showerror} autoHideDuration={6000} onClose={handleCloseerror} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alerts onClose={handleCloseerror} severity="error">
          Error occurred, please try again
        </Alerts>
      </Snackbar>
    <Container className={classes.container}>
      <Grid container spacing={4}>
      <Grid item xs={0} md={0} lg={2}/>
      <Grid item xs={12} md={12} lg={8}>
          <Grid container >
          <Grid item xs={12}/>
              <Grid item xs={12} className={classes.inputcontainer}>
                <span className={classes.header}>{t("Edit profile")}</span>
               <Grid container spacing={2}>
               <Grid item xs={12} md={6} lg={6}>
             <div className={classes.input}>
             <TextField error={error?.type === 'firstname'} helperText={error?.type === 'firstname' ? error.data : null} fullWidth label={t("First name")} id="filled-size-normal"  value={firstname} onChange={(e) => setFirstname(e.target.value)} variant="outlined" />
             </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
             <div className={classes.input}>
             <TextField error={error?.type === 'lastname'} helperText={error?.type === 'lastname' ? error.data : null} fullWidth label={t("Last name")} id="filled-size-normal" value={lastname} onChange={(e) => setLastname(e.target.value)}  variant="outlined" />
             </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
             <div className={classes.input}>
             <TextField  error={error?.type === 'email'} helperText={error?.type === 'email' ? error.data : null}fullWidth label={t("Email")} id="filled-size-normal"  variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
             </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
             <div className={classes.input}>
             <TextField error={error?.type === 'phone'} helperText={error?.type === 'phone' ? error.data : null} fullWidth label={t("Phone number")} id="filled-size-normal"  variant="outlined" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)}/>
             </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
             <div className={classes.input}>
             <TextField type='password' error={error?.type === 'password'} helperText={error?.type === 'password' ? error.data : null} fullWidth label={t("Password")} id="filled-size-normal"  variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} />
             </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
             <div className={classes.input}>
             <TextField type='password' error={error?.type === 'password'} helperText={error?.type === 'password' ? error.data : null} fullWidth label={t("Password confirmation")} id="filled-size-normal"  variant="outlined" value={passwordconfirm} onChange={(e) => setPasswordconfirm(e.target.value)}/>
             </div>
                </Grid>
               </Grid>

              
             <div className={classes.inputtwo}>
             <ButtonBase onClick={() => {onSubmit()}} elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             {app.loadingedit ? <CircularProgress color='white' size={20}/> : `${t('Submit')}`}
            </ButtonBase>
             </div>
              </Grid>
          </Grid>
      </Grid>
      <Grid item xs={0} md={0} lg={2}/>
      </Grid>
    </Container>
    </>
  );
}
