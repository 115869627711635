import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Link as Linkscroll} from 'react-scroll';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {useTranslation} from 'react-i18next';
import {language} from '../store/actions/appactions';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function SwipeableTemporaryDrawer({opensidebar, closesidebar, findrental, postrental, profile}) {
  const classes = useStyles();
  const {t, i18n} = useTranslation();
  let history = useHistory();
  const app = useSelector(state => state.app);
  const [open, setOpen] = React.useState(false);
  const [openprofile, setOpenprofile] = React.useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickprofile = () => {
    setOpenprofile(!openprofile);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const changelanguage = (lang) => {
    i18n.changeLanguage(lang === 'kiswahili' ? 'sh' : 'en');
    dispatch(language(lang))
}

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
        <React.Fragment>
          <SwipeableDrawer
            anchor="left"
            open={opensidebar}
            onClose={closesidebar}
          >
              <div
      className={clsx(classes.list)}
      role="presentation"
    >
         <List>
          <ListItem button onClick={postrental}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText  primary={t("Post a rental")} />
          </ListItem>
          <Divider/>
          <ListItemLink button className={classes.nested} href='/why-eneo'>
            <ListItemText  primary={t("Why Eneo?")} />
          </ListItemLink>
          {!app?.userdata?.token ? 
          <>
          <Divider/>
          <ListItemLink button href='/auth' >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={`${t("Login")}/${t("Sign up")}`} />
          </ListItemLink></> : null}
          {app?.userdata?.token ? 
          <>
          <Divider/>
          <ListItem button onClick={handleClickprofile}>
        <ListItemText primary={app?.userdata?.user?.first_name} />
        {openprofile ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openprofile} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink button className={classes.nested} href='/profile'>
            <ListItemText primary={t("Profile")} />
          </ListItemLink>
          <ListItemLink href='/logout' button className={classes.nested} >
            <ListItemText primary={t("Signout")} />
          </ListItemLink>
        </List>
      </Collapse>
      </> : null}
          <Divider/>
          <ListItem button onClick={handleClick}>
        <ListItemText primary={t("Language")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'english' ? '#1849AA' : '#fff', color: app.language === 'english' ? '#fff' : 'black'}} onClick={() => changelanguage('english')} button className={classes.nested}>
            <ListItemText primary="English" />
          </ListItem>
          <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'kiswahili' ? '#1849AA' : '#fff', color: app.language === 'kiswahili' ? '#fff' : 'black'}} onClick={() => changelanguage('kiswahili')} button className={classes.nested}>
            <ListItemText primary="Kiswahili" />
          </ListItem>
        </List>
      </Collapse>
      </List>
    </div>  
          </SwipeableDrawer>
        </React.Fragment>
  );
}
