import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import {useDispatch, useSelector} from 'react-redux';
import {Signin, clearError, Signinsocial} from '../../store/actions/appactions';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom';
import validator from 'validator'
import {phone} from 'phone';
import Googlelogin from './google';
import Facebook from './facebook';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
 


{/* <script src="https://www.gstatic.com/firebasejs/8.9.1/firebase-app.js"></script>


<script src="https://www.gstatic.com/firebasejs/8.9.1/firebase-analytics.js"></script>

<script>

  var firebaseConfig = {
    apiKey: "AIzaSyC8d5oc-j0jnX2ACOiRw52WikUM6aS41IM",
    authDomain: "eneowebapp.firebaseapp.com",
    projectId: "eneowebapp",
    storageBucket: "eneowebapp.appspot.com",
    messagingSenderId: "1080361822797",
    appId: "1:1080361822797:web:85b4efd94c0226f4230a04",
    measurementId: "G-QX0TT4PN1E"
  };
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
</script> */}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


  function Alerts(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  buttonfour: {
    width: '120px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#2965DB',
    }
  },
  link: {
    color: '#1849AA',
    fontWeight: '800'
  },
  griditem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  divider: {
    backgroundColor: 'grey',
    width: '45%',
    marginTop: 50
  },
  or: {
    color: 'grey',
    fontWeight: '800',
    marginTop: 50
  }
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showerror, setShowerror] = useState(false);
  const {t} = useTranslation()
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  let history = useHistory();


  function validation(value){
  const validate =  validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1
    })

    return validate;
  }

  const onSubmit = () => {
    setError(null);
    const phonenumber = phone(username, {country: 'TZ'});
    if (validator.isEmail(username) && password) {
       const data = {
      username,
      password,
    }
    dispatch(Signin(data));
    }else if(phonenumber?.phoneNumber && password){
      const trimmedstring = phonenumber?.phoneNumber.substring(1);
      const completephone = trimmedstring;
      const data = {
        username: completephone,
        password,
      }
      dispatch(Signin(data));
    }else if(!validator.isEmail(username) && !phonenumber?.phoneNumber){
      setError({
        type: 'username',
        data: 'Enter a valid email or phone number'
      })
    }else if(!password){
     setError({
       type: 'password',
       data: 'Enter password'
     })
    }
   
  }

  useEffect(() => {
    if(app.signinsuccess){
      history.push("/");
    }
}, [app.signinsuccess])

useEffect(() => {
  if(app.loadingsocial){
    setShow(true);
  }else{
    setShow(false)
  }
}, [app.loadingsocial])

useEffect(() => {
  if(app?.error?.type === 'signinsocial'){
    setShowerror(true);
  }else{
    setShowerror(false)
  }
}, [app.error])

    useEffect(() => {
     clearError();
     setError(null);
    }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFacebookLogin = (user) => {
    const googledata = {
      first_name: user?._profile?.firstName,
      last_name: user?._profile?.lastName,
      email: user?._profile?.email,
      avatar: user?._profile?.profilePicURL,
      provider: 2,
      token: user?._token?.accessToken,
    }
    dispatch(Signinsocial(googledata))
  }

  const handleSocialLogin = (user) => {
    const googledata = {
      first_name: user?._profile?.firstName,
      last_name: user?._profile?.lastName,
      email: user?._profile?.email,
      avatar: user?._profile?.profilePicURL,
      provider: 1,
      token: user?._token?.accessToken,
    }
    dispatch(Signinsocial(googledata))
  };
  
  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseerror = () => {
    setShowerror(false);
  }

  return (
    <>
    <Snackbar open={show} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alerts onClose={handleClose} severity="success">
          logging you in <CircularProgress color="white" size={10}/>
        </Alerts>
      </Snackbar>

      <Snackbar open={showerror} autoHideDuration={6000} onClose={handleCloseerror} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alerts onClose={handleCloseerror} severity="error">
          Error occured, please try again
        </Alerts>
      </Snackbar>

    <Grid container spacing={2}>
      {app?.error?.type === 'signin' && app?.error?.data?.message  &&  typeof app?.error?.data?.message === 'string' ? <Grid item xs={12}>
      <Alert severity="error">{app?.error?.data?.message}</Alert>
      </Grid> : null}
   <Grid item xs={12}>
           <span style={{color: '#1849AA', fontWeight: '700', fontSize: 20}}>{t("Please login to continue using Eneo")}</span>
       </Grid>
       <Grid item xs={12}>
       <TextField error={(app?.error?.type === 'signin' && app?.error?.data?.messages?.username) || error?.type === 'username'} helperText={app?.error?.type === 'signin' && app?.error?.data?.messages?.username ? app?.error?.data?.messages?.username[0] : error?.type === 'username' ? error?.data : null} fullWidth value={username} onChange={(e) => setUsername(e.target.value)} id="username" label={t("Email or Phone (255xxxxx)")} variant="outlined" />
       </Grid>
       <Grid item xs={12}>
       <TextField fullWidth error={(app?.error?.type === 'signin' && app?.error?.data?.messages?.password) || error?.type === 'password'} helperText={app?.error?.type === 'signin' && app?.error?.data?.messages?.password ? app?.error?.data?.messages?.password[0] : error?.type === 'password' ? error?.data : null} type='password' id="password" value={password}  onChange={(e) => setPassword(e.target.value)} label={t("Password")} variant="outlined"  endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }/>
       </Grid>
       <Grid item xs={12} className={classes.griditem}>
       <ButtonBase onClick={() => onSubmit()} elevation={0} variant="contained" color="secondary" className={classes.buttonfour}>
           {app.loading ? <CircularProgress color="#fff" size={25}/> : `${t('Sign in')}`}
          </ButtonBase>
         <Link to='/forgot-password' style={{textDecoration: 'none'}}>
         <span className={classes.link}>{t("Forgot password?")}</span>
           </Link> 
       </Grid>
       <Grid item xs={12} className={classes.griditem}>
         <Divider className={classes.divider}/><span className={classes.or}>{t("OR")}</span><Divider className={classes.divider}/>
       </Grid>
       <Grid item xs={12} className={classes.griditem}>
         <Grid container>
           <Grid item xs={12} md={6} lg={6}>
              <Googlelogin 
       provider="google"
       appId="212938495154-7n2fgu3gb2q6eilpmt06s7ge88ti9n44.apps.googleusercontent.com"
       onLoginSuccess={handleSocialLogin}
       onLoginFailure={handleSocialLoginFailure}
       />
           </Grid>
           <Grid item xs={12} md={6} lg={6}>
             <Facebook 
       provider="facebook"
       appId="2993120210945084"
       onLoginSuccess={handleFacebookLogin}
       onLoginFailure={handleSocialLoginFailure}
       /> 
           </Grid>
         </Grid>
       </Grid>
   </Grid>
  </>
  );
}
