import React, { useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Grid, ButtonBase} from '@material-ui/core';
import Globe from '@material-ui/icons/Public';
import Person from '@material-ui/icons/PersonOutline';
import Logo from '../components/logos/logo';
import Logotwo from '../components/logos/logotwo';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Usericon from '@material-ui/icons/PermIdentity';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Menuicon from '@material-ui/icons/Menu';
import Swiperble from './sidebar';
import {useHistory} from 'react-router-dom';
import {Link as Linkscroll} from 'react-scroll';
import {useTranslation} from 'react-i18next';
import {language} from '../store/actions/appactions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            height: 80
        },
    },
    headercontainertt: {
        [theme.breakpoints.down('md')]: {
            height: 80
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    headercontainer: {
        height: '120px',
        backgroundColor: '#1849AA',
        [theme.breakpoints.down('md')]: {
            height: 80
        },
    },
    grid: {
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            height: 80
        },
    },
    menugrid: {
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    gridtwo: {
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    button: {
        width: '130px',
        height: '46px',
        borderRadius: '54px',
        fontSize: '0.875rem',
        color: '#fff',
        textDecoration: 'none',
        border: '1px solid rgba(233, 240, 255, 0.5)',
        backgroundColor: 'rgba(233, 240, 255, 0.05)',
        "&:hover": {
            backgroundColor: 'rgba(233, 240, 255, 0.05)',
        }
    },
    buttontwo: {
        width: '130px',
        height: '46px',
        borderRadius: '54px',
        backgroundColor: '#fff',
        fontSize: 15,
        color: '#1849AA',
        "&:hover": {
            backgroundColor: '#fff',
        },
    },
    buttonfive: {
        width: '130px',
        height: '46px',
        fontSize: '0.875rem',
        borderRadius: '54px',
        backgroundColor: '#3EBF45',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#3EBF45',
        }
    },
    buttonthree: {
        paddingLeft: 20,
        paddingRight: 20,
        height: '46px',
        borderRadius: '54px',
        border: '1px solid rgba(233, 240, 255, 0.5)',
        backgroundColor: '#1849AA',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": {
            backgroundColor: '#1849AA',
        }
    },
    buttonthreethree: {
        width: '116px',
        height: '46px',
        borderRadius: '54px',
        border: '1px solid rgba(233, 240, 255, 0.5)',
        backgroundColor: '#1849AA',
        fontSize: '0.875rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": {
            backgroundColor: '#1849AA',
        }
    },
    buttonfour: {
        paddingLeft: 20,
        paddingRight: 20,
        height: '46px',
        borderRadius: '54px',
        backgroundColor: '#2965DB',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        "&:hover": {
            backgroundColor: '#2965DB',
        }
    },
    popper: {
        backgroundColor: '#fff',
        marginTop: 10,
        marginRight: 50,
        borderRadius: 10,
        minWidth: 200,
        zIndex: '999999999'
    },
    searchbar: {
        width: '50%',
        height: 70,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 50,
        position: 'absolute',
        zIndex: '9999999999',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 64
    },
    inputcontainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: 79
    },
    input: {
        width: '100%',
        backgroundColor: 'transparent',
        border: '0 !important',
        borderColor: 'transparent !important',
        color: '#222222',
        paddingLeft: 20,
        '&:focus, &:active': {
            border: 0,
            borderColor: 'transparent !important',
            outline: 'none !important'
        }
    },
    inputheading: {
        color: '#1849AA',
        fontSize: 14,
        paddingLeft: 20,
    },
    buttonsearch: {
        width: 50,
        height: 50,
        backgroundColor: '#1849AA',
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
    },
    searchbardiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    logo: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    logotwo: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    }
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function ButtonAppBar() {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEltwo, setAnchorEltwo] = React.useState(null);
    const [anchorElone, setAnchorElone] = React.useState(null);
    const [anchorElthree, setAnchorElthree] = React.useState(null);
    let history = useHistory();
    const [openside, setOpenside] = useState(false);
    const handleClick = (event) => {
        setAnchorEltwo(null)
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEltwo(false)
        setAnchorEl(false);
    };

    const handleClosetwo = (event) => {
        setAnchorElthree(false)
        setAnchorElone(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const handleClicktwo = (event) => {
        setAnchorElone(null)
        setAnchorElthree(anchorElthree ? null : event.currentTarget);
    };

    console.log(open)
    const opentwo = Boolean(anchorElthree);
    const idtwo = opentwo ? 'simple-popper' : undefined;

    const onOpen = () => {
        setOpenside(true);
    }

    const closesidebar = () => {
        setOpenside(false);
    }

    const postrental = () => {
        setOpenside(false);
        if(app?.userdata?.token){
            history.push('/dashboard/property/create');
        }else{
            history.push('/auth');
        }

    }

    const profile = () => {
        setOpenside(false);
        if(app?.userdata?.token){
            history.push('/profile');
        }else{
            history.push('/auth');
        }

    }

    const findrental = () => {
        setOpenside(false);
    }

    const changelanguage = (lang) => {
        i18n.changeLanguage(lang === 'kiswahili' ? 'sh' : 'en');
        dispatch(language(lang))
    }

    return (
        <>
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar className={classes.headercontainer}>
                        <Grid container className={classes.headercontainertt}>
                            <Grid item xs={3} className={classes.grid}>
                                <Link to='/'>
                                    <div className={classes.logo}>
                                        <Logo/>
                                    </div>
                                    <div className={classes.logotwo}>
                                        <Logotwo/>
                                    </div>
                                </Link>
                            </Grid>
                            <Grid item xs={3} className={classes.gridtwo}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}>
                                    {/* <ButtonBase elevation={0} variant="contained" color="secondary"
                                                className={classes.buttontwo}>

                        Find a rental
                                    </ButtonBase> */}
                                    {/* <div style={{
                                        backgroundColor: '#fff',
                                        width: 10,
                                        height: 10,
                                        clipPath: 'polygon(100% 0, 0 0, 51% 100%)',
                                        position: 'absolute',
                                        top: 46,
                                    }}></div> */}
                                </div>
                                <Link to={app.userdata ? '/dashboard/property/create' : '/auth'} style={{textDecoration: 'none'}}>
                                    <ButtonBase elevation={0} variant="contained" color="secondary"
                                                className={classes.button}>
                                        {t("Post a rental")}
                                    </ButtonBase>
                                </Link>
                            </Grid>
                            <Grid item xs={3} className={classes.gridtwo}>
                                {/* <Linkscroll
                                to="rentals"
                                spy={true}
                                smooth={true}
                                hashSpy={true}
                                offset={50}
                                duration={500}
                                delay={100}
                                isDynamic={true}
                                // onSetActive={this.handleSetActive}
                                // onSetInactive={this.handleSetInactive}
                                // ignoreCancelEvents={false}
                                spyThrottle={500}
                                > */}
                                    <Link to={'/why-eneo'} style={{textDecoration: 'none'}}>
                                       <ButtonBase elevation={0} variant="contained" color="secondary"
                                            className={classes.buttonfive}>
                                    {t("Why eneo?")}
                                </ButtonBase>  
                                    </Link>
                               
                                {/* </Linkscroll>     */}
                            </Grid>
                            <Grid item xs={3} className={classes.gridtwo}>
                                <>
                                <ClickAwayListener onClickAway={handleClosetwo}>
                                   <ButtonBase aria-describedby={idtwo} onClick={handleClicktwo} elevation={0} variant="contained" color="secondary"
                                            className={classes.buttonfour}>
                                    {app.language === 'english' ? 'English' : 'Kiswahili'} <Globe style={{marginLeft: 10, color: '#8BAFF6'}}/>
                                </ButtonBase> 
                                </ClickAwayListener>
                                
                                <Popper id={idtwo} open={opentwo} anchorEl={anchorElthree} className={classes.popper}>
                                            <List component="nav" aria-label="main mailbox folders">
                                                <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'english' ? '#1849AA' : '#fff', color: app.language === 'english' ? '#fff' : 'black'}} onClick={() => changelanguage('english')}>
                                                    <ListItemText primary="English"/>
                                                </ListItem>
                                                <Divider/>
                                                <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'kiswahili' ? '#1849AA' : '#fff', color: app.language === 'kiswahili' ? '#fff' : 'black'}} onClick={() => changelanguage('kiswahili')}>
                                                    <ListItemText primary="Kiswahili"/>
                                                </ListItem>
                                                {/* <Divider/>
                                                <ListItemLink href="/dashboard">
                                                    <ListItemText primary="Dashboard"/>
                                                </ListItemLink> */}
                                            </List>
                                        </Popper>
                                </>
                                {app?.userdata?.token && app.isAuthenticated ?
                                    
                                    <> <ClickAwayListener onClickAway={handleClose}>
                                       <ButtonBase aria-describedby={id} onClick={handleClick} elevation={0}
                                                    variant="contained" color="secondary"
                                                    className={classes.buttonthreethree}>
                                            {app?.userdata?.user?.first_name || null} <Usericon/>
                                        </ButtonBase>
                                        </ClickAwayListener>
                                        <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
                                            <List component="nav" aria-label="main mailbox folders">
                                                <ListItemLink href="profile">
                                                    <ListItemText primary={t("Profile")}/>
                                                </ListItemLink>
                                                <Divider/>
                                                <ListItemLink href="/logout">
                                                    <ListItemText primary={t("Signout")}/>
                                                </ListItemLink>
                                                {/* <Divider/>
                                                <ListItemLink href="/dashboard">
                                                    <ListItemText primary="Dashboard"/>
                                                </ListItemLink> */}
                                            </List>
                                        </Popper> 
                                     
                                    </>  
                                
                                    : <Link to='/auth' style={{textDecoration: 'none', color: '#fff'}}>
                                        <ButtonBase elevation={0} variant="contained" color="secondary"
                                                    className={classes.buttonthree}>
                                            {t("Sign in")}/{t("Sign up")} <Person style={{marginLeft: 5}}/>
                                        </ButtonBase>
                                    </Link>}

                            </Grid>
                            <Grid item xs={9} className={classes.menugrid}>
                                <Menuicon color='white' onClick={() => {onOpen()}}/>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
          {/* {!['/terms-and-conditions', '/privacy-and-policy', '/auth', '/founders', '/faq', '/how-it-works', '/aboutus', '/ourstory', '/places'].includes(window?.location?.pathname) ?   <Searchbar /> : null} */}
            <Swiperble findrental={() => {findrental()}} postrental={() => {postrental()}} profile={() => profile()} opensidebar={openside} closesidebar={closesidebar}/>
        </>
    );
}
