import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import {useDispatch, useSelector} from 'react-redux';
import {clearSignup, Reset, clearError} from '../store/actions/appactions';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useParams} from "react-router-dom";
import validator from 'validator';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  buttonfour: {
    width: '120px',
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#1849AA',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#2965DB',
    }
  },
  link: {
    color: '#1849AA',
    fontWeight: '800'
  },
  griditem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  divider: {
    backgroundColor: 'grey',
    width: '45%',
    marginTop: 50
  },
  or: {
    color: 'grey',
    fontWeight: '800',
    marginTop: 50
  }
}));

function validation(value){
  const validate =  validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1
    })

    return validate;
  }

export default function SimpleTabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState(null);
  const {t} = useTranslation();
  const params = useParams();
  let token = params.token;

  const onSubmit = (e) => {
      e.preventDefault();
      setError(null);
      const data = {
        password,
       password_confirmation: confirm
      }
      if(password === confirm && password){
        dispatch(Reset(data, token));
      } else if(!password){
        setError('enter a valid password')
      } else if(password !== confirm){
          setError('password don`t match');
      } 
  }

  const clearsignup = () => {
    dispatch(clearSignup());
  }

  useEffect(() => {
    clearError();
    setError(null);
   }, [])

  return (
    <>
      <div className={classes.root}>
         <form noValidate autoComplete="off" onSubmit={(e) => onSubmit(e)}>
  
    <Grid container spacing={2}>
      {app?.successresetpassword ? <Grid item xs={12}>
      <Alert onClose={() => {clearsignup()}} severity="success"> password reset successfully, <a href='/'>go back home</a></Alert>
      </Grid> : null}
      {app?.error?.type === 'resetpassword' ? <Grid item xs={12}>
      <Alert onClose={() => {clearsignup()}} severity="error">Reset password token has expired or does not exist, please try to request again </Alert>
      </Grid> : null}
   <Grid item xs={12}>
           <span style={{color: '#1849AA', fontWeight: '700', fontSize: 20}}>{t("Reset password")}</span>
       </Grid>
       <Grid item xs={12}>
       <TextField error={app?.error?.type === 'verifypassword' || error} helperText={app?.error?.type === 'verifypassword' ? 'please enter valid password' : error ? error : null} fullWidth type='password' value={password} onChange={(e) => setPassword(e.target.value)} id="password" label={t("Enter password")} variant="outlined" />
       </Grid>
       <Grid item xs={12}>
       <TextField type='password' error={app?.error?.type === 'verifypassword' || error} helperText={app?.error?.type === 'verifypassword' ? 'please enter valid email or phone number' : error ? error : null} fullWidth value={confirm} onChange={(e) => setConfirm(e.target.value)} id="confirmpassword" label={t("Confirm password")} variant="outlined" />
       </Grid>
       <Grid item xs={12} className={classes.griditem}>
       <ButtonBase type='submit' elevation={0} variant="contained" color="secondary" className={classes.buttonfour}>
           {app.loading ? <CircularProgress color="#fff" size={25}/> : 'Submit'}
          </ButtonBase>
          <span className={classes.link}></span>
       </Grid>
   </Grid>
  </form>  
      </div>
   </>
  );
}
