import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Dashboard';
import Building from '@material-ui/icons/HomeWork';
import Logo from './logos/logo';
import {Link} from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Languageicon from '@material-ui/icons/Language';
import Usericon from '@material-ui/icons/AccountBox';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {language} from '../store/actions/appactions';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
     display: 'none'
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1849AA'
  },
}));

export default function SwipeableTemporaryDrawer({opensidebar, closesidebar}) {
  const classes = useStyles();
  const{t, i18n} = useTranslation();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const app = useSelector(state => state.app);
  const [openprofile, setOpenprofile] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickprofile = () => {
    setOpenprofile(!openprofile);
  };

  const changelanguage = (lang) => {
    i18n.changeLanguage(lang === 'kiswahili' ? 'sh' : 'en');
    dispatch(language(lang))
}

  const handleClick = () => {
    setOpen(!open);
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  return (
        <React.Fragment>
          <SwipeableDrawer
            anchor="left"
            open={opensidebar}
            onClose={closesidebar}
            className={classes.drawer}
            classes={{
               paper: classes.drawerPaper,
            }}
          >
                <div className={classes.toolbar}>
            <Link to="/">
            <Logo/>
            </Link>
        </div>
        <Divider className={classes.divider}/>
        <List>
            <ListItemLink className={classes.listitem} href='/dashboard'>
              <ListItemIcon><MailIcon style={{color: '#fff'}}/></ListItemIcon>
              <ListItemText primary={t('Dashboard')} style={{color: '#fff'}} />
            </ListItemLink>
            <ListItemLink className={classes.listitem} href='/dashboard/property'>
              <ListItemIcon><Building style={{color: '#fff'}}/></ListItemIcon>
              <ListItemText primary={t('Property')} style={{color: '#fff'}} />
            </ListItemLink>
            <Divider/>
          <ListItem button onClick={handleClickprofile}>
          <ListItemIcon><Usericon style={{color: '#fff'}}/></ListItemIcon>
        <ListItemText style={{color: '#fff'}} primary={app?.userdata?.user?.first_name} />
        {openprofile ? <ExpandLess style={{color: '#fff'}} /> : <ExpandMore style={{color: '#fff'}} />}
      </ListItem>
      <Collapse in={openprofile} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink button className={classes.nested} href='/profile'>
            <ListItemText style={{color: '#fff'}} primary={t("Profile")} />
          </ListItemLink>
          <ListItemLink href='/logout' button className={classes.nested} >
            <ListItemText style={{color: '#fff'}} primary={t("Signout")} />
          </ListItemLink>
        </List>
      </Collapse>
      <ListItem button onClick={handleClick}>
      <ListItemIcon><Languageicon style={{color: '#fff'}}/></ListItemIcon>
        <ListItemText style={{color: '#fff'}} primary={t("Language")} />
        {open ? <ExpandLess style={{color: '#fff'}}/> : <ExpandMore style={{color: '#fff'}}/>}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'english' ? '#1849AA' : '#fff', color: app.language === 'english' ? '#fff' : 'black'}} onClick={() => changelanguage('english')} button className={classes.nested}>
            <ListItemText primary="English" />
          </ListItem>
          <ListItem style={{ cursor: 'pointer', backgroundColor: app.language === 'kiswahili' ? '#1849AA' : '#fff', color: app.language === 'kiswahili' ? '#fff' : 'black'}} onClick={() => changelanguage('kiswahili')} button className={classes.nested}>
            <ListItemText primary="Kiswahili" />
          </ListItem>
        </List>
      </Collapse>
            {/* <ListItemLink button className={classes.listitem}>
              <ListItemIcon><People style={{color: '#fff'}}/></ListItemIcon>
              <ListItemText primary='Customers' style={{color: '#fff'}} />
            </ListItemLink> */}
        </List>
        <Divider className={classes.divider}/>
        <List>
            <ListItemLink href='/faq' button className={classes.listitem}>
              <ListItemText primary={t('Faq')} style={{color: '#fff', opacity: '0.5'}} />
            </ListItemLink>
            <ListItemLink href='/terms-and-conditions' button className={classes.listitem}>
              <ListItemText primary={t('Terms and Conditions')} style={{color: '#fff', opacity: '0.5'}} />
            </ListItemLink>
            <ListItemLink href='/privacy-and-policy' button className={classes.listitem}>
              <ListItemText primary={t('Privacy Policy')} style={{color: '#fff', opacity: '0.5'}} />
            </ListItemLink>
            <ListItem button className={classes.listitem}>
              <ListItemText primary='@eneo 2021' style={{color: '#7AA3F5'}} />
            </ListItem>
        </List>       
          </SwipeableDrawer>
        </React.Fragment>
  );
}
