import '../App.css';
import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react'
import Header from './header';
import Bottomnav from './bottomnav';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   root: {
       width: '100%',
       height: '100vh',
       backgroundColor: '#eeeeee'
   },
  }));

function Layout(props) {
  const classes = useStyles();
  const location = useLocation();
  return (
   <div className={classes.root}>
     <Bottomnav/>
      <Header/>
    {props.children}
 {/* {!location.pathname === 'propertydetails/:propertyid?' ? <Bottomnav/> : null}    */}
   </div>
   
  )
}

export default Layout;
