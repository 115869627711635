import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import rootReducer from './reducers';
import { persistStore, persistReducer } from "redux-persist";


let composeFn = compose;
if (process.env.NODE_ENV === "development") composeFn = composeWithDevTools;


const persistConfig = {
    key: "root",
    storage,
    blacklist: [],
  };
  let middleware = [thunk];
  let enhancers = [applyMiddleware(...middleware)];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, composeFn(...enhancers));

export const persistor = persistStore(store);

// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store

