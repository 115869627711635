import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, ButtonBase} from '@material-ui/core';
import Apartment from '../assets/Appartment.png';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: -100,
        marginBottom: -100
    },
    container: {
        paddingTop: 50,
        marginBottom: 100,
        marginTop: 100,
        backgroundColor: '#fff',
        paddingBottom: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
 
    },
headertwo: {
    color: '#2E7D32',
    fontSize: '20px',
    fontWeight: '700',
    paddingTop: 50,
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
   header: {
       color: '#1849AA',
       fontSize: '20px',
       fontWeight: '700',
       paddingBottom: 20
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#222222',
       paddingTop: 10,
       paddingBottom: 20
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
    width: '176px',
    height: '46px',
    borderRadius: '54px',
    color: '#fff',
    fontSize: 12,
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  },
  inputcontainer: {
      display: 'flex',
      flexDirection: 'column',
  },
  photo: {
    paddingTop: 50
  },
}));


export default function CustomSeparator() {
  const classes = useStyles();


  return (
      <>
      <div className={classes.root}>
    <Container className={classes.container}>
    <span className={classes.header}>Congratulation Adam!</span>
    <img src={Apartment} alt='building' className={classes.photo}/>
    <span className={classes.headertwo}>1 Month Subscription</span>
    <span className={classes.smallheader}>From May 18, 2021 to June 20, 2021</span>
    <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             BROWSE PROPERTIES
            </ButtonBase>
    </Container>      
      </div>
   
    </>
  );
}
