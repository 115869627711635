import '../../App.css';
import { makeStyles, Grid, Container } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import Dropzone from 'react-dropzone-uploader'
import {clearData, clearError, Editproperty} from '../../store/actions/appactions';
import Steps from './steps';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Deleteicon from '@material-ui/icons/DeleteOutline';
import Confirmmodal from './deletephotodialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';



const useStyles = makeStyles((theme) => ({
 container: {
     paddingTop: 50,
     marginBottom: 50
 },
 input: {
     marginBottom: 50
 },
 title: {
  margin: theme.spacing(0, 2),
},
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }



function Home() {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [filesupload, setFilesupload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [thumb, setThumb] = useState('');
    const [show, setShow] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const {t} = useTranslation();

    const getUploadParams = ({file, meta}) => {
      return { url: 'https://httpbin.org/post' }
    }

    const handleCloseerror = () => {
      setShowerror(false);
    }
  
    const handleChangeStatus = (data) => {
      const filename = data.meta
      if(filename.status === 'done'){
        setLoading(true);
        const currentfiles = filesupload.map(file => {return (file)});
      setFilesupload([...currentfiles, data])
      setLoading(false);
      }else{
        const currentfiles = filesupload.filter(file => file.meta.name !== filename.name);
      setFilesupload(currentfiles)
      }
    }

  
    const handleSubmit = () => {
      const media = filesupload.map(f => f.file);
      // if(filesupload.length < 3){
      //   setError({data: "Please upload atleast 3 photos"});
      //   setShow(true);
      // }else if(filesupload.length > 10){
      //   setError({data: "Please upload a maximum of 10 photos"});
      //   setShow(true)
      // } else if(filesupload.length >= 3 && filesupload.length <= 10){
        let propitems = app.propertyitems;
        const tages = app?.propertyitems?.tags?.map(tg => {
          return (
            tg.id
          )
        });
        propitems.tags = [tages]
        propitems.ward = app?.propertyitems?.ward?.id;
        propitems.street = app?.propertyitems?.street?.id;
        propitems.region = app?.propertyitems?.region?.id;
        propitems.district = app?.propertyitems?.district?.id;
         dispatch(Editproperty(propitems, media,  app.userdata.token, app.propertyeditdata.id))
      // }
    //  dispatch(Editproperty(app.propertyitems, media,  app.userdata.token, app.propertyeditdata.id))
    }

    // const handleNext = (e) => {
    //   e.preventDefault();
    // }

    const handleClose = () => {
      setOpen(false);
    }

    useEffect(() => {
      if(app.successdeletephoto){
          setOpen(false);
          setShow(true)
          const data = app?.propertyitems?.thumbnails?.filter(thu => thu !== thumb)
          dispatch(clearData(app.propertyitems, {thumbnails: data}));
          setTimeout(() => {
            dispatch(clearError());
            setShow(false);
          }, 5000)
      }
   }, [app.successdeletephoto])

 
   const handleClosesnack = () => {
     setShow(false);
   }

   useEffect(() => {
    if(app?.error?.type === 'createproperty'){
      setShowerror(true);
    }
}, [app?.error])

  return (
    <>
    <Confirmmodal id={app.propertyeditdata.id} open={open} handleClose={handleClose} photo={thumb}/>
        <div style={{width: '100%'}}>
       <Container className={classes.container} >
       <Snackbar open={showerror} autoHideDuration={6000} onClose={handleCloseerror} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert onClose={handleCloseerror} severity="error">
          {app?.error?.type === 'createproperty' ? app?.error?.data : null }
        </Alert>
      </Snackbar>
       <Snackbar open={show} autoHideDuration={6000} onClose={handleClosesnack} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert onClose={handleClosesnack} severity="success">
          {t("photo deleted successfully")}
        </Alert>
      </Snackbar>

      {app?.propertyitems?.thumbnails && app?.propertyitems?.thumbnails?.length > 0 ?  <Typography variant="h6" className={classes.title}>
            {t("Uploaded photos")}
          </Typography>  : null}
       <List dense className={classes.root}>
       {app?.propertyitems?.thumbnails ? app?.propertyitems?.thumbnails?.map((thumbnail) => {
         return (
          <ListItem button key={thumbnail}>
          <ListItemAvatar>
            <Avatar
              alt={`Avatar n`}
              src={`${thumbnail}`}
              style={{width: 100, height: 100, borderRadius: 20}}
            />
          </ListItemAvatar>
          <ListItemSecondaryAction>
            <Deleteicon onClick={() => {
              setThumb(thumbnail);
              setOpen(true);
            }}/>
          </ListItemSecondaryAction>
        </ListItem>
         )
       }) : null}
       </List>
           <form onSubmit={e => e.preventDefault()}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
            {t("Add new photos")}
          </Typography> 
              </Grid>
                <Grid item xs={12} md={12} lg={12}>
                <Dropzone
                 inputContent={t('Drag Photos or Click to Browse (Upload atleast 5 photos)')}
      getUploadParams={getUploadParams}
      maxFiles={10}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      SubmitButtonComponent={null}
      styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
    />
                </Grid>
            </Grid>
           </form>
       </Container>   
       <Container>
         <Steps disabled={app.loading} loading={app.loading} activeStep={app.steps} handleSubmit={handleSubmit}/>
      </Container>        
       </div>
    </>
  )
}

export default Home;
