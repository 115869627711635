import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, ButtonBase, Button, CircularProgress} from '@material-ui/core';
import Person from '@material-ui/icons/Apps';
import { useSelector } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const useStyles = makeStyles((theme) => ({
  container: {
      paddingTop: 20,
  },
  heading: {
      fontSize: 25,
      fontWeight: '700'
  },
  subheading: {
      fontSize: 16,
      fontWeight: '400'
  },
  largephoto: {
      height: 400,
  },
  largephototwo: {
    height: 400
},
  smallphoto: {
    height: 400
},
photo: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    [theme.breakpoints.down('md')]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
},
phototwo: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
},
photothree: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    borderTopRightRadius: 20,
    [theme.breakpoints.down('md')]: {
      borderTopRightRadius: 0,
    },
},
photofour: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    borderBottomRightRadius: 20,
    [theme.breakpoints.down('md')]: {
      borderBottomRightRadius: 0,
    },
},
smallpic: {
    height: 200
},
buttonthree: {
    width: '186px',
    height: '46px',
    borderRadius: '54px',
    border: '1px solid rgba(24, 73, 170, 0.4)',
    color: '#1849AA',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 50,
      marginBottom: 50
  },
  imagecontainer: {
    height: 500,
    [theme.breakpoints.down('md')]: {
      height: 300
    },
  },
  image: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover'
  }
}));

export default function CustomSeparator() {
  const classes = useStyles();
  const app = useSelector(state => state.app);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const images = app?.singleproperty?.thumbnails || [];
  return (
    <>
    {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
    <Container className={classes.container}>
       {app?.loadingproperties ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color="#556cd6"/>
      </div> : app?.error?.type === 'getsingleproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>An error occured, please try again later</span>
      </div> : app.singleproperty && !app?.error?.type !== 'getsingleproperty' && !app?.loadingproperties ? <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} onClickItem={(index) => {setPhotoIndex(index); setIsOpen(true)}}>
      {app?.singleproperty?.thumbnails.map(property => {
        return (
          
          <div className={classes.imagecontainer}>
              <img className={classes.image} src={property} />
          </div>
      
        )
      })}</Carousel> : null}
    </Container>
    </>
  );
}
