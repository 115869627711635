import '../App.css';
import { ButtonBase, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import Productcontainer from './singleproduct';
import Words from './headerwords';
import {useSelector, useDispatch} from 'react-redux';
import {getProperties} from '../store/actions/appactions';
import Skeleton from '@material-ui/lab/Skeleton';
import {Box} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {useHistory} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
   item: {
       width: '100%',
       height: 500,
       marginBottom: 100
   },
   button: {
    height: '46px',   
    background: '#1849AA',
    borderRadius: '94px',
    color: '#fff',
    paddingRight: 20,
    paddingLeft: 20,
   }
  }));

  
  

function Home(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const {t} = useTranslation()
    let history = useHistory();

   const options = {
        loop: true,
        dots: true,
        autoplay: true,
        margin: 10,      
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            600: {
                items: 1,
                nav: false
            },
            1000: {
                items: 3,
            },
        },
    }

    useEffect(() => {
        dispatch(getProperties());
    }, []);

    const handleClick = () => {
        history.push('properties?page=1');
    }

    const loadingcontent = ['one', 'two', 'three'];
  return (

      <div style={{width: '100%', paddingTop: 50, paddingBottom: 100}}>
          <Words header='Discover our featured listing' headermini='Don’t miss out the hottest place to stay or do business'/>
          {app?.loadingproperties ?  
          <OwlCarousel className='owl-theme' {...options} loop margin={20}>
               { app.loadingproperties ? 
               loadingcontent.map(load => {
                   return (
                     <Box pt={0.5} key={load}>
                     <Skeleton variant="rect" width='100%' height={300} />
                   <Skeleton />
                   <Skeleton width="60%" />
                   <Skeleton width="60%" />
                   <Skeleton width="100%" />
                 </Box>
                   )
               }) : null}</OwlCarousel>
                : app?.error?.type === 'getproperty' ? <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span style={{color: 'black'}}>{t("An error occured, please try again later")}</span>
      </div> : app.properties && !app?.error?.type !== 'getproperty' && !app?.loadingproperties ?
       <OwlCarousel className='owl-theme' {...options} loop margin={20}>
             {app.properties ? app.properties.map((property) => {
        return (
            <Productcontainer image={property?.thumbnails?.length > 0 ? property?.thumbnails[0] : null} address={property.street_address} name={app?.language === 'english' ? property.title : app?.language === 'kiswahili' ? property?.title_sw || property?.title : null} size={property.property_size} price={property.price_per_month} bathrooms={property.number_of_bathrooms} bedrooms={property.number_of_bedrooms} id={property.id} type_id={property.type_id} propertyid={property.id}/>
        )
    }) : null }</OwlCarousel>
: null}
    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50}}>
        <ButtonBase onClick={() => {
            handleClick()
        }} className={classes.button}>View all</ButtonBase>
    </div>
      </div>
   
   
  )
}

export default Home;
