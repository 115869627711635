import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, CircularProgress} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {back} from '../../store/actions/appactions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#fff'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));




export default function HorizontalLinearStepper({handleNext, activeStep, loading, handleSubmit, disabled}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
const {t} = useTranslation()
  const handleBack = () => {
      dispatch(back());
  }

  return (
      <div>
          <div>
            {/* <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography> */}
            <div>
              <Button disabled={app.steps === 0} onClick={handleBack} className={classes.button}>
                {t("Back")}
              </Button>
             {app.steps >= 3 ? null :  <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {t("Next")}
              </Button>}
              {app.steps === 3 ?  <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
                disabled={disabled}
              >
                {loading ? <CircularProgress color='#fff' size={20}/> : `${t("Submit")}`}
              </Button> : null}
            </div>
          </div>
          </div>
  );
}
