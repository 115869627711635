import '../../App.css';
import { makeStyles, Grid, Container, TextField, CircularProgress} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@material-ui/core/Box';
import {edit, Enteritem, getStreets} from '../../store/actions/appactions';
import Steps from './steps';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };



const useStyles = makeStyles((theme) => ({
 container: {
     paddingTop: 50,
 },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  

function Home({header, headermini}) {
    const classes = useStyles()
    const app = useSelector(state => state.app);
    const [value, setValue] = React.useState(0);
    const [title, setTitle] = useState(app?.propertyitems?.title || '');
    const [address, setAddress] = useState(app?.propertyitems?.street_address || '');
    const [geolocation, setGeolocation] = useState(app?.propertyitems?.geo_location || '');
    const [plotnumber, setplotnumber] = useState(app?.propertyitems?.plot_number || '');
    const [ward, setWard] = useState(app?.propertyitems?.ward || '');
    const [region, setRegion] = useState(app?.propertyitems?.region || '');
    const [district, setDistrict] = useState(app?.propertyitems?.district || '');
    const [village, setVillage] = useState(app?.propertyitems?.street || '');
    const [titlesw, setTitlesw] = useState(app?.propertyitems?.title_sw || '')
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const loaded = React.useRef(false);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const {t} = useTranslation();
     

    useEffect(() => {
      if(app?.propertyeditdata){
        const regionobject = app?.regions?.find(reg => reg.id === (app?.propertyitems?.region?.id || app?.propertyitems?.region ) ) || {};
        const districtobject = app?.districts?.find(dist => dist.id === (app?.propertyitems?.district?.id || app?.propertyitems?.district)) || {};
        const wardobject = app?.wards?.find(ward => ward.id === (app?.propertyitems?.ward?.id || app?.propertyitems?.ward)) || {};
        dispatch(getStreets('ward_id', app?.propertyitems?.ward?.id || app?.propertyitems?.ward));
        setRegion(regionobject);
        setDistrict(districtobject);
        setWard(wardobject);
      }
    }, [app.propertyeditdata])

    useEffect(() => {
       dispatch(edit());
    }, [])

    useEffect(() => {
       const villageobject = app?.villages?.find(village => village.id === (app?.propertyitems?.street?.id || app?.propertyitems?.street)) || {};
       setVillage(villageobject);
    }, [app?.villages]);

    useEffect(() => {
      if(ward){
        dispatch(getStreets('ward_id', ward.id));
      }
    }, [ward]);

    const handleNext = () => {
      setError(null);
      if(!title){
        setError({type: 'title', body: 'please enter valid title'})
      }else if(!titlesw){
        setError({type: 'titlesw', body: 'please enter valid Kiswahili title'})
      } else if(!region){
        setError({type: 'region', body: 'please enter valid region'})
      }  else if(!district){
        setError({type: 'district', body: 'please enter valid district'})
      } 
      // else if(!ward){
      //   setError({type: 'ward', body: 'please enter valid ward'})
      // } else if(!village){
      //   setError({type: 'village', body: 'please enter valid village'})
      // }
        else if(!geolocation){
        setError({type: 'geolocation', body: 'please enter valid geolocation'})
      }
      // else if(!plotnumber){
      //   setError({type: 'plotnumber', body: 'please enter valid plotnumber'})
      // } 
      else {
        const data = {
            title,
            title_sw: titlesw,
            street_address: address,
            geo_location: geolocation,
            plot_number: plotnumber,
            street: village,
            ward,
            region,
            district
        }
        dispatch(Enteritem(app.propertyitems, data));
      }
    }


    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
         'https://maps.googleapis.com/maps/api/js?key=AIzaSyDlpsDI3Prs8XZk1hEP0M5dOyBnlIiytLM&libraries=places',
         document.querySelector('head'),
         'google-maps',
        );
      }

      loaded.current = true;
    }

    const fetch = React.useMemo(
      () =>
        throttle((request, callback) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        }, 200),
      [],
    );

    React.useEffect(() => {
      let active = true;

      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }

      if (inputValue === '') {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }, [value, inputValue, fetch]);


    const dist = app?.districts?.filter(reg => reg.region_id === region?.id) || [];
    const wards = app?.wards?.filter(reg => reg.district_id === district?.id) || [];
    const villages = app?.villages?.filter(reg => reg.ward_id === ward?.id) || [];


  return (
    <div style={{width: '100%'}}>
    <Container className={classes.container} >
        <form>
         <Grid container spacing={2}>
             <Grid item xs={12} md={6} lg={4}>
             <TextField helperText='*appears below the property image on the property pages' error={error?.type === 'title'} helperText={error?.type === 'title' ? error?.body : null} value={title} onChange={(e) => setTitle(e.target.value)} id="title" label={t("Property Title")} variant="outlined" fullWidth className={classes.input} />
             </Grid>
             <Grid item xs={12} md={6} lg={4}>
                <TextField  error={error?.type === 'titlesw'} helperText={error?.type === 'titlesw' ? error?.body : null} value={titlesw} onChange={(e) => setTitlesw(e.target.value)} id="titlesw" helperText='appears below the property image on the property pages' label={t("Property Title (Kiswahili)")} variant="outlined" fullWidth className={classes.input} />
                </Grid>
             <Grid item xs={12} md={6} lg={4}>
             <Autocomplete
  id="regions"
  options={app?.regions || []}
  loading={app?.loading}
  getOptionLabel={(option) => option?.name}
  value={region}
  disabled={app?.loading}
  onChange={(e, newValue)  => {
    setRegion(newValue);
    setDistrict('');
    setWard('');
    setVillage('')
  }}
  renderInput={(params) => <TextField error={error?.type === 'region'} helperText={error?.type === 'region' ? error?.body : null} fullWidth {...params} InputProps={{
    ...params.InputProps,
    endAdornment: (
      <React.Fragment>
        {app?.loading ? <CircularProgress color="inherit" size={20} /> : null}
        {params.InputProps.endAdornment}
      </React.Fragment>
    ),
  }} label={t("Region")} variant="outlined" />}
/>
             </Grid>
             <Grid item xs={12} md={6} lg={4}>
             <Autocomplete
  id="districts"
  options={dist || []}
  loading={app?.loadingdistricts}
  getOptionLabel={(option) => option?.name}
  value={district}
  error={error?.type === 'district'} helperText={error?.type === 'district' ? error?.body : null}
  disabled={app?.loadingdistricts}
  onChange={(e, newValue)  => {
    setDistrict(newValue);
    setWard('');
    setVillage('')
  }}
  renderInput={(params) => <TextField fullWidth {...params} InputProps={{
    ...params.InputProps,
    endAdornment: (
      <React.Fragment>
        {app?.loadingdistricts ? <CircularProgress color="inherit" size={20} /> : null}
        {params.InputProps.endAdornment}
      </React.Fragment>
    ),
  }} label={t("District")} variant="outlined" />}
/>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
  id="Ward"
  options={wards || []}
  loading={app?.loadingwards}
  getOptionLabel={(option) => option?.name}
  value={ward}
  error={error?.type === 'ward'} helperText={error?.type === 'ward' ? error?.body : null}
  disabled={app?.loadingwards}
  onChange={(e, newValue)  => {
    setWard(newValue);
    setVillage('')
  }}
  renderInput={(params) => <TextField fullWidth {...params} InputProps={{
    ...params.InputProps,
    endAdornment: (
      <React.Fragment>
        {app?.loadingwards ? <CircularProgress color="inherit" size={20} /> : null}
        {params.InputProps.endAdornment}
      </React.Fragment>
    ),
  }} label={t("Ward")} variant="outlined" />}
/>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                <Autocomplete
  id="villages"
  options={villages || []}
  loading={app?.loadingvillages}
  getOptionLabel={(option) => option?.name}
  value={village}
  disabled={app?.loadingvillages}
  error={error?.type === 'village'} helperText={error?.type === 'village' ? error?.body : null}
  onChange={(e, newValue)  => {
    setVillage(newValue)
  }}
  renderInput={(params) => <TextField fullWidth {...params} InputProps={{
    ...params.InputProps,
    endAdornment: (
      <React.Fragment>
        {app?.loadingvillages ? <CircularProgress color="inherit" size={20} /> : null}
        {params.InputProps.endAdornment}
      </React.Fragment>
    ),
  }} label={t("Village")} variant="outlined" />}
/>
                </Grid>
             {/* <Grid item xs={12} md={6} lg={4}>
             <TextField error={error?.type === 'address'} helperText={error?.type === 'address' ? error?.body : null} value={address} onChange={(e) => setAddress(e.target.value)} id="address" label="Address" variant="outlined" fullWidth className={classes.input} />
             </Grid> */}
             <Grid item xs={12} md={6} lg={4}>            
                     <Autocomplete
      id="google-map-demo"
      // style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        console.log(newValue)
        geocodeByAddress(newValue?.description)
  .then(results => getLatLng(results[0]))
  .then(({ lat, lng }) =>
    setGeolocation(`${lat},${lng}`)
  );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField error={error?.type === 'geolocation'} helperText={error?.type === 'geolocation' ? error?.body : null} {...params} label={t("Add a location (search your location and choose)")} variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    /> 
                {/* <TextField error={error?.type === 'geolocation'} helperText={error?.type === 'geolocation' ? error?.body : null} value={geolocation} onChange={(e) => setGeolocation(e.target.value)} id="geolocation" label="Geolocation eg -34.397,150.644" variant="outlined" fullWidth className={classes.input} /> */}
                </Grid>
             <Grid item xs={12} md={6} lg={4}>
             <TextField error={error?.type === 'plotnumber'} helperText={error?.type === 'plotnumber' ? error?.body : null} value={plotnumber} onChange={(e) => setplotnumber(e.target.value)} id="plotnumber" label={t("Plot number")} variant="outlined" fullWidth className={classes.input} />
             </Grid>
         </Grid>
        </form>
    </Container>  
    <Container style={{marginTop: 50}}>
      <Steps handleNext={handleNext}/>
   </Container>     
    </div>
    
  )
}

export default Home;
