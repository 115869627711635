import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Dashboard';
import Building from '@material-ui/icons/HomeWork';
import People from '@material-ui/icons/PeopleOutline';
import {Grid} from '@material-ui/core';
import Logo from './logos/logo';
import Search from '@material-ui/icons/SearchOutlined';
import Notifications from '@material-ui/icons/NotificationsNone';
import Hidden from '@material-ui/core/Hidden';
import Header from './dashboardheader';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
      marginLeft: 0,
    },
    backgroundColor: '#fff'
  },
  appBartwo: {
    width: '100%',
    height: 100,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputcontainer: {
     flex: 1,
     height: 100,
     display: 'flex',
     alignItems: 'center',
     flexDirection: 'row',
     backgroundColor: '#fff'
  },
  inputinside: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    height: 34,
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: '#F6F6F6',
    overflow: 'hidden',
    border:'1px solid #EEEEEE'
  },
  input: {
flex: 9,
height: '100%',
border: '0 !important',
paddingLeft: 20,
outline: 'none'
  },
  iconcont: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1849AA',
    height: 34
  },
  iconscontainer: {
    flex: 1,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#fff',

  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
     display: 'none'
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1849AA'
  },
  // necessary for content to be below app bar
  toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 100
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#EEEEEE',
    minHeight: '100vh',
    // padding: theme.spacing(3),
  },
  listitem: {
    paddingLeft: 50
  },
  divider: {
    backgroundColor: '#5B87DE',
    position: 'relative',
    width: '80%',
    left: '20%',
  },
  photocont: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: '#eeeeee',
  },
  iconsinside: {
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function PermanentDrawerRight(props) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <>
    <div className={classes.root}>
      <CssBaseline />
      <Header/>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
            <Link to="/">
            <Logo/>
            </Link>
        </div>
        <Divider className={classes.divider}/>
        <List>
            <ListItemLink className={classes.listitem} href='/dashboard'>
              <ListItemIcon><MailIcon style={{color: '#fff'}}/></ListItemIcon>
              <ListItemText primary={t('Dashboard')} style={{color: '#fff'}} />
            </ListItemLink>
            <ListItemLink className={classes.listitem} href='/dashboard/property'>
              <ListItemIcon><Building style={{color: '#fff'}}/></ListItemIcon>
              <ListItemText primary={t('Property')} style={{color: '#fff'}} />
            </ListItemLink>
            {/* <ListItemLink button className={classes.listitem}>
              <ListItemIcon><People style={{color: '#fff'}}/></ListItemIcon>
              <ListItemText primary='Customers' style={{color: '#fff'}} />
            </ListItemLink> */}
        </List>
        <Divider className={classes.divider}/>
        <List>
            <ListItemLink href='/faq' button className={classes.listitem}>
              <ListItemText primary={t('FAQ')} style={{color: '#fff', opacity: '0.5'}} />
            </ListItemLink>
            <ListItemLink href='/terms-and-conditions' button className={classes.listitem}>
              <ListItemText primary={t('Terms and Conditions')} style={{color: '#fff', opacity: '0.5'}} />
            </ListItemLink>
            <ListItemLink href='/privacy-and-policy' button className={classes.listitem}>
              <ListItemText primary={t('Privacy Policy')} style={{color: '#fff', opacity: '0.5'}} />
            </ListItemLink>
            <ListItem button className={classes.listitem}>
              <ListItemText primary='@eneo 2021' style={{color: '#7AA3F5'}} />
            </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
    </>
  );
}
