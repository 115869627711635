import '../App.css';
import { useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import React from 'react'
import {useSelector} from 'react-redux';
import ModalVideo from 'react-modal-video';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
   root: {
    width: '100%', 
    height: 600, 
    backgroundColor: '#fff', 
    marginBottom: 100, 
    marginTop: 100,
    borderRadius:30, 
    paddingTop: 50, 
    paddingLeft: 40,
    paddingBottom: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundImage:
    "url('https://images.unsplash.com/photo-1502764613149-7f1d229e230f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80') ",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down('md')]: {
        height: 300, 
        paddingBottom: 30,
        paddingTop: 20, 
      },
   },
   heading: {
       color: '#fff',
       fontSize: 60,
       maxWidth: 100,
       [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },    
   },
   buttonheading: {
       backgroundColor: '#fff',
       height: 40,  
       paddingLeft: 20,
       paddingRight: 20,
       borderRadius: 10,
       
   }
  }));

  
  

function Home(props) {
    const classes = useStyles()
    const [isOpen, setOpen] = useState(false)
    const app = useSelector(state => state.app);
    const {t} = useTranslation();


    function extractVideoID(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[7].length == 11) {
        return match[7];
      } else {
        return null
      }
    }

    const videoid = app?.prices?.marketing_video ? extractVideoID(app?.prices?.marketing_video) : '';



  return (
    <>
    <div className={classes.root}>
         <h1 className={classes.heading}>{t("Questions about Eneo?")}</h1>
         <ButtonBase onClick={()=> setOpen(true)} className={classes.buttonheading}>{t("Watch This Video")}</ButtonBase>
      </div>
    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={videoid} onClose={() => setOpen(false)} />
    </>
  )
}

export default Home;
