export const SUCCESS_USERINFO = 'SUCCESS_USERINFO';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS_MODIFY = 'SUCCESS_MODIFY';
export const SUCCESS_REVIEW = 'SUCCESS_REVIEW';
export const CLEARSUCCESS = 'CLEARSUCCESS';
export const CONFIRMLOADING = 'CONFIRMLOADING';
export const SUCCESS_CONFIRM = 'SUCCESS_CONFIRM';
export const SUCCESSSIGNUP = 'SUCCESSSIGNUP';
export const CLEARSIGNUP = 'CLEARSIGNUP';
export const LOGOUT = 'LOGOUT';
export const SUCCESSRESET = 'SUCCESSRESET';
export const SUCCESSRESETPASSWORD = 'SUCCESSRESETPASSWORD';
export const CLEARERROR = 'CLEARERROR';
export const SUCCESSSIGNIN = 'SUCCESSSIGNIN';
export const SUCCESSCREATEPROPERTY = 'SUCCESSCREATEPROPERTY';
export const SUCCESSPARAMS = 'SUCCESSPARAMS';
export const SUCCESSTAGS = 'SUCCESSTAGS';
export const SUCCESSTYPES = 'SUCCESSTYPES';
export const SUCCESSLISTTYPES = 'SUCCESSLISTTYPES';
export const SUCCESSSTATUS = 'SUCCESSSTATUS';
export const PROPERTYITEMS = 'PROPERTYITEMS';
export const BACK = 'BACK';
export const SUCCESSGETPROPERTY = 'SUCCESSGETPROPERTY';
export const LOADINGPROPERTIES = 'LOADINGPROPERTIES';
export const SUCCESSGETSINGLEPROPERTY = 'SUCCESSGETSINGLEPROPERTY';
export const LOADINGUSERPROPERTIES = 'LOADINGUSERPROPERTIES';
export const SUCCESSGETUSERPROPERTY = 'SUCCESSGETUSERPROPERTY';
export const PROPERTYEDITDATA = 'PROPERTYEDITDATA';
export const SUCCESSREGIONS = 'SUCCESSREGIONS';
export const SUCCESSPLACES = 'SUCCESSPLACES';
export const SUCCESSGETREGIONPROPERTY = 'SUCCESSGETREGIONPROPERTY';
export const CLEARTOEDIT = 'CLEARTOEDIT';
export const CLEARPROP = 'CLEARPROP';
export const SUCCESSGETSLIDER = 'SUCCESSGETSLIDER';
export const LOADINGSLIDER = 'LOADINGSLIDER';
export const SUCCESSTERMS = 'SUCCESSTERMS';
export const SUCCESSABOUT = 'SUCCESSABOUT';
export const SUCCESSHOW = 'SUCCESSHOW';
export const SUCCESSTEAM = 'SUCCESSTEAM';
export const SUCCESSFAQ = 'SUCCESSFAQ';
export const SUCCESSPRIVACY = 'SUCCESSPRIVACY';
export const SUCCESSUNLIKE = 'SUCCESSUNLIKE';
export const SUCCESSLIKE = 'SUCCESSLIKE';
export const SUCCESSEDITPHOTO = 'SUCCESSEDITPHOTO';
export const LOADINGUPLOAD = 'LOADINGUPLOAD';
export const SUCCESSDASHBOARD = 'SUCCESSDASHBOARD';
export const LOADINGGOOGLE = 'LOADINGGOOGLE';
export const FILTERPROP = 'FILTERPROP';
export const SUCCESSEDITPROFILE = 'SUCCESSEDITPROFILE';
export const LOADINGEDIT = 'LOADINGEDIT';
export const SUCCESSPROFILE = 'SUCCESSPROFILE';
export const SUCCESSVILLAGES = 'SUCCESSVILLAGES';
export const FILTEREDVILLAGES = 'FILTEREDVILLAGES';
export const SUCCESSCATEGORIES = 'SUCCESSCATEGORIES';
export const SUCCESSAGENTS = 'SUCCESSAGENTS';
export const LANGUAGE = 'LANGUAGE';
export const SUCCESSDELETEPHOTO = 'SUCCESSDELETEPHOTO';
export const LOADINGDELETEPHOTO = 'LOADINGDELETEPHOTO'
export const CLEARDATA = 'CLEARDATA';
export const LOADINGDISTRICTS = 'LOADINGDISTRICTS';
export const LOADINGWARDS = 'LOADINGWARDS';
export const LOADINGVILLAGES = 'LOADINGVILLAGES';
export const SUCCESSDISTRICTS = 'SUCCESSDISTRICTS';
export const SUCCESSWARDS = 'SUCCESSWARDS';
export const SUCCESSVILLAGEDATA = 'SUCCESSVILLAGEDATA';
export const SUCCESSWHY = 'SUCCESSWHY';
export const SUCCESSEDITPROPERTY = 'SUCCESSEDITPROPERTY';
export const CLEARSTEPS = 'CLEARSTEPS';
export const SUCCESSPAGINATION = 'SUCCESSPAGINATION';
export const LOADINGPAGE = 'LOADINGPAGE';
export const SUCCESSPRICES = 'SUCCESSPRICES';