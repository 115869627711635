import React from "react";
import SocialLogin from "react-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";

function SocialButton({children, triggerLogin}) {
    return (
        <FacebookLoginButton onClick={triggerLogin} >
            {children}
        </FacebookLoginButton>
    );
}

export default SocialLogin(SocialButton);