import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, ButtonBase, Divider} from '@material-ui/core';
import Check from '@material-ui/icons/CheckCircleOutline';
import Checkfilled from '@material-ui/icons/CheckCircle';
import Person from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginTop: 50,
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
        },
      },
      inputtwo: {
        width: '100%',
        marginTop: 150,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
   container: {
       paddingTop: 50,
       marginBottom: 100,
       marginTop: 100,
       backgroundColor: '#fff',
       borderRadius: 30,
       paddingLeft: 50,
       paddingBottom: 50,

   },
   griditem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
   },
   choosecontainer: {
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       paddingTop: 20,
   },
headertwo: {
    color: '#222222',
    fontSize: '14px',
    fontWeight: '200',
    marginLeft: 10
},
bigheadertwo: {
    color: '#fff',
    fontSize: '64px',
    fontWeight: '700'
},
   header: {
       color: '#222222',
       fontSize: '20px',
       fontWeight: '700'
   },
   bigheader: {
       color: '#222222',
       fontSize: '64px',
       fontWeight: '700'
   },
   smallheader: {
       fontSize: 12,
       color: '#AEB6BB',
       paddingTop: 10
   },
   buttontwo: {
    width: '130px',
    marginTop: 80,
    height: '46px',
    borderRadius: '54px',
    backgroundColor: '#F4F4F4',
    color: '#1849AA',
    fontWeight: '700',
    "&:hover": {
      backgroundColor: '#F4F4F4',
    },
  },
  buttonthree: {
    width: '136px',
    height: '46px',
    borderRadius: '54px',
    color: '#fff',
    backgroundColor: '#1849AA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    "&:hover": {
      backgroundColor: '#1849AA',
    }
  },
  button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 50
  },
  inputcontainer: {
      display: 'flex',
      flexDirection: 'column',
  }
}));


export default function CustomSeparator() {
  const classes = useStyles();


  return (
      <>
    <Container className={classes.container}>
      <Grid container spacing={4}>
      <Grid item xs={5} className={classes.griditem}>
      <span className={classes.header}>Select Network Operator</span>
     <div className={classes.choosecontainer}> <Check style={{color: '#1849AA'}} fontSize="large"/><span className={classes.headertwo}>Mpesa - Vodacom</span></div>
     <div className={classes.choosecontainer}> <Check style={{color: '#1849AA'}} fontSize="large"/><span className={classes.headertwo}>Airtel money</span></div>
     <div className={classes.choosecontainer}> <Checkfilled style={{color: '#1849AA'}} fontSize="large"/><span className={classes.headertwo}>Tigopesa</span></div>
     <div className={classes.choosecontainer}> <Check style={{color: '#1849AA'}} fontSize="large"/><span className={classes.headertwo}>Halopesa</span></div>
      </Grid>
      <Grid item xs={1} style={{minHeight: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Divider orientation="vertical" flexItem  style={{height: '100%', backgroundColor: 'rgba(24, 73, 170, 0.2)'}}/>
      </Grid>
      <Grid item xs={5}>
          <Grid container>
          <Grid item xs={4}/>
              <Grid item xs={8} className={classes.inputcontainer}>
              <span className={classes.header}>Phone number</span>
              <span className={classes.smallheader}>1 Month Subscription to eneo</span>
              <span className={classes.smallheader}>Amount: TZS 10,000</span>
             <div className={classes.input}>
             <TextField fullWidth label="Phone number" id="filled-size-normal" defaultValue="+255" variant="outlined" />
             </div>
             <div className={classes.inputtwo}>
             <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             PAY NOW
            </ButtonBase>
             </div>
              </Grid>
          </Grid>
      </Grid>
      </Grid>
    </Container>
    <Container>
        <Grid container>
          <Grid item xs={12} className={classes.button}>
          <ButtonBase elevation={0} variant="contained" color="secondary" className={classes.buttonthree}>
             Continue <Person fontSize='small'/>
            </ButtonBase>
          </Grid>   
        </Grid>
    </Container>
    </>
  );
}
