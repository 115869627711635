import React from "react";

const Logoicon = () => {
  return (
    <svg width="154" height="64" viewBox="0 0 154 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.5603 20.5812C41.5603 22.0152 41.417 23.4468 41.1303 24.851C40.4904 27.9572 39.1438 30.8724 37.2017 33.3708L37.083 33.5222C36.0206 34.8694 34.8 36.0826 33.4435 37.132H33.357L33.2161 37.2288C26.2557 41.913 16.8318 40.0423 12.1693 33.0533C10.5583 30.6392 9.66878 27.8109 9.60454 24.9031C9.60454 24.8262 9.60454 24.7617 9.60454 24.6873C9.56253 21.4049 10.5781 18.1969 12.5004 15.5422C14.4301 13.0017 17.0468 11.0739 20.0365 9.98477L20.758 9.65976L24.2247 11.0541L30.6835 14.2968C31.7979 14.7136 32.3662 15.9566 31.9535 17.0755C31.6051 18.0133 30.6613 18.5914 29.6729 18.4698H28.9835V24.9552H22.9126C21.7661 24.9279 20.842 24 20.8124 22.8464C20.8519 21.7026 21.7736 20.7871 22.9126 20.7598H24.7855V18.4128V16.0558L22.5469 14.9741L20.7605 14.1951C16.5032 15.8896 13.7309 20.0502 13.7951 24.6501C13.7951 24.727 13.7951 24.7915 13.7951 24.8659C13.9187 30.9494 18.9296 35.7799 24.9882 35.6558C27.1032 35.6112 29.1615 34.9562 30.9133 33.7653C31.9362 32.9639 32.8653 32.046 33.6807 31.0312L33.777 30.9121C35.1953 29.1407 36.2331 27.0939 36.8236 24.9006C39.3118 16.0459 34.1773 6.84132 25.3588 4.34542C16.5403 1.84952 7.37334 7.00259 4.88765 15.8573C4.74187 16.3783 4.6208 16.9093 4.52443 17.4427V17.5395C4.10191 19.8542 4.10191 22.2286 4.52443 24.5459V24.6327V24.9031C5.39665 31.3885 8.72243 43.9623 20.7605 57.6054L20.8247 57.6823C21.697 56.7197 22.6112 55.6503 23.4834 54.591C24.2271 53.7375 25.4996 53.606 26.4015 54.2883C27.2935 55.0375 27.4318 56.3649 26.7128 57.2829C25.4848 58.764 24.1827 60.2675 23.0189 61.5105L22.9546 61.575L20.8025 63.5524L20.674 63.6715L19.273 62.1705C4.97413 46.8725 1.25053 32.4727 0.301719 25.186V24.9056C-0.143037 22.1517 -0.0960907 19.3407 0.442558 16.6016C2.78988 5.32294 13.8001 -1.9117 25.0351 0.445267C34.5578 2.44497 41.4244 10.8109 41.5603 20.5812Z" fill="white"/>
    <path d="M59.2261 37.933C61.3807 39.63 64.3581 39.7987 66.6905 38.3573C67.4763 37.8908 68.0792 37.1664 68.4029 36.3104H74.0216C73.394 38.7244 71.9436 40.8432 69.9249 42.2996C67.8963 43.5475 65.549 44.1728 63.172 44.0958C61.482 44.1182 59.8042 43.8155 58.2278 43.2076C56.8293 42.6569 55.5692 41.8083 54.5339 40.7167C53.4912 39.6027 52.6881 38.2878 52.1668 36.8538C51.5812 35.2907 51.2748 33.6359 51.2625 31.9662C51.2452 30.3039 51.5318 28.654 52.11 27.0984C53.1798 24.1063 55.458 21.7097 58.381 20.4915C59.908 19.8464 61.5536 19.5239 63.2091 19.5437C65.0029 19.504 66.7795 19.901 68.3831 20.7024C69.7544 21.4566 70.9404 22.5135 71.8472 23.7937C72.8059 25.1235 73.4978 26.6245 73.8857 28.2174C74.3058 29.9144 74.4565 31.6684 74.328 33.4126H57.6472C57.578 35.0674 58.1438 36.6851 59.2261 37.933ZM66.441 25.647C65.5144 24.7439 64.2444 24.2874 62.9595 24.3916C62.0849 24.3619 61.2126 24.5405 60.4195 24.9126C59.7944 25.2228 59.2434 25.6644 58.8035 26.2077C58.4181 26.689 58.1315 27.2398 57.956 27.8303C57.8053 28.3265 57.7089 28.8401 57.6669 29.3561H67.9976C67.8296 27.999 67.291 26.7163 66.441 25.647Z" fill="white"/>
    <path d="M84.2564 20.1793V23.4244H84.3922C85.1236 22.1442 86.2232 21.1146 87.5476 20.4695C88.783 19.8642 90.137 19.5466 91.5108 19.5416C92.9464 19.492 94.3795 19.7277 95.7237 20.2363C96.7367 20.6432 97.6262 21.3081 98.3008 22.169C98.963 23.0672 99.4151 24.1042 99.6276 25.2008C99.8871 26.4711 100.018 27.7662 100.013 29.0638V44.0962H93.6061V30.339C93.7025 28.8479 93.3887 27.3568 92.7018 26.032C91.9704 25.0148 90.7523 24.4689 89.5069 24.6029C88.0442 24.4516 86.6037 25.0371 85.6598 26.1684C84.7505 27.7116 84.3404 29.5004 84.4861 31.2868V44.1012H78.1953V20.1793H84.2564Z" fill="white"/>
    <path d="M111.69 37.9311C113.845 39.6281 116.822 39.7968 119.154 38.3553C119.94 37.8889 120.543 37.1644 120.867 36.3085H126.486C125.851 38.7349 124.388 40.8636 122.349 42.3175C120.321 43.5654 117.973 44.1907 115.596 44.1137C113.906 44.1361 112.229 43.8334 110.652 43.2255C109.239 42.6748 107.964 41.8188 106.919 40.7148C105.876 39.6008 105.073 38.2858 104.552 36.8518C103.993 35.2838 103.712 33.629 103.724 31.9642C103.687 30.2722 103.969 28.5876 104.552 26.9997C105.624 24.0647 107.86 21.7077 110.726 20.4895C112.253 19.8445 113.899 19.5219 115.554 19.5418C117.348 19.5021 119.125 19.899 120.728 20.7004C122.157 21.4348 123.394 22.4917 124.346 23.7917C125.304 25.1216 125.996 26.6226 126.384 28.2154C126.804 29.9124 126.955 31.6665 126.827 33.4106H110.109C110.042 35.0655 110.608 36.6831 111.69 37.9311ZM118.905 25.6451C117.978 24.742 116.708 24.2855 115.423 24.3897C114.549 24.3599 113.677 24.5385 112.883 24.9107C112.258 25.2208 111.707 25.6624 111.267 26.2058C110.882 26.6871 110.595 27.2379 110.42 27.8284C110.269 28.3246 110.173 28.8381 110.131 29.3542H120.521C120.336 27.9921 119.775 26.7069 118.905 25.6451Z" fill="white"/>
    <path d="M130.797 26.7872C131.833 23.8372 134.103 21.4927 137.012 20.3737C138.588 19.7634 140.266 19.4632 141.956 19.4855C143.638 19.4706 145.309 19.7709 146.88 20.3737C149.791 21.4753 152.069 23.8075 153.114 26.7475C153.729 28.4147 154.028 30.1837 153.999 31.9626C154.026 33.6869 153.739 35.4037 153.151 37.0238C152.623 38.4678 151.797 39.7852 150.727 40.8868C149.638 41.9759 148.326 42.812 146.88 43.3405C145.291 43.9285 143.609 44.2163 141.916 44.1915C140.231 44.2188 138.553 43.931 136.972 43.3405C135.527 42.812 134.215 41.9759 133.125 40.8868C132.055 39.7852 131.23 38.4678 130.701 37.0238C130.145 35.3963 129.891 33.6819 129.95 31.9626C129.913 30.201 130.199 28.4445 130.797 26.7872ZM136.569 34.513C136.732 35.3591 137.046 36.1704 137.493 36.9072C137.921 37.6094 138.516 38.1949 139.225 38.6067C140.051 39.0558 140.98 39.2766 141.919 39.2443C142.863 39.2741 143.799 39.0533 144.632 38.6067C145.351 38.1998 145.954 37.6143 146.384 36.9072C146.831 36.1704 147.142 35.3591 147.308 34.513C147.491 33.6745 147.592 32.821 147.617 31.9626C147.619 31.0347 147.528 30.1092 147.347 29.2012C147.189 28.3527 146.875 27.5414 146.423 26.807C145.983 26.1074 145.383 25.5243 144.671 25.1075C143.841 24.6485 142.905 24.4203 141.958 24.45C140.164 24.3632 138.467 25.2663 137.533 26.807C137.081 27.5414 136.769 28.3527 136.609 29.2012C136.428 30.1117 136.337 31.0371 136.339 31.9626C136.344 32.8508 136.433 33.739 136.609 34.6098L136.569 34.513Z" fill="white"/>
    </svg>
  );
};

export default Logoicon;
