import '../App.css';
import {ButtonBase, Divider, makeStyles } from '@material-ui/core';
import React from 'react'
import Sofa from '@material-ui/icons/Weekend';
import Shower from '@material-ui/icons/Bathtub';
import Car from '@material-ui/icons/DriveEta';
import Measurement from '@material-ui/icons/BorderStyle';
import Phone from '@material-ui/icons/Phone';


const useStyles = makeStyles((theme) => ({
   productcontainer: {
       width: 400,
       height: 250,
       borderRadius: 25,
       display: 'flex',
       flexDirection: 'column',
       position: 'relative',
       background: 'rgba(255, 255, 255, 0.65)',
       backdropFilter: 'blur(24px)',
       [theme.breakpoints.down('md')]: {
        width: '80%',
        height: 180,
        borderRadius: 15
      },
   },
   photo: {
       width: '100%',
       height: '100%',
       display: 'block',
       objectFit: 'cover',
       top: 0,
       left: 0,
       zIndex: 1,
       position: 'absolute'
   },
   photowords: {
       width: '100%',
       height: '100%',
       top: 0,
       left: 0,
       zIndex: 9,
       position: 'absolute',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'space-between',
       flexDirection: 'column',
       backgroundColor: 'rgba(0,0,0,0.5)',
   },
   photocontainer: {
       flex: 1,
       width: '100%',
       position: 'relative',
   },
   wordscontainer: {
       flex: 1,
       width: '100%',
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'space-evenly'
   },
   featured: {
       height: 30,
       backgroundColor: '#1849AA',
       paddingLeft: 10,
       paddingRight: 10,
       borderRadius: 15,
       color: '#fff'
   },
   forrent: {
    height: 30,
    backgroundColor: '#222222',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15,
    color: '#fff'
   },
   like: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff'
   },
   liketwo: {
    height: 30,
    width: 30,
    backgroundColor: '#1849AA',
    borderRadius: 15,
    color: '#fff'
   },
   photowordsinside: {
       width: '100%',
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       justifyContent: 'space-between',
       paddingLeft: 10,
       paddingRight: 10,
       paddingTop: 20
   },
   photowordsinsidetwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20
},
wordsone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
},
spanone: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
},
spantwo: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    
},
spanthree: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
},
spanfour: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
},
wordstwo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
},
wordsthree: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20
},
buttonone: {
    position: 'absolute',
    top: -10,
    left: 20,
    height: 20,
    borderRadius: 10,
    width: 100,
    backgroundColor: '#1849AA',
    zIndex: 99,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
},
buttontwo: {
    position: 'absolute',
    top: -10,
    left: 20,
    height: 20,
    borderRadius: 10,
    width: 100,
    backgroundColor: '#222222',
    zIndex: 99,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
},
buttontextone: {
    fontSize: 12,
    color: '#fff',
},
buttontexttwo: {
    fontSize: 12,
    color: '#fff'
}
  }));

  
  

function Productcontainer({image, property}) {
    const classes = useStyles()

  return (
      <div className={classes.productcontainer}>
          {/* <div className={classes.buttonone}>
              <span className={classes.buttontextone}>FEATURED</span>
          </div> */}
          <div className={classes.buttontwo}>
              <span className={classes.buttontexttwo}>{property?.type_id === 1 ? "FOR RENT" : property?.type_id === 2 ? "FOR SELL" : property?.type_id === 3 ? "PLOT" : null}</span>
          </div>
          <div className={classes.wordscontainer}>
             <div className={classes.wordsone}>
                 <span className={classes.spanone}>{property?.title}</span>
                 <span className={classes.spantwo}>{property?.street_address}</span>
             </div>
             <div className={classes.wordstwo}>
            {property?.type_id === 1 ?  <span className={classes.spanthree}>TZS { property?.rent_prices && new Intl.NumberFormat().format(property?.rent_prices[0]?.price)  || ''} / <span className={classes.spanfour}>1 month</span></span> :  <span className={classes.spanthree}>TZS {property.sell_price}</span>}
                 {/* <span className={classes.spanthree}>Installments / <span className={classes.spanfour}>6 months</span></span> */}
             </div>
             <div className={classes.wordsthree}>
                 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 2, justifyContent: 'space-evenly'}}><Sofa style={{color: '#1849AA'}}/> {property?.number_of_bedrooms}<Divider orientation="vertical" flexItem /><Shower style={{color: '#1849AA'}}/> {property?.number_of_bathrooms}<Divider orientation="vertical" flexItem /><Car style={{color: '#1849AA'}}/><Divider orientation="vertical" flexItem /><Measurement style={{color: '#1849AA'}}/> <span>{property?.property_size}SqFt</span></div>
                 <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-end'}}>  <ButtonBase className={classes.liketwo}><Phone style={{fontSize: 16}}/></ButtonBase> </div>
             </div>
          </div>
      </div>
   
   
  )
}

export default Productcontainer;
