import * as types from '../types';
import Eneo from '../../api/eneo';



export const Signin = (data) => async dispatch => {

  try {
    dispatch({type: types.LOADING, payload: true})
    const response = await Eneo.post('/auth/login', data);
    localStorage.setItem("token", response.data.token);
    dispatch({type: types.SUCCESSSIGNIN, payload: response.data})
  }catch (err) {
    console.log(err.response.data);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'signin',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Signinsocial = (data) => async dispatch => {
  try {
    dispatch({type: types.LOADINGGOOGLE, payload: true})
    const response = await Eneo.post('/auth/social', data);
    localStorage.setItem("token", response.data.token);
    dispatch({type: types.SUCCESSSIGNIN, payload: response.data})
  }catch (err) {
    console.log(err?.response?.data || 'something went wrong');
    dispatch({type: types.LOADINGGOOGLE, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'signinsocial',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

          export const clearSignup = () => async dispatch => {
            dispatch({type: types.CLEARSIGNUP});
          }

          export const clearError = () => async dispatch => {
            dispatch({type: types.CLEARERROR});
          }

          export const logout = () => async dispatch => {
            dispatch({type: types.LOGOUT});
          }

export const Signup = (data) => async dispatch => {
  try {
    dispatch({type: types.LOADING, payload: true})
    const response = await Eneo.post('/auth/register', data);
    localStorage.setItem("token", response.data.token);
    dispatch({type: types.SUCCESSSIGNUP, payload: response.data})
  }catch (err) {
    console.log(err.response.data);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'signup',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Resetpassword = (data) => async dispatch => {
  try {
    dispatch({type: types.LOADING, payload: true})
    const response = await Eneo.post('/auth/forgot-password', data);
    console.log(response.data);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.SUCCESSRESET, payload: response})
  }catch (err) {
    console.log(err.response.data);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'forgotpassword',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Reset = (data, token) => async dispatch => {
  try {
    dispatch({type: types.LOADING, payload: true})
    const response = await Eneo.post(`/auth/reset-password/${token}`, data);
    console.log(response.data);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.SUCCESSRESETPASSWORD, payload: response})
  }catch (err) {
    console.log(err.response.data);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'resetpassword',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Createproperty = (data, images, token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
};
  try {
    dispatch({type: types.LOADING,  payload: true})
function getFormData(object, image) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  for (var key in image) {
   formData.append('thumbnails[]', image[key])
  }
  return formData;
}


    const response = await Eneo.post('/properties/create', getFormData(data, images), config);

    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.SUCCESSCREATEPROPERTY, payload: response.data})
  }catch (err) {
    console.log(err?.response?.data || 'something went wrong');
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'createproperty',
      data: err?.response?.data?.error_message || 'something went wrong, try again later',
    }});
  }
}

export const Editproperty = (data, images, token, propertyid) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
};
  try {
    dispatch({type: types.LOADING,  payload: true})

function getFormData(object, image) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
      for (var key in image) {
        console.log(images[key].File);
      formData.append('thumbnails[]', images[key])
      }
      return formData;
    }
    const response = await Eneo.post(`/properties/${propertyid}/edit`, getFormData(data, images), config);
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.SUCCESSEDITPROPERTY, payload: response.data})
  }catch (err) {
    console.log(err?.response?.data || 'something went wrong');
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'createproperty',
      data: err?.response?.data?.error_message || 'something went wrong, try again later',
    }});
  }
}

export const getProperties = () =>  async dispatch => {
  try {
    dispatch({type: types.LOADINGPROPERTIES,  payload: true})
    const response = await Eneo.get('/properties');
    dispatch({type: types.SUCCESSGETPROPERTY, payload: response.data.data})
    dispatch({type: types.LOADINGPROPERTIES,  payload: false})
  }catch (err) {
    console.log(err?.response?.data || 'SOMETHING WENT WRONG');
    dispatch({type: types.LOADINGPROPERTIES, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'getproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getSlider = () =>  async dispatch => {
  try {
    dispatch({type: types.LOADINGSLIDER,  payload: true})
    const response = await Eneo.get('/slider');
    dispatch({type: types.SUCCESSGETSLIDER, payload: response.data})
    dispatch({type: types.LOADINGSLIDER,  payload: false})
  }catch (err) {
    console.log(err?.response?.data || 'SOMETHING WENT WRONG');
    dispatch({type: types.LOADINGSLIDER, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'getSLIDER',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getRegionProperties = (path) =>  async dispatch => {
  try {
    dispatch({type: types.LOADINGPROPERTIES,  payload: true})
    const response = await Eneo.get(`/properties${path}`);
    dispatch({type: types.SUCCESSGETREGIONPROPERTY, payload: response.data.data})
    dispatch({type: types.LOADINGPROPERTIES,  payload: false})
  }catch (err) {
    console.log(err?.response?.data || 'SOMETHING WENT WRONG');
    dispatch({type: types.LOADINGPROPERTIES, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'getregionproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getPagination = (path) =>  async dispatch => {
  try {
    dispatch({type: types.LOADINGPAGE,  payload: true})
    const response = await Eneo.get(`/properties${path}`);
    dispatch({type: types.SUCCESSPAGINATION, payload: response.data.data})
    dispatch({type: types.LOADINGPAGE,  payload: false})
  }catch (err) {
    console.log(err?.response?.data || 'SOMETHING WENT WRONG');
    dispatch({type: types.LOADINGPAGE, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'getregionproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getUserProperties = (token) =>  async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};
  try {
    dispatch({type: types.LOADINGUSERPROPERTIES,  payload: true})
    const response = await Eneo.get(`/profile/properties`, config);
    dispatch({type: types.SUCCESSGETUSERPROPERTY, payload: response.data.data})
    dispatch({type: types.LOADINGUSERPROPERTIES,  payload: false})
  }catch (err) {
    console.log(err?.response?.data || 'SOMETHING WENT WRONG');
    dispatch({type: types.LOADINGUSERPROPERTIES, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'getuserproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getSingleProperty = (propertyid) =>  async dispatch => {
  try {
    dispatch({type: types.LOADINGPROPERTIES,  payload: true})
    const response = await Eneo.get(`/properties/${propertyid}`);
    console.log(response.data);
    dispatch({type: types.SUCCESSGETSINGLEPROPERTY, payload: response.data.property})
    dispatch({type: types.LOADINGPROPERTIES,  payload: false})
  }catch (err) {
    console.log(err?.response?.data || 'SOMETHING WENT WRONG');
    dispatch({type: types.LOADINGPROPERTIES, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'getsingleproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getTags = () => async dispatch => {
  try{
     const tags = await Eneo.get('/properties/tags');
  await dispatch({type: types.SUCCESSTAGS, payload: tags.data.tags })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchtags',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getPrices = () => async dispatch => {
  try{
     const tags = await Eneo.get('/configurations');
  await dispatch({type: types.SUCCESSPRICES, payload: tags.data })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchprices',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getRegions = () => async dispatch => {
  try{
    dispatch({type: types.LOADING, payload: true})
     const tags = await Eneo.get('/properties/regions');
  await dispatch({type: types.SUCCESSREGIONS, payload: tags.data.items })
  }catch(err){
    console.log(err?.response?.data || err)
    dispatch({type: types.LOADING, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'fetchregions',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getDistricts = () => async dispatch => {
  try{
    dispatch({type: types.LOADINGDISTRICTS, payload: true})
     const tags = await Eneo.get('/properties/districts');
  await dispatch({type: types.SUCCESSDISTRICTS, payload: tags.data.items })
  }catch(err){
    console.log(err?.response?.data || err)
    dispatch({type: types.LOADINGDISTRICTS, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'fetchdistricts',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getWards = () => async dispatch => {
  try{
    dispatch({type: types.LOADINGWARDS, payload: true})
     const tags = await Eneo.get('/properties/wards');
     console.log(tags)
  await dispatch({type: types.SUCCESSWARDS, payload: tags.data.items })
  }catch(err){
    console.log(err?.response?.data || err)
    dispatch({type: types.LOADINGWARDS, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'fetchwards',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getStreets = (id, data) => async dispatch => {
  try{
    dispatch({type: types.LOADINGVILLAGES, payload: true})
     const tags = await Eneo.get(`/properties/streets?${id}=${data}`);
  if(id === 'ward_id'){
    await dispatch({type: types.SUCCESSVILLAGES, payload: tags.data.items })
  }else{
    await dispatch({type: types.SUCCESSVILLAGEDATA, payload: tags.data.items })
  }
  }catch(err){
    console.log(err?.response?.data || err)
    dispatch({type: types.LOADINGVILLAGES, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'fetchvillages',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getCategories = () => async dispatch => {
  try{
     const tags = await Eneo.get('/properties/property_categories');
  await dispatch({type: types.SUCCESSCATEGORIES, payload: tags.data.categories })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchcategories',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getVillages = (keyword) => async dispatch => {
  dispatch({type: types.LOADINGVILLAGES,  payload: true})
  try{
     const tags = await Eneo.get(`/properties/streets?q=${keyword}`);
     console.log(tags);
  await dispatch({type: types.SUCCESSVILLAGES, payload: tags.data.items })
  }catch(err){
    dispatch({type: types.LOADINGVILLAGES,  payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'fetchvillages',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const filteredvillages = (data) => async dispatch => {
   dispatch({type: types.FILTEREDVILLAGES, payload: data})
}

export const getNearbyPlaces = () => async dispatch => {
  try{
    const tags = await Eneo.get('/properties/near_by_places');
    await dispatch({type: types.SUCCESSPLACES, payload: tags.data.items })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
        type: 'fetchplaces',
        data: err?.response?.data || 'something went wrong, try again later',
      }});
  }
}

export const getStatus = () => async dispatch => {
  try{
  const status = await Eneo.get('/properties/property_statuses');
  await dispatch({type: types.SUCCESSSTATUS, payload: status.data })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchstatus',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getTypes = () => async dispatch => {
  try{
  const listtype = await Eneo.get('/properties/types');
  await dispatch({type: types.SUCCESSTYPES, payload: listtype.data })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchtypes',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getListtypes = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/properties/list_types');
  await dispatch({type: types.SUCCESSLISTTYPES, payload: listtypes.data.types })
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchlisttypes',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getTerms = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/terms-of-service');
  await dispatch({type: types.SUCCESSTERMS, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchterms',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Enteritem = (partialitems, filled) => dispatch => {
  let items = {};
  items = {...partialitems, ...filled}
  dispatch({type: types.PROPERTYITEMS, payload: items});
}

export const back = () => dispatch => {
  dispatch({type: types.BACK})
}

export const clearsteps = () => dispatch => {
  dispatch({type: types.CLEARSTEPS})
}


export const clearData = (partialitems, filled) => dispatch => {
  let items = {};
  items = {...partialitems, ...filled}
  dispatch({type: types.CLEARDATA, payload: items})
}

export const propertyedit = (data, id) => dispatch => {
  dispatch({type: types.PROPERTYEDITDATA, payload: {data, id}})
}

export const edit = () => dispatch => {
  dispatch({type: types.CLEARTOEDIT})
}

export const clearProp = () => dispatch => {
  dispatch({type: types.CLEARPROP})
}

export const filterprop = (data) => dispatch => {
  dispatch({type: types.FILTERPROP, payload: data})
}

export const getWorks = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/how-it-works');
  await dispatch({type: types.SUCCESSHOW, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchhow',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getWhy = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/why-eneo');
  await dispatch({type: types.SUCCESSWHY, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchwhy',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getAgents = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/how-to-be-an-agent');
  await dispatch({type: types.SUCCESSAGENTS, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchagents',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const language = (lang) => dispatch => {
  dispatch({type: types.LANGUAGE, payload: lang})
}


export const getAbout = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/about-us');
  await dispatch({type: types.SUCCESSABOUT, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchabout',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getTeam = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/our-team');
  await dispatch({type: types.SUCCESSTEAM, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchteam',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getFaq = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/faq');
  await dispatch({type: types.SUCCESSFAQ, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchfaq',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getPrivacy = () => async dispatch => {
  try{
  const listtypes = await Eneo.get('/pages/privacy-policy');
  await dispatch({type: types.SUCCESSPRIVACY, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchprivacy',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Like = (property_id, token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`}
};
  try{
  const listtypes = await Eneo.post(`/properties/${property_id}/like`, null, config);
  await dispatch({type: types.SUCCESSLIKE, payload: listtypes.data})
  }catch(err){
    console.log(err);
    dispatch({type: types.ERROR, payload: {
      type: 'likeproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const Unlike = (property_id, token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`}
};
  try{
  const listtypes = await Eneo.post(`/properties/${property_id}/unlike`, null, config);
  await dispatch({type: types.SUCCESSUNLIKE, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'likeproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getStats = (token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`}
};
  try{
  const listtypes = await Eneo.get('/profile/dashboard', config);
  await dispatch({type: types.SUCCESSDASHBOARD, payload: listtypes.data})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchSTATS',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const getProfile = (token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`}
};
  try{
  const listtypes = await Eneo.get('/profile', config);
  await dispatch({type: types.SUCCESSPROFILE, payload: listtypes.data.profile})
  }catch(err){
    dispatch({type: types.ERROR, payload: {
      type: 'fetchprofile',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}

export const uploadphoto = (file, token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
};
  try {
    dispatch({type: types.LOADINGUPLOAD,  payload: true})
    function getFormData(image) {
    const formData = new FormData();
    formData.append('avatar', image)
    return formData;
    }
    const response = await Eneo.post(`/profile/avatar`, getFormData(file), config);
    console.log(response.data);
    dispatch({type: types.LOADINGUPLOAD, payload: false})
    dispatch({type: types.SUCCESSEDITPHOTO, payload: response.data.avatar})
  }catch (err) {
    console.log(err?.response?.data || 'something went wrong');
    dispatch({type: types.LOADINGUPLOAD, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'uploadphoto',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}


export const Editprofile = (first_name,last_name,email,phone,password,password_confirmation,token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};
const data = {
  first_name, last_name, email, phone, password, password_confirmation
}

console.log(data);

  try {
    dispatch({type: types.LOADINGEDIT,  payload: true})
    const response = await Eneo.post(`/profile/update`, data, config);
    console.log(response.data);
    dispatch({type: types.LOADINGEDIT, payload: false})
    dispatch({type: types.SUCCESSEDITPROFILE, payload: response.data.avatar})
  }catch (err) {
    console.log(err?.response?.data || 'something went wrong');
    dispatch({type: types.LOADINGEDIT, payload: false})
    dispatch({type: types.ERROR, payload: {
      type: 'editprofile',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}


export const Deletephoto = (property_id, thumbnail, token) => async dispatch => {
  const config = {
    headers: { Authorization: `Bearer ${token}`}
};
dispatch({type: types.LOADINGDELETEPHOTO, payload: true})
  try{
  const listtypes = await Eneo.post(`/properties/${property_id}/remove-thumbnail`, {thumbnail}, config);
  await dispatch({type: types.SUCCESSDELETEPHOTO, payload: thumbnail})
  }catch(err){
    dispatch({type: types.LOADINGDELETEPHOTO, payload: false})
    console.log(err);
    dispatch({type: types.ERROR, payload: {
      type: 'deleteproperty',
      data: err?.response?.data || 'something went wrong, try again later',
    }});
  }
}