import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, StepIcon} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
      },
  container: {
      paddingTop: 20,
      paddingBottom: 20
  },
  heading: {
      fontSize: 25,
      fontWeight: '700'
  },
  subheading: {
      fontSize: 16,
      fontWeight: '400'
  },
  griditem: {
      display: 'flex',
      flexDirection : 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
  },
  featured: {
    height: 30,
    backgroundColor: '#1849AA',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 15,
    color: '#fff',
    marginRight: 5,
},
forrent: {
 height: 30,
 backgroundColor: '#3EBF45',
 paddingLeft: 10,
 paddingRight: 10,
 borderRadius: 15,
 color: '#fff',
 marginLeft: 5
},
like: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff',
    marginRight: 5
   },
   liketwo: {
    height: 30,
    width: 30,
    backgroundColor: '#222222',
    borderRadius: 15,
    color: '#fff'
   },
   step: {
       padding: 0,
       backgroundColor: 'transparent'
   }
}));

function getSteps() {
    return ['Choose your pricing plan', 'Create an ad group'];
  }
  
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return 'Select campaign settings...';
      case 1:
        return 'What is an ad group anyways?';
      case 2:
        return 'This is the bit I really care about!';
      default:
        return 'Unknown stepIndex';
    }
  }

export default function CustomSeparator() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  return (
    <Container className={classes.container}>
      <Grid container>
          <Grid item xs={8} className={classes.griditem}>
              <span className={classes.heading}>Subscribe to Eneo</span>
          </Grid>
          <Grid item xs={4} className={classes.griditem}>
              <div></div>
              <div className={classes.root}>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.step}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

              </div>
          </Grid>
      </Grid>
    </Container>
  );
}
