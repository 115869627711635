import '../App.css';
import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import Layout from '../layout';
import Breadcrumbs from './Breadcrumbs';
import Footer from './Footer';
import Productsgrid from './productsgrid';
import Productsfilter from './productsfilter';
import Homeslider from './searchbar';
import { useLocation } from "react-router-dom";
import * as Scroll from 'react-scroll';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%'
   },
  }));

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

function Home(props) {
    const classes = useStyles()
    let query = useQuery();
    const min_price = query.get("min_price");
    const keysw = query.get("keyword");
    const page = query.get("page");
    const max_price = query.get("max_price");
    const bathrooms = query.get("bathrooms");
    const bedrooms = query.get("bedrooms");
    const street = query.get("street");
    const property_category = query.get("property_category");
    var scroll    = Scroll.animateScroll;

    useEffect(() => {
      scroll.scrollTo(350);
    }, [keysw, page, min_price, max_price, bedrooms, bathrooms, property_category, street])
  return (
      <Layout>
        <div className={classes.root}>
      <Homeslider/>
        <Breadcrumbs/>
        <Productsfilter/>
        <Productsgrid/>
        <Footer/>
        </div>  
   
      </Layout>
   
   
  )
}

export default Home;
