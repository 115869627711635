import '../App.css';
import { makeStyles, Grid, Container, ButtonBase } from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import OwlCarousel from 'react-owl-carousel';
import Productcard from './Slidercard';
import {useSelector, useDispatch} from 'react-redux';
import {getPrices, getSlider} from '../store/actions/appactions';
import { useHistory } from 'react-router-dom';
import SearchBar from "material-ui-search-bar";
import Select from 'react-select'
import {getCategories} from '../store/actions/appactions';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundImage: `url(${"https://images.unsplash.com/photo-1603822455719-861b5afe1c50?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2900&q=80"})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
   item: {
       width: '100%',
       paddingTop: 200,
       paddingBottom: 200,
       backgroundColor: 'rgba(0,0,0,0.5)',
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       [theme.breakpoints.down('md')]: {
        paddingTop: 50,
        paddingBottom: 50,
      },
   },
   itemtwo: {
       width: '80%',
       margin: '0 auto',
       display: 'flex',
       flexDirection: 'column',
       [theme.breakpoints.down('md')]: {
        width: '80%'
      },
   },
   itemword: {
       fontSize: 50,
       color: '#fff',
       paddingBottom: 20,
       [theme.breakpoints.down('md')]: {
        fontSize: 25,
      },
   },
   inputcontainer: {
       width: '100%',
       height: 50,
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'center',
       borderRadius: 3,
       overflow: 'hidden',
       marginBottom: 30
   },
   input: {
       flex: 5,
       height: '100%',
       backgroundColor: '#fff',
       border: '0 !important',
       outline: 'none',
       borderRadius: 0,
       paddingLeft: 10,
       fontSize: 16,
       [theme.breakpoints.down('md')]: {
        fontSize: 12,
        flex: 4
      },
   },
   inputsearch: {
      height: '100%',
      flex: 1,
      backgroundColor: '#1849AA',
      fontSize: '0.875rem',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   clearbutton: {
       width: '100%',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       color: '#fff',
       borderRadius: 5,
       backgroundColor: '#1849AA',
       height: 38
   }
  }));




function Home(props) {
    const classes = useStyles()
    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const [minprice, setMinprice] = useState('');
    const [maxprice, setMaxprice] = useState('');
    const [numberofbedrooms, setNumberofbedrooms] = useState('');
    const [numberofbathrooms, setNumberofbathrooms] = useState('');
    const [keyword, setKeyword] = useState('');
    const [category, setCategory] = useState('');
    const {t} = useTranslation();
    let history = useHistory();

    const clearSearch = () => {
        setKeyword('');
        setCategory('');
        setMaxprice('');
        setMinprice('');
        setNumberofbathrooms('');
        setNumberofbedrooms('');
    }

    useEffect(() => {
       dispatch(getCategories());
       dispatch(getPrices());
    }, [])


    const price = app?.prices?.filter_amounts || " ";

    const prices = price.split(",");

    const options = prices?.map(p => {
        return (
            { value: p, label: `Tsh ${p ? new Intl.NumberFormat().format(p) : null}` }
        )
    }) || [];

      const optionscount = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5+' }
      ]

      const categ = app?.categories?.map(category => {
          return {
              value: category.value,
              label: `${t(`${category.name}`)}`
          }
      }) || '';


      
      const onSubmit = () => {
          history.push(`/properties?page=1${keyword ? `&keyword=${keyword}` : ''}${minprice ? `&min_price=${minprice.value}` : ''}${maxprice ? `&max_price=${maxprice.value}` : ''}${numberofbedrooms ? `&bedrooms=${numberofbedrooms.value}` : ''}${numberofbathrooms ? `&bathrooms=${numberofbathrooms.value}` : ''}${category ? `&property_category=${category.value}` : ''}`)
      }

  return (
      <div className={classes.root}>
         <div className={classes.item}>
        <div className={classes.itemtwo}>
            <span className={classes.itemword}>{t("Find property for rent")}</span>
        {/* <SearchBar
         value={keyword}
         placeholder='Search for a city, surburb or experience'
         onChange={(newValue) => setKeyword(newValue)}
         onRequestSearch={() => onSubmit()}
         style={{width: '100%', marginBottom: 20}}
        />    */}
        <div className={classes.inputcontainer}>
            <input placeholder={t('Search for a city, surburb or experience')} type='text' value={keyword} onChange={(e) => setKeyword(e.target.value)} className={classes.input}/>
            <ButtonBase onClick={() => onSubmit()} className={classes.inputsearch}>{t("Search")}</ButtonBase>
        </div>
        <div style={{width: '100%'}}>
           <Grid container spacing={2}>
           <Grid item xs={12} sm={6} md={6} lg={2}>
               <Select onChange={(value) => setCategory(value)} value={category} placeholder={t('Category')}  options={categ} />
               </Grid>
               <Grid item xs={12} sm={6} md={6} lg={2}>
               <Select onChange={(value) => setMinprice(value)} value={minprice} placeholder={t('Min. price')}  options={options} />
               </Grid>
               <Grid item xs={12} sm={6} md={6} lg={2}>
               <Select onChange={(value) => setMaxprice(value) } value={maxprice} placeholder={t('Max. price')} options={options} />
               </Grid>
               <Grid item xs={12} sm={6} md={6} lg={2}>
               <Select onChange={(value) => setNumberofbathrooms(value) } value={numberofbathrooms} placeholder={t('Bathrooms')} options={optionscount} />
               </Grid>
               <Grid item xs={12} sm={6} md={6} lg={2}>
               <Select onChange={(value) => setNumberofbedrooms(value) } value={numberofbedrooms} placeholder={t('Bedrooms')} options={optionscount} />
               </Grid>
               <Grid item xs={12} sm={6} md={6} lg={2}>
               <ButtonBase className={classes.clearbutton} onClick={() => {
                   clearSearch();
               }}>
                   {t("Clear")}
               </ButtonBase>
               </Grid>
           </Grid>
        </div> 
        </div>
   </div> 
      </div>
    


  )
}

export default Home;
