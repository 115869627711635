import React from "react";

const Facebook = () => {
  return (
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.49536 7.32601H4.48794V14.6803H1.44651V7.32601H0V4.74142H1.44651V3.0689C1.44651 1.87287 2.01465 0 4.51501 0L6.7679 0.00942528V2.51821H5.13328C4.86516 2.51821 4.48814 2.65217 4.48814 3.22272V4.74383H6.76108L6.49536 7.32601Z" fill="white"/>
    </svg>
    
  );
};

export default Facebook;
