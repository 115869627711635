import '../App.css';
import { makeStyles, Grid, Container, CircularProgress} from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import Layout from '../layout';
import { useSelector, useDispatch} from 'react-redux';
import {clearError, getVillages} from '../store/actions/appactions';
import { useLocation } from "react-router-dom";
import Footer from './Footer';
import {Link} from 'react-router-dom';
import SearchBar from "material-ui-search-bar";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
   heading: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       flexDirection: 'column',
       height: 400,
       width: '100%',
       position: 'relative',
       backgroundColor: 'black',
   },
   photo: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    top: 0,
    left: 0,
    zIndex: 1,
    position: 'absolute'
},
photowords: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 9,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 67.39%, rgba(0, 0, 0, 0.81) 100%)',
},
  }));


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  

function Home(props) {
    const classes = useStyles()
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState('');
    let history = useHistory();
    const {t} = useTranslation();
  

    useEffect(() => {
      dispatch(clearError())
    },[])



  //  let data = app?.villages?.reduce((r, e) => {
 
  //   // get first letter of name of current element
  //   let alphabet = e.name[0];
   
  //   // if there is no property in accumulator with this letter create it
  //   if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] }
   
  //   // if there is push current element to children array for that letter
  //   else r[alphabet].record.push(e);
   
  //   // return accumulator
  //   return r;
  // }, {});
   
  // let result = Object.values(data);

  const onSubmit = () => {
    dispatch(getVillages(keyword))
  }


  return (
      <Layout>
          <Container>
          <Grid container spacing={2} style={{paddingTop: 50, paddingBottom: 50, minHeight: "calc(100vh - 50px)"}}>
            <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h3">
              {t("Places")}
           </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
               <SearchBar
         value={keyword}
         placeholder={`${t('Search for places')} eg. masaki...`}
         onChange={(newValue) => setKeyword(newValue)}
         onRequestSearch={() => onSubmit()}
         style={{width: '100%'}}
        />   
            </Grid>
              {app.loadingvillages ? <div style={{width: '100%', height: 'calc(100vh - 70px)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress style={{color: '#1849AA'}} size={30}/>
              </div> : app?.villagesfiltered?.length > 0 ? app?.villagesfiltered?.map(result => {
                  return (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={result?.cover_image || 'https://images.unsplash.com/photo-1603298108410-e6f28ad2708d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1933&q=80'}
          title="Contemplative Reptile"
        />
        <CardContent style={{minHeight: 120}}>
          <Typography gutterBottom variant="h5" component="h2">
            {result?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {result?.district_name}, {result?.region_name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => {
          history.push(`/properties?street=${result?.id}`)
        }}>
          {t("view properties at")} {result?.name}
        </Button>
      </CardActions>
    </Card>
                      </Grid>
                  )
              }) :  app?.villagesfiltered?.length <= 0  ? <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 200}} item xs={12} sm={12} md={12} lg={12}> <span style={{color: '#000', textAlign: 'center'}}>No Places found</span> </Grid> : null}
          </Grid>
          </Container>
       <Footer/>
      </Layout> 
   
  )
}

export default Home;
