import React from "react";
import SocialLogin from "react-social-login";
import {GoogleLoginButton } from "react-social-login-buttons";

function SocialButton({children, triggerLogin}) {
    return (
        <GoogleLoginButton onClick={triggerLogin} >
            {children}
        </GoogleLoginButton>
    );
}

export default SocialLogin(SocialButton);