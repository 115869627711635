import '../App.css';
import { makeStyles, Grid, Container, CircularProgress} from '@material-ui/core';
import React, {useEffect} from 'react'
import Layout from '../layout';
import { useSelector, useDispatch} from 'react-redux';
import { getAgents} from '../store/actions/appactions';
import { useLocation } from "react-router-dom";
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
   heading: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
       flexDirection: 'column',
       height: 400,
       width: '100%',
       position: 'relative',
       backgroundColor: 'black',
   },
   photo: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    top: 0,
    left: 0,
    zIndex: 1,
    position: 'absolute'
},
photowords: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 9,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 67.39%, rgba(0, 0, 0, 0.81) 100%)',
},
  }));


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  

function Home(props) {
    const classes = useStyles()
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();



   useEffect(() => {
    dispatch(getAgents())
   },[])


  return (
      <Layout>
          <div style={{width: '100%', minHeight: '100vh', backgroundColor: '#fff'}}>
            <div style={{width: '70%', margin: '0 auto', paddingTop: 50}}>
       <div style={{width: '100%'}} dangerouslySetInnerHTML={{__html: app.language === 'english' ? app?.agents?.content?.en : app?.agents?.content?.sw}}></div>
      </div>   
          </div>   
          <Footer/>
      </Layout> 
   
  )
}

export default Home;
