import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { Container, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
     textAlign: 'center'
      },
  },
}));

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const app = useSelector(state => state.app);
  let history = useHistory();


  const onClose = () => {
    setOpen(false);
  }

  const onSubmit = (keyword, price) => {
    setOpen(false);
    history.push(`/properties?page=1${keyword ? `&keyword=${keyword}` : ''}${price ? `&price=${price}` : ''}`)
  }

  const handleAuth = () => {
    if(app?.userdata?.token){
      history.push(`/profile`)
    }else{
      history.push(`/auth`)
    }
  }

  return (
    <>
      <Container>
        <Grid>
          <Grid item xs={12}>
            <p className={classes.root}>Contact Us: +255-758-988-500</p>
          </Grid>
        </Grid>
      </Container>
   </>
  );
}
