import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import Logo from './logos/logo';
import Formcontainer from './formcontainer';
import Apartment from '../assets/Appartment.png';
import Header from '../layout/header';
import Footer from './Footer';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
   root: {
     width: '100%',
     display: 'flex',
     flexDirection: 'row',
     height: '100vh',
     backgroundColor: '#fff'
   },
   photocontainer: {
       flex: 2,
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'flex-start',
       justifyContent: 'space-evenly',
       height: '100vh',
       backgroundColor: '#1849AA',
       borderTopRightRadius: 50,
       paddingLeft: 100,
       [theme.breakpoints.down('md')]: {
        display: 'none'
      },
   },
   formcontainer: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginTop: 20
    },
   },
   header: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
   },
   photo: {
     width: 400
   },
   words: {
     color: '#fff',
     fontWeight: '800',
     fontSize: 30,
   }
  }));

  
  

function Home(props) {
    const classes = useStyles()
    const {t} = useTranslation();
  return (<>
     <div className={classes.header}><Header/></div>
      <div className={classes.root}>
      <div className={classes.photocontainer}>
      <Link to='/'><Logo/></Link>
       <img src={Apartment} alt='building' className={classes.photo}/> 
        <span className={classes.words}>{t("Affordable quality")}<br/>{t("Apartments")}</span>
    </div> 
    <div className={classes.formcontainer}>
    <Formcontainer/>
    </div>       
      </div>   
      <div className={classes.header}><Footer/></div> 
      </>  
  )
}

export default Home;
